export const message_responses = {
    'VALIDATOR_ERROR' : 'VALIDATOR_ERROR',
    'CREATE_QUOTATION_REQUEST_SUCCESS': 'Muy bien ! Te damos la  bienvenida a TMP tu formulario de contactanos ha sido enviado satisfactoriamente, nos pondremos en contacto contigo lo mas pronto posible.',
    'CREATE_QUOTATION_REQUEST_ERROR': 'Por favor discúlpanos :( , hemos tenido un error enviando formulario de contáctenos.',

    'GET_MACHINE_CATEGORIES_SUCCESS': 'GET_MACHINE_CATEGORIES_SUCCESS',
    'GET_MACHINE_CATEGORIES_ERROR': 'GET_MACHINE_CATEGORIES_ERROR',
    'GET_MACHINE_CATEGORY_SUCCESS': 'GET_MACHINE_CATEGORY_SUCCESS',
    'GET_MACHINE_CATEGORY_ERROR': 'GET_MACHINE_CATEGORY_ERROR',
    'CREATE_MACHINE_CATEGORY_SUCCESS': 'CREATE_MACHINE_CATEGORY_SUCCESS',
    'CREATE_MACHINE_CATEGORY_ERROR': 'CREATE_MACHINE_CATEGORY_ERROR',
    'UPDATE_MACHINE_CATEGORY_SUCCESS': 'UPDATE_MACHINE_CATEGORY_SUCCESS',
    'UPDATE_MACHINE_CATEGORY_ERROR': 'UPDATE_MACHINE_CATEGORY_ERROR',

    'GET_MACHINE_SUBCATEGORIES_SUCCESS': 'GET_MACHINE_SUBCATEGORIES_SUCCESS',
    'GET_MACHINE_SUBCATEGORIES_ERROR': 'GET_MACHINE_SUBCATEGORIES_ERROR',
    'GET_MACHINE_SUBCATEGORY_SUCCESS': 'GET_MACHINE_SUBCATEGORY_SUCCESS',
    'GET_MACHINE_SUBCATEGORY_ERROR': 'GET_MACHINE_SUBCATEGORY_ERROR',
    'CREATE_MACHINE_SUBCATEGORY_SUCCESS': 'CREATE_MACHINE_SUBCATEGORY_SUCCESS',
    'CREATE_MACHINE_SUBCATEGORY_ERROR': 'CREATE_MACHINE_SUBCATEGORY_ERROR',
    'UPDATE_MACHINE_SUBCATEGORY_SUCCESS': 'UPDATE_MACHINE_SUBCATEGORY_SUCCESS',
    'UPDATE_MACHINE_SUBCATEGORY_ERROR': 'UPDATE_MACHINE_SUBCATEGORY_ERROR',
    'DELETE_MACHINE_SUBCATEGORY_SUCCESS': 'DELETE_MACHINE_SUBCATEGORY_SUCCESS',
    'DELETE_MACHINE_SUBCATEGORY_ERROR': 'DELETE_MACHINE_SUBCATEGORY_ERROR',

    'GET_MACHINE_BRANDS_SUCCESS': 'GET_MACHINE_BRANDS_SUCCESS',
    'GET_MACHINE_BRANDS_ERROR': 'GET_MACHINE_BRANDS_ERROR',
    'GET_MACHINE_BRAND_SUCCESS': 'GET_MACHINE_BRAND_SUCCESS',
    'GET_MACHINE_BRAND_ERROR': 'GET_MACHINE_BRAND_ERROR',
    'CREATE_MACHINE_BRAND_SUCCESS': 'CREATE_MACHINE_BRAND_SUCCESS',
    'CREATE_MACHINE_BRAND_ERROR': 'CREATE_MACHINE_BRAND_ERROR',
    'UPDATE_MACHINE_BRAND_SUCCESS': 'UPDATE_MACHINE_BRAND_SUCCESS',
    'UPDATE_MACHINE_BRAND_ERROR': 'UPDATE_MACHINE_BRAND_ERROR',
    'DELETE_MACHINE_BRAND_SUCCESS': 'DELETE_MACHINE_BRAND_SUCCESS',
    'DELETE_MACHINE_BRAND_ERROR': 'DELETE_MACHINE_BRAND_ERROR',

    'GET_SUBCATEGORY_FIELDS_SUCCESS': 'GET_SUBCATEGORY_FIELDS_SUCCESS',
    'GET_SUBCATEGORY_FIELDS_ERROR': 'GET_SUBCATEGORY_FIELDS_ERROR',
    'GET_SUBCATEGORY_FIELD_SUCCESS': 'GET_SUBCATEGORY_FIELD_SUCCESS',
    'GET_SUBCATEGORY_FIELD_ERROR': 'GET_SUBCATEGORY_FIELD_ERROR',
    'CREATE_SUBCATEGORY_FIELD_SUCCESS': 'CREATE_SUBCATEGORY_FIELD_SUCCESS',
    'CREATE_SUBCATEGORY_FIELD_ERROR': 'CREATE_SUBCATEGORY_FIELD_ERROR',
    'UPDATE_SUBCATEGORY_FIELD_SUCCESS': 'UPDATE_SUBCATEGORY_FIELD_SUCCESS',
    'UPDATE_SUBCATEGORY_FIELD_ERROR': 'UPDATE_SUBCATEGORY_FIELD_ERROR',
    'DELETE_SUBCATEGORY_FIELD_SUCCESS': 'DELETE_SUBCATEGORY_FIELD_SUCCESS',
    'DELETE_SUBCATEGORY_FIELD_ERROR': 'DELETE_SUBCATEGORY_FIELD_ERROR',

    'CREATE_MACHINE_SUCCESS': 'CREATE_MACHINE_SUCCESS',
    'CREATE_MACHINE_ERROR': 'Oops por favor discúlpanos :( , hemos tenido un error creando tu publicación inténtalo nuevamente si el error persiste por favor ponte en contacto con uno de nuestros profesionales.',

    'UPLOAD_MACHINE_IMAGES_SUCCESS': 'UPLOAD_MACHINE_IMAGES_SUCCESS',
    'UPLOAD_MACHINE_IMAGES_ERROR': 'UPLOAD_MACHINE_IMAGES_ERROR',

    'GET_MACHINES_FOR_SELL_SUCCESS': 'GET_MACHINES_FOR_SELL_SUCCESS',
    'GET_MACHINES_FOR_SELL_ERROR': 'GET_MACHINES_FOR_SELL_ERROR',
    'GET_MACHINES_FOR_RENT_SUCCESS': 'GET_MACHINES_FOR_RENT_SUCCESS',
    'GET_MACHINES_FOR_RENT_ERROR': 'GET_MACHINES_FOR_RENT_ERROR',

    'GET_MACHINE_FOR_SELL_SUCCESS': 'GET_MACHINE_FOR_SELL_SUCCESS',
    'GET_MACHINE_FOR_SELL_ERROR': 'GET_MACHINE_FOR_SELL_ERROR',
    'GET_MACHINE_FOR_RENT_SUCCESS': 'GET_MACHINE_FOR_RENT_SUCCESS',
    'GET_MACHINE_FOR_RENT_ERROR': 'GET_MACHINE_FOR_RENT_ERROR',

    'CREATE_USER_ERROR' : 'Oops por favor discúlpanos :( , hemos tenido un error creando tu usuario, puede ser que este correo ay este registrado, inténtalo nuevamente si el error persiste por favor ponte en contacto con uno de nuestros profesionales.',
    'CREATE_USER_SUCCESS' : 'Muy bien ! Tu usuario ha sido registrado correctamente, te damos la  bienvenida a TMP. Te hemos enviado un correo para verificar tu dirección de correo, una vez verificado puedes ingresar a nuestra plataforma con tu usuario y contraseña.',
    'LOGIN_USER_SUCCESS' : 'LOGIN_USER_SUCCESS',
    'LOGIN_USER_BAD_CREDENTIALS' : 'LOGIN_USER_BAD_CREDENTIALS',
    'LOGIN_USER_ERROR' : 'LOGIN_USER_ERROR',
    
    'DELETE_MACHINE_IMAGE_SUCCESS' : 'DELETE_MACHINE_IMAGE_SUCCESS',
    'DELETE_MACHINE_IMAGE_ERROR' : 'DELETE_MACHINE_IMAGE_ERROR',

    'SIGN_UP_SUCCESS': 'Se ha creado tu usuario correctamente, para poder iniciar sesión debes confirmar tu cuenta a través del mensaje de confirmación que hemos enviado a tu correo.',
    'SIGN_UP_ERROR': 'Ups, lo sentimos ocurrió un error al intentar crear tu usuario.',
    'USER_WITH_THIS_EMAIL_ALREADY_EXISTS': 'Ups, lo sentimos este email ya se encuentra registrado.',
    'VALIDATE_EMAIL_ERROR': 'Ups, lo sentimos ocurrió un error validando tu email, inténtalo nuevamente.',
    'VALIDATE_EMAIL_SUCCESS': 'Perfecto, tu email ha sido verificado correctamente, ahora puedes iniciar sesión.',
    'EMAIL_ALREADY_WAS_VERIFIED_ERROR': 'Ups, lo sentimos al parecer este email ya ha sido verificado.',

    'USER_NOT_EXIST_ERROR': 'Ups, lo sentimos al parecer este usuario no se encuentra registrado.',
    'EMAIL_CODE_NOT_VERIFIED_ERROR': 'Ups, lo sentimos al parecer tu email no ha sido verificado aún.',
    'BLOCKED_ACCOUNT_ERROR': 'Ups, lo sentimos al parecer esta cuenta se encuentra bloqueada.',
    'INACTIVE_ACCOUNT_ERROR': 'Ups, lo sentimos al parecer esta cuenta se encuentra inactiva.',
    'LOGIN_SUCCESS': 'Perfecto, sesión iniciada correctamente.',
    'STATUS_ACCOUNT_ERROR': 'Ups, lo sentimos al parecer esta cuenta se encuentra en un estado con el cual no es posible acceder.',
    'INVALID_CREDENTIALS_ERROR': 'Ups, lo sentimos al parecer las credenciales ingresadas no son correctas.',
    'LOGIN_USER_ERROR': 'Ups, lo sentimos ocurrió un error al intentar iniciar sesión tu usuario.',
    'SEND_RESET_PASSWORD_EMAIL_SUCCESS': 'Perfecto, te hemos enviado un enlace a tu correo para que restablezcas tu contraseña.',
    'SEND_RESET_PASSWORD_EMAIL_ERROR': 'Ups, lo sentimos ocurrió un error al intentar restablecer tu contraseña.',
    'RESET_PASSWORD_ERROR': 'Ups, lo sentimos ocurrió un error al intentar restablecer tu contraseña.',
    'RESET_PASSWORD_SUCCESS': 'Perfecto, tu contraseña se ha restablecido correctamente, ahora puedes iniciar sesión con tu nueva contraseña.',
    'MACHINE_DOESNT_EXIST': 'Lo sentimos, la maquinaria no ha sido encontrada.',

    'GET_ALL_ENABLED_AND_COMING_SOON_PACKAGES_ERROR': 'GET_ALL_ENABLED_AND_COMING_SOON_PACKAGES_ERROR',

    'CREATE_INSURANCE_SUCCESS': 'Seguro creado Exítosamente.',
    'CREATE_INSURANCE_ERROR': 'Ups, hemos tenido un error al crear el seguro.',
    'INSURANCE_FOUND_SUCCESS' : 'El seguro ya está en proceso con ese correo.',

    'IS_NOT_ADMIN_ACCOUNT_ERROR' : 'Este usuario no es administrador.'
}