import React, { Component, Fragment } from 'react';
import './Cities.css';
import CitiesComponent from "../../components/CitiesComponent/CitiesComponent";


class Cities extends Component{
    render(){
        return(
            <Fragment>
                <CitiesComponent/>
            </Fragment>
        );
    }
}

export default Cities;