import React, {useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { API_URL, CREATE_INSURANCE } from "../../API";
import CountrySelect from "../Utils/countryAutocomplete";
import Swal from "sweetalert2";
import { Strings, Colors } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    minHeight: '50px',
    margin: '16px 0px',
  },
  input: {
    display: 'none',
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontFamily: 'BebasFont, serif !important',
    paddingTop: '20px',
    width: '100%',
    textAlign: 'center',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  filesButton: {
    minHeight: '56px',
    maxHeight: '56px',
  },
  spanErrorMessage:{
    color: '#dc3545',
    fontWeight: 'bold',
    textAlign: 'justify',
  },
}));

export default function NewInsuranceComponent({type}) {
  const classes = useStyles();

  const [loadingItems, setLoadingItems] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const [identification, setIdentification] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [activity, setActivity] = useState("");
  const [observations, setObservations] = useState("");
  const [idDocument, setIdDocument] = useState(null);
  
  const [errors, setErrors] = useState({
    country: '',
    state: '',
    city: '',
  });
  
  const handleIdDocumentChange = async (e) => {
    const maxImageSize = process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE;
    const file = document.getElementById('contained-button-file').files[0];
    if (file.size > maxImageSize) {
        // TODO : ALERT
        alert(`${file.name} es demasiado grande, el máximo tamaño permitido es ${maxImageSize/1000000}MB.`);
        setIdDocument(null);
    }else{
      setIdDocument(file);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(validateFields()){
      createInsurance();
    }
  };

  const validateFields = () => {
    return validateTextField(country, 'country', 64) && 
      validateTextField(state, 'state', 64) && 
      validateTextField(city, 'city', 64) && 
      validateTextField(name, 'name', 64) && 
      validateTextField(lastName, 'lastName', 64) && 
      validateTextField(identificationType, 'identificationType', 64) && 
      validateTextField(identification, 'identification', 64) && 
      validateFileField(idDocument, 'idDocument', process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE) &&
      validateTextField(cellPhone, 'cellPhone', 64) && 
      validateTextField(phone, 'phone', 64) && 
      validateTextField(address, 'address', 64) && 
      validateTextField(email, 'email', 64) && 
      validateTextField(activity, 'activity', 64) && 
      validateTextField(observations, 'observations', 64);
  }

  const validateTextField = (value, name, maxLength) => {
    if(value === '' || value === null || value === undefined){
      setErrors({...{}, [name]: "Este campo no puede estar vacío."});
        return false;
    }else if(maxLength && value.length > maxLength ){
        setErrors({...{}, [name]: `Este campo excede la longitud máxima de ${maxLength} caracteres.`});
        return false;
    }else{
        setErrors({...{}, [name]: ''});
        return true;
    }
  }

  const validateFileField = (file, name, maxImageSize) => {
    if(file === null || file === undefined){
      setErrors({...{}, [name]: "Este documento es necesario."});
      return false;
    }else if(file.size > maxImageSize){
        setErrors({...{}, [name]: `${file.name} es demasiado grande, el máximo tamaño permitido es ${maxImageSize/1000000}MB.`});
    }else{
      setErrors({...{}, [name]: ''});
      return true;
    }
  }

  const emptyBox = () => {
    setCountry("");
    setState("");
    setCity("");
    setName("");
    setLastName("");
    setIdentificationType("");
    setIdentification("");
    setCellPhone("");
    setPhone("");
    setAddress("");
    setEmail("");
    setActivity("");
    setObservations("");
    setIdDocument(null);
  };

  const createInsurance = () => {

    setLoadingItems(true);

    let formData = new FormData();
    formData.append('country', country);
    formData.append('state', state);
    formData.append('city', city);
    formData.append('client_name', name);
    formData.append('client_last_name', lastName);
    formData.append('client_identification_type', identificationType); 
    formData.append('client_identification', identification); 
    formData.append('client_cell_phone', cellPhone); 
    formData.append('client_phone', phone); 
    formData.append('client_address', address); 
    formData.append('client_email', email); 
    formData.append('client_activity', activity); 
    formData.append('client_id_document', idDocument);
    formData.append('type', type);
    formData.append('observations', observations);
    
    axios.post(API_URL + CREATE_INSURANCE, formData, { headers: { 'content-type': 'multipart/form-data' } }).then((response) => {
      setLoadingItems(false);
      if (response.data.success) {
          Swal.fire({
            title: Strings.CREATING_INSURANCE_SUCCESS_TITLE,
            html: Strings.CREATING_INSURANCE_SUCCESS_HTML(response.data.data.id),
            icon: 'success',
            width: "80%",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            confirmButtonColor: Colors.SUCCESS_MAIN,
          });

          emptyBox();
      } else {
        Swal.fire({
          title: 'Oops...',
          text: Strings.GENERAL_ERROR,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonColor: Colors.SUCCESS_MAIN,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      Swal.fire({
        title: 'Oops...',
        text: Strings.GENERAL_ERROR,
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonColor: Colors.SUCCESS_MAIN,
      });
      setLoadingItems(false);
    });
  };

  return (
    <Container component="main" maxWidth="lg">

        <Typography variant="h3" className={classes.title}>COTIZADOR POLIZA DE ACCIDENTES INDIVIDUAL</Typography>

        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <CountrySelect setCountry={setCountry}/>
              { errors.country && <span className={classes.spanErrorMessage}>{errors.country}</span> }
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <TextField
                autoComplete="state"
                name={state}
                variant="outlined"
                required
                fullWidth
                id="state"
                label="Estado"
                value={state}
                onChange={(event) => {
                  setState(event.target.value)
                }}
              />
              { errors.state && <span className={classes.spanErrorMessage}>{errors.state}</span> }
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                autoComplete="city"
                name="city"
                variant="outlined"
                required
                fullWidth
                id="city"
                label="Ciudad"
                value={city}
                onChange={(event) => {
                  setCity(event.target.value)
                }}
              />
              { errors.city && <span className={classes.spanErrorMessage}>{errors.city}</span> }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Nombres del Tomador"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
              { errors.name && <span className={classes.spanErrorMessage}>{errors.name}</span> }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Apellidos del Tomador"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value)
                }}
              />
              { errors.lastName && <span className={classes.spanErrorMessage}>{errors.lastName}</span> }
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo de Identificacion
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={identificationType}
                  onChange={(event) => {
                    setIdentificationType(event.target.value)
                  }}
                  labelWidth={150}
                >
                  <MenuItem value="Cédula de Ciudadanía">Cédula de Ciudadanía</MenuItem>
                  <MenuItem value="Cédula de Extranjería">Cédula de Extranjería</MenuItem>
                  <MenuItem value="NIT">NIT</MenuItem>
                  <MenuItem value="Otro">Otro</MenuItem>
                </Select>
              </FormControl>
              { errors.identificationType && <span className={classes.spanErrorMessage}>{errors.identificationType}</span> }
          </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                type="number"
                required
                fullWidth
                id="identification"
                label="Número de Identificación"
                name="identification"
                autoComplete="identification"
                value={identification}
                onChange={(event) => {
                  setIdentification(event.target.value)
                }}
              />
              { errors.identification && <span className={classes.spanErrorMessage}>{errors.identification}</span> }
            </Grid>
            <Grid item xs={12} sm={4}>
              <input
                accept="image/jpeg,image/png,application/pdf"
                className={classes.input}
                id="contained-button-file"
                type="file"
                onChange={handleIdDocumentChange}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="secondary" component="span" fullWidth className={classes.filesButton}>
                  {idDocument ?  `(${parseFloat(idDocument.size / process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE).toFixed(2)}MB) - ${idDocument.name}` : 'Documento de Identidad'}&nbsp;&nbsp; <CloudUploadIcon/>
                </Button>
              </label>
              { errors.idDocument && <span className={classes.spanErrorMessage}>{errors.idDocument}</span> }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                type="number"
                required
                fullWidth
                id="cellPhone"
                label="Celular"
                name="cellPhone"
                autoComplete="cellPhone"
                value={cellPhone}
                onChange={(event) => {
                  setCellPhone(event.target.value)
                }}
              />
              { errors.cellPhone && <span className={classes.spanErrorMessage}>{errors.cellPhone}</span> }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                type="number"
                fullWidth
                id="phone"
                label="Télefono"
                name="phone"
                autoComplete="phone"
                value={phone}
                onChange={(event) => {
                  setPhone(event.target.value)
                }}
              />
              { errors.phone && <span className={classes.spanErrorMessage}>{errors.phone}</span> }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                required
                id="address"
                label="Dirección"
                name="address"
                autoComplete="address"
                value={address}
                onChange={(event) => {
                  setAddress(event.target.value)
                }}
              />
              { errors.address && <span className={classes.spanErrorMessage}>{errors.address}</span> }
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Correo"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
              />
              { errors.email && <span className={classes.spanErrorMessage}>{errors.email}</span> }
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="activity"
                label="Actividad"
                name="activity"
                autoComplete="activity"
                value={activity}
                onChange={(event) => {
                  setActivity(event.target.value)
                }}
              />
              { errors.activity && <span className={classes.spanErrorMessage}>{errors.activity}</span> }
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-textarea"
                label="Observaciones"
                placeholder="Observaciones"
                multiline
                rows={4}
                variant="outlined"
                value={observations}
                onChange={(event) => {
                  setObservations(event.target.value)
                }}
                fullWidth
              />
              { errors.observations && <span className={classes.spanErrorMessage}>{errors.observations}</span> }
            </Grid>
          </Grid>
          <Grid item xs={12} justify='center' alignContent='center' alignItems='center' className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              disabled={loadingItems}
              fullWidth
            >
              {loadingItems ? (
                <CircularProgress
                  className={classes.progress}
                  color="primary"
                />
              ) : (
                "Solicitar Cotización"
              )}
            </Button>
          </Grid>

        </form>

    </Container>
  );
}
