import React, { Component, Fragment } from 'react';
import './ProvidersList.css';
import {Grid, Paper, Typography, AppBar, Tabs, Tab, TextField, MenuItem, Button, Chip} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import {
    SelectionState,
    PagingState,
    IntegratedPaging,
    SearchState,
    IntegratedFiltering,

} from '@devexpress/dx-react-grid';
import {
    Grid as GridTable,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel
} from '@devexpress/dx-react-grid-material-ui';


import axios from "axios";
import {API_URL} from "../../API";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiInput: {
            root:{
                width: "100%",
                "&$focused": {
                    '&:after': {
                        borderBottom: "2px solid #000000 !important",
                        width: "100%"
                    }
                },
            },

        }
    }
});

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#000000',
    },
    tabsRoot: {
        borderBottom: '1px solid #000000',
    },
    tabsIndicator: {
        backgroundColor: '#ff9b30',
        height: "4px",
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: "\"machineFont\" !important",
        fontSize: "18px",
        '&:hover': {
            color: '#ff9b30',
            opacity: 5,
        },
        '&:focus': {
            color: '#ff9b30',
        },
    },
    tabSelected: {
        color: '#ff9b30',
    },
    typography: {
        padding: theme.spacing.unit * 3,
    },
    input: {
        backgroundColor: "blue",
    }
});

class ProvidersList extends Component{

    constructor(props){
        super(props);
        this.state = {
            value: 0,
            providersList: [],
            columns: [
                { name: 'provider_types', title: 'Tipo Proveedor' },
                { name: 'provider_brands', title: 'Marcas Asociadas' },
                { name: 'provider_company', title: 'Nombre' },
                { name: 'provider_NIT', title: 'Identificacion' },
                { name: 'provider_schedule', title: 'Horarios' },
                { name: 'provider_country_id', title: 'Pais' },
                { name: 'provider_city_id', title: 'Ciudad' },
                { name: 'provider_freight_details', title: 'Detalles Flete' },
                { name: 'provider_quality_RT', title: 'Cal. T.Respuesta' },
                { name: 'provider_quality_DT', title: 'Cal. T.Envio' },
                { name: 'provider_quality_cost', title: 'Cal. costo' },
                { name: 'actions', title: 'Acciones' },
            ],

            provider_company: '',
            provider_NIT: '',

            countries: [],
            country_id: '',

            cities: [],
            cities_by_country: [],
            city_id: '',

            providers_types: [],
            providers_types_group: [],
            provider_type_id: '',

            providers_brands: [],
            providers_brands_group: [],
            provider_brand_id: '',

            provider_addresses: [],
            provider_address: '',

            provider_phone_numbers: [],
            provider_phone_number: '',

            provider_faxes: [],
            provider_fax: '',

            provider_emails: [],
            provider_email: '',

            provider_web_pages: [],
            provider_web_page: '',


            provider_contacts: [],
            provider_contact_name: '',
            provider_contact_last_name: '',
            provider_contact_phone_number: '',
            provider_contact_whatsapp: '',
            provider_contact_email: '',

            error_provider_company: '',
            error_provider_NIT: '',
            error_country_id: '',
            error_city_id: '',
            error_provider_type_id: '',
            error_provider_brand_id: '',
            error_provider_address: '',
            error_provider_phone_number: '',
            error_provider_fax: '',
            error_provider_email: '',
            error_provider_web_page: '',


            error_provider_contact_name: '',
            error_provider_contact_last_name: '',
            error_provider_contact_phone: '',
            error_provider_contact_whatsapp: '',
            error_provider_contact_email: '',

        };





        this.changeSelection = selection => this.setState({ selection });
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this._updateProvidersList();
        this._getCountries();
        this._getCities();
        this._getProviderTypes();
        this._getProviderBrands();
    };

    _updateProvidersList(){
        axios.get(API_URL+'/providers').then((response) => {


            let providers_list = response.data.map(provider => ({
                id: provider.id,
                provider_company: provider.provider_company,
                provider_type_id: provider.provider_type_id,
                provider_description: provider.provider_description,
                provider_identification: provider.provider_identification,
                provider_country_id: provider.provider_country_id,
                provider_quality_RT: provider.provider_quality_RT,
                provider_quality_DT: provider.provider_quality_DT,
                provider_quality_price: provider.provider_quality_price,
                provider_brands: "Cat | Hitachi",
                provider_contacts: "Carlos | Camilo",
                provider_actions: "Editar",
            }));
            this.setState({
                providersList: providers_list
            })
        });
    }

    _getCountries(){
        axios.get(API_URL+'/countries').then((response) => {
            let countries = response.data.map(country => ({
                id: country.id,
                country_name: country.country_name,
            }));
            this.setState({
                countries: countries
            })
        });
    }

    _getCities(){
        axios.get(API_URL+'/cities').then((response) => {
            let cities = response.data.map(city => ({
                id: city.id,
                city_name: city.city_name,
                country_id: city.country_id,
            }));
            this.setState({
                cities: cities
            })
        });
    }

    _getProviderTypes(){
        axios.get(API_URL+'/providersTypes').then((response) => {
            let providers_types = response.data.map(provider => ({
                id: provider.id,
                provider_type_name: provider.provider_type_name,
                provider_type_description: provider.provider_type_description,
            }));
            this.setState({
                providers_types: providers_types
            })
        });
    }

    _getProviderBrands(){
        axios.get(API_URL+'/brands').then((response) => {
            let providers_brands = response.data.map(provider => ({
                id: provider.id,
                brand_name: provider.brand_name,
            }));
            this.setState({
                providers_brands: providers_brands
            })
        });
    }

    _createProvider(){

        let formData = {
            provider_company: this.state.new_provider_company,
            provider_type_id: this.state.new_provider_type_id,
            provider_description: this.state.new_provider_description,
            provider_identification: this.state.new_provider_identification,
            provider_country_id: this.state.new_provider_country_id,
            provider_quality_RT: this.state.new_provider_quality_RT,
            provider_quality_DT: this.state.new_provider_quality_DT,
            provider_quality_price: this.state.new_provider_quality_price,
        };

        axios.post(API_URL+'/providers', formData).then((response) => {
            this.setState({
                provider_company: "",
                provider_type_id: "",
                provider_description: "",
                provider_identification: "",
                provider_country_id: "",
                provider_quality_RT: "",
                provider_quality_DT: "",
                provider_quality_price: "",
            })
            this._updateProvidersList();
        })
    }

    addProviderType(){
        //provider_type_id
        if(this.state.provider_type_id !== ''){
            let provider_type = this.state.providers_types.find(x => x.id === this.state.provider_type_id);

            let new_provider_type = {
                key: provider_type.id,
                label: provider_type.provider_type_name,
            }

            let new_chip_data = this.state.providers_types_group;
            new_chip_data.push(new_provider_type);
            this.setState({providers_types_group:new_chip_data})
        }
    }

    addProviderBrand(){

        if(this.state.provider_brand_id !== ''){
            let provider_brand = this.state.providers_brands.find(brand => brand.id === this.state.provider_brand_id);

            let new_provider_brand = {
                key: provider_brand.id,
                label: provider_brand.brand_name,
            }

            let new_chip_data = this.state.providers_brands_group;
            new_chip_data.push(new_provider_brand);
            this.setState({providers_brands_group:new_chip_data})
        }
    }

    addProviderAddress(){

    }

    addPhoneNumber(){

    }

    addFax(){

    }

    addEmail(){

    }

    addWebPage(){

    }

    addContact(){

    }

    handleDelete = data => () => {

        this.setState(state => {
            const providers_types_group = [...state.providers_types_group];
            const chipToDelete = providers_types_group.indexOf(data);
            providers_types_group.splice(chipToDelete, 1);
            return { providers_types_group };
        });
    };


    handleDeleteBrands = data => () => {

        this.setState(state => {
            const providers_brands_group = [...state.providers_brands_group];
            const chipToDelete = providers_brands_group.indexOf(data);
            providers_brands_group.splice(chipToDelete, 1);
            return { providers_brands_group };
        });
    };



    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    handleChangeCountry(event){

        let targetValue = event.target.value;

        this.setState({[event.target.name]:event.target.value}, () => {

            let cities_by_country = this.state.cities.filter(city => {
                return city.country_id === targetValue
            })

            this.setState({cities_by_country:cities_by_country}, () => {
                console.log(this.state)
            })

        });
    }

    handleChange(event) {

        /*switch (event.target.name) {
            case "customerName":
                this.validateCustomerName(event.target.value);
                break;
            case "customerLastName":
                this.validateCustomerLastName(event.target.value);
                break;
            case "customerEmail":
                this.validateEmail(event.target.value);
                break;
            case "customerPhone":
                this.validateCustomerPhone(event.target.value);
                break;
            case "customerCountry":
                this.validateCustomerCountry(event.target.value);
                break;
            case "customerCity":
                this.validateCustomerCity(event.target.value);
                break;
            case "machineCategory":
                this.validateMachineCategory(event.target.value);
                break;
            case "machineBrand":
                this.validateMachineBrand(event.target.value);
                break;
            case "machineModel":
                this.validateMachineModel(event.target.value);
                break;
            case "machineYear":
                this.validateMachineYear(event.target.value);
                break;
            case "machineSN":
                this.validateMachineSN(event.target.value);
                break;
            default:
                //console.log("Another Target Not Validated");
                break;
        }*/

        this.setState({[event.target.name]:event.target.value});
    }

    render() {
        const { classes } = this.props;
        return (

            <Fragment>
                <MuiThemeProvider theme={theme}>
                    <Grid container className={["mainContainer","yellowBackground"].join(" ")}>
                        <Grid item xs={12} className="mainRow">
                            <Paper className="mainPaperCotizar">

                                <Typography variant="h3" className="cotizarTitle">Proveedores</Typography>

                                <AppBar position="static" className={classes.root}>
                                    <Tabs variant="fullWidth" value={this.state.value} onChange={this.handleTabChange} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                                        <Tab label="Listado Proveedores" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}/>
                                        <Tab label="Crear Proveedor" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}  />
                                    </Tabs>
                                </AppBar>

                                {this.state.value === 0 && <TabContainer>
                                    <GridTable
                                        rows={this.state.providersList}
                                        columns={this.state.columns}
                                    >
                                        <PagingState
                                            defaultCurrentPage={0}
                                            pageSize={10}
                                        />
                                        <SelectionState
                                            selection={this.state.selection}
                                            onSelectionChange={this.changeSelection}
                                        />
                                        <SearchState />
                                        <IntegratedFiltering />
                                        <IntegratedPaging />
                                        <Table />
                                        <TableHeaderRow />
                                        <Toolbar />
                                        <SearchPanel
                                            messages={{searchPlaceholder: 'Buscar...'}}
                                            InputProps={{
                                                className: classes.input,
                                            }}
                                        />
                                        <PagingPanel />
                                    </GridTable>
                                </TabContainer>}
                                {this.state.value === 1 &&
                                <Fragment>
                                    <Grid container className={["mainContainer","yellowBackground"].join(" ")}>
                                        <Grid item xs={12} className="mainRow">
                                            <Paper className="mainPaperCotizar">

                                                <Typography variant="h3" className="cotizarTitle">Agregar Proveedor</Typography>

                                                <form autoComplete="off">
                                                    <Grid container spacing={24} className="cotizarFormContainer">
                                                        <Grid item xs={12} md={3}>
                                                            <TextField
                                                                required
                                                                id="outlined-name"
                                                                name="provider_company"
                                                                label="Nombre Empresa"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_company}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_company.length !== 0 }
                                                                helperText={this.state.error_provider_company}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={3}>
                                                            <TextField
                                                                required
                                                                id="outlined-name"
                                                                name="provider_NIT"
                                                                label="NIT Empresa"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_NIT}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_NIT.length !== 0 }
                                                                helperText={this.state.error_provider_NIT}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={3}>
                                                            <TextField
                                                                id="select-country"
                                                                select
                                                                value={this.state.country_id}
                                                                onChange={this.handleChangeCountry.bind(this)}
                                                                error ={this.state.error_country_id.length !== 0 }
                                                                helperText={this.state.error_country_id}
                                                                label="Pais"
                                                                margin="normal"
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                    name: 'country_id',
                                                                }}
                                                                fullWidth
                                                            >
                                                                {this.state.countries.map(option => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.country_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item xs={12} md={3}>
                                                            <TextField
                                                                id="select-city"
                                                                select
                                                                value={this.state.city_id}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_city_id.length !== 0 }
                                                                helperText={this.state.error_city_id}
                                                                label="Ciudad"
                                                                margin="normal"
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                    name: 'city_id',
                                                                }}
                                                                fullWidth
                                                            >
                                                                {this.state.cities_by_country.map(option => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.city_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id="select-type"
                                                                select
                                                                value={this.state.provider_type_id}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_type_id.length !== 0 }
                                                                helperText={this.state.error_provider_type_id}
                                                                label="Tipo de proveedor"
                                                                margin="normal"
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                    name: 'provider_type_id',
                                                                }}
                                                                fullWidth
                                                            >
                                                                {this.state.providers_types.map(option => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.provider_type_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <Button onClick={this.addProviderType.bind(this)} className={["cotizacionPanelButton","add-provider-button"].join(' ')}><FontAwesomeIcon icon={faCog}/> Agregar tipo</Button>
                                                        </Grid>

                                                        <Grid item xs={12} md={12} className='chip-container'>
                                                                {this.state.providers_types_group.map(data => {
                                                                    return (
                                                                        <Chip
                                                                            key={data.key}
                                                                            icon={null}
                                                                            label={data.label}
                                                                            onDelete={this.handleDelete(data)}
                                                                            className='chips-style'
                                                                        />
                                                                    );
                                                                })}
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id="select-type"
                                                                select
                                                                value={this.state.provider_brand_id}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_brand_id.length !== 0 }
                                                                helperText={this.state.error_provider_brand_id}
                                                                label="Marca asociada"
                                                                margin="normal"
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                    name: 'provider_brand_id',
                                                                }}
                                                                fullWidth
                                                            >
                                                                {this.state.providers_brands.map(option => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.brand_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <Button onClick={this.addProviderBrand.bind(this)} className={["cotizacionPanelButton","add-provider-button"].join(' ')}><FontAwesomeIcon icon={faCog}/> Agregar Marca</Button>
                                                        </Grid>

                                                        <Grid item xs={12} md={12} className='chip-container'>
                                                            {this.state.providers_brands_group.map(data => {
                                                                return (
                                                                    <Chip
                                                                        key={data.key}
                                                                        icon={null}
                                                                        label={data.label}
                                                                        onDelete={this.handleDeleteBrands(data)}
                                                                        className='chips-style'
                                                                    />
                                                                );
                                                            })}
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                id="outlined-address"
                                                                name="provider_address"
                                                                label="Dirección"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_address}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_address.length !== 0 }
                                                                helperText={this.state.error_provider_address}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <Button onClick={this.addProviderAddress.bind(this)} className={["cotizacionPanelButton","add-provider-button"].join(' ')}><FontAwesomeIcon icon={faCog}/> Agregar Dirección</Button>
                                                        </Grid>

                                                        <Grid item xs={12} md={12} className='chip-container'>
                                                            {this.state.providers_types_group.map(data => {
                                                                return (
                                                                    <Chip
                                                                        key={data.key}
                                                                        icon={null}
                                                                        label={data.label}
                                                                        onDelete={this.handleDelete(data)}
                                                                        className='chips-style'
                                                                    />
                                                                );
                                                            })}
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                id="outlined-phone-number"
                                                                name="provider_phone_number"
                                                                label="Numero de telefono"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_phone_number}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_phone_number.length !== 0 }
                                                                helperText={this.state.error_provider_phone_number}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <Button onClick={this.addPhoneNumber.bind(this)} className={["cotizacionPanelButton","add-provider-button"].join(' ')}><FontAwesomeIcon icon={faCog}/> Agregar Numero de Telefono</Button>
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                id="outlined-fax"
                                                                name="provider_fax"
                                                                label="Numero de Fax"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_fax}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_fax.length !== 0 }
                                                                helperText={this.state.error_provider_fax}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <Button onClick={this.addFax.bind(this)} className={["cotizacionPanelButton","add-provider-button"].join(' ')}><FontAwesomeIcon icon={faCog}/> Agregar Numero de Fax</Button>
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                id="outlined-email"
                                                                name="provider_email"
                                                                label="Email"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_email}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_email.length !== 0 }
                                                                helperText={this.state.error_provider_email}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <Button onClick={this.addEmail.bind(this)} className={["cotizacionPanelButton","add-provider-button"].join(' ')}><FontAwesomeIcon icon={faCog}/> Agregar Email</Button>
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                id="outlined-email"
                                                                name="provider_web_page"
                                                                label="Pagina Web"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_web_page}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_web_page.length !== 0 }
                                                                helperText={this.state.error_provider_web_page}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <Button onClick={this.addWebPage.bind(this)} className={["cotizacionPanelButton","add-provider-button"].join(' ')}><FontAwesomeIcon icon={faCog}/> Agregar Pagina Web</Button>
                                                        </Grid>

                                                        <Grid item xs={12} md={4}>
                                                            <TextField
                                                                required
                                                                id="outlined-email"
                                                                name="provider_contact_name"
                                                                label="Nombre Contacto"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_contact_name}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_contact_name.length !== 0 }
                                                                helperText={this.state.error_provider_contact_name}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={4}>
                                                            <TextField
                                                                required
                                                                id="outlined-email"
                                                                name="provider_contact_last_name"
                                                                label="Apellido Contacto"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_contact_last_name}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_contact_last_name.length !== 0 }
                                                                helperText={this.state.error_provider_contact_last_name}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={4}>
                                                            <TextField
                                                                required
                                                                id="outlined-email"
                                                                name="provider_contact_phone_number"
                                                                label="Telefono Contacto"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_contact_phone_number}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_contact_phone.length !== 0 }
                                                                helperText={this.state.error_provider_contact_phone}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={4}>
                                                            <TextField
                                                                required
                                                                id="outlined-email"
                                                                name="provider_contact_whatsapp"
                                                                label="WhatsApp Contacto"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_contact_whatsapp}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_contact_whatsapp.length !== 0 }
                                                                helperText={this.state.error_provider_contact_whatsapp}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={4}>
                                                            <TextField
                                                                required
                                                                id="outlined-email"
                                                                name="provider_contact_email"
                                                                label="Email Contacto"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.provider_contact_email}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_provider_contact_email.length !== 0 }
                                                                helperText={this.state.error_provider_contact_email}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={4}>
                                                            <Button onClick={this.addContact.bind(this)} className={["cotizacionPanelButton","add-provider-button"].join(' ')}><FontAwesomeIcon icon={faCog}/> Agregar Contacto</Button>
                                                        </Grid>


                                                        <Grid container className="cotizacionButtonContainer">
                                                            <Grid item xs={12} md={12} className="cotizacionButtonContainer">
                                                                <Button onClick={this._createProvider.bind(this)} className="cotizacionPanelButton"><FontAwesomeIcon icon={faCog}/> Agregar Proveedor</Button>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </form>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

export default withStyles(styles)(ProvidersList);





