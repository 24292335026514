import React, {useState } from 'react';
import Header from '../../components/Header/Header';
import InsuranceResume from "../../components/InsuranceComponent/InsuranceResume";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import queryString from "query-string";


const InsuranceR = ({location}) =>{
    const [data] = useState(queryString.parse(location.search));

    return(
        <>
            <Header/>
            <InsuranceResume id={data.InsuranceId}/>
            <FooterComponent/>
        </>
    );
}

export default InsuranceR;