import React from 'react';
import './ContactDialog.css';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import blue from '@material-ui/core/colors/blue';
import {faComments} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Grid} from "@material-ui/core";

const styles = theme => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    dialogContainer:{
        backgroundColor: blue[100],
    },
    root: {
        borderRadius: 50,
    }
});

class ContactDialog extends React.Component {

    handleClose = () => {
        this.props.onClose(this.props.selectedValue);
    };

    handleOnlineChatClick = () => {
        this.props.onClose();
        window.open("https://tawk.to/chat/608dbe8262662a09efc4013b/1f4kr0c1g");
    };

    handleOfflineMsgClick = () => {
        this.props.onClose();
        window.open("https://tawk.to/chat/608dbe8262662a09efc4013b/1f4kr0c1g");
    };

    handleWhatsappClick = () => {
        this.props.onClose();
        window.open("https://api.whatsapp.com/send?phone=573138052400");
    };



    render() {
        const { classes, onClose, ...other } = this.props;

        return (
            <Dialog
                onClose={this.handleClose}
                aria-labelledby="simple-dialog-title" {...other}
            >
                <Grid container>

                    <Grid item xs={12} className="contactDialogListItem">
                        <Button
                            onClick={() => this.handleWhatsappClick()}
                            className="cotizacionPanelButton"
                        >
                            <FontAwesomeIcon icon={faWhatsapp}/>&nbsp;&nbsp;Chat de Whatsapp</Button>
                    </Grid>

                    <Grid item xs={12} className="contactDialogListItem">
                        <Button
                            onClick={() => this.handleOnlineChatClick()}
                            className="cotizacionPanelButton"
                        >
                            <FontAwesomeIcon icon={faComments}/>&nbsp;&nbsp;Chat en linea</Button>
                    </Grid>

                    {
                        /*

                        <Grid item xs={12} className="contactDialogListItem">
                            <Button
                                onClick={() => this.handleOfflineMsgClick()}
                                className="cotizacionPanelButton"
                            >
                                <FontAwesomeIcon icon={faEnvelope}/>&nbsp;&nbsp;Déjanos un Mensaje</Button>
                        </Grid>

                        * */
                    }

                </Grid>
            </Dialog>
        );
    }
}

export default withStyles(styles)(ContactDialog);
