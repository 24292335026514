import React from 'react';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Excavator from "../../images/excavator.svg";
import Backhoe from "../../images/backhoe.svg";
import Bulldozer from "../../images/bulldozer.svg";
import { useHistory } from 'react-router-dom';
import TMPButton from '../primitive/TMPButton/TMPButton';
import './TopSearchedFor.css';

const TopSearchedFor = () => {

    const history = useHistory();

    const searchedFor = [
        {
            src: Excavator,
            btnText: 'Excavadoras',
            path: '/ventas?category_id=3&category_name=Maquinaria%20de%20Construcción&subcategory_id=31&subcategory_name=Excavadora%20hidráulica'
        },
        {
            src: Backhoe,
            btnText: 'Retroexcavadoras',
            path: '/ventas?category_id=3&category_name=Maquinaria%20de%20Construcción&subcategory_id=34&subcategory_name=Retroexcavadora'
        },
        {
            src: Bulldozer,
            btnText: 'Bulldozer',
            path: '/ventas?category_id=3&category_name=Maquinaria%20de%20Construcción&subcategory_id=35&subcategory_name=Tractor%20oruga%20(Construcción)'
        }
    ];
    
    return (
        <Container maxWidth="xl">
            <Grid container spacing={4}>
                {
                    searchedFor.map((e, index) => (
                        <Grid item xs={12} sm={4} key={index} onClick={() => history.push(e.path)} className='TopSearchedFor-root'>
                            <img src={e.src} alt={e.btnText} className='TopSearchedFor-image' />
                            <TMPButton 
                                variant='sm-dark' 
                                onClick={() => history.push(e.path)} 
                                text={e.btnText}
                            />
                        </Grid>
                    ))
                }  
            </Grid>
        </Container>
    )
}

export default TopSearchedFor;