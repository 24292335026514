import React, { Component, Fragment } from 'react';
import './Cotizacion.css';
import Header from '../../components/Header/Header';
import CotizacionForm from '../../components/CotizacionForm/CotizacionForm';
import {Redirect} from "react-router-dom";

class Cotizacion extends Component{
    redirectToHome = () => {
        return <Redirect push to='/' />
    }
    render(){
        return(
            <Fragment>
                {this.redirectToHome()}
                <Header/>
                <CotizacionForm/>
            </Fragment>
        );
    }
}

export default Cotizacion;