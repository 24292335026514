import React from 'react';
import Whatsapp from "../../images/whatsapp.svg";
import './WhatsappFloatButton.css';

const WhatsappFloatButton = () => {

    return (
        <div className='WhatsappFloatButton-root'>
            <a
              href="https://api.whatsapp.com/send?phone=573138052400"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Whatsapp}
                className="WhatsappFloatButton-icon"
                alt="WhatsappFloatButton"
              />
            </a>
        </div>
    )

}

export default WhatsappFloatButton;