import React, { Component, Fragment } from 'react';
import Header from '../../components/Header/Header';
import MaintenanceComponent from '../../components/MaintenanceComponent/MaintenanceComponent';
import FooterComponent from "../../components/FooterComponent/FooterComponent";


class Maintenance extends Component{
    render(){
        return(
            <Fragment>
                <Header/>
                <MaintenanceComponent/>
                <FooterComponent/>
            </Fragment>
        );
    }
}

export default Maintenance;