import React, { Component, Fragment } from 'react';
import './ProviderTypes.css';
import ProvidersTypesComponent from "../../components/ProviderTypesComponent/ProviderTypesComponent";


class ProviderTypes extends Component{
    render(){
        return(
            <Fragment>
                <ProvidersTypesComponent/>
            </Fragment>
        );
    }
}

export default ProviderTypes;