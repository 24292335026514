import React, { useState} from 'react';
import './AdminDashboard.css';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import GroupIcon from '@material-ui/icons/Group';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CardContent from "@material-ui/core/CardContent";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ContactsIcon from "@material-ui/icons/Contacts";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DescriptionIcon from '@material-ui/icons/Description';
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import DateRangeIcon from "@material-ui/icons/DateRange";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import {Link, Redirect} from "react-router-dom";
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

const AdminDashboard = () => {

    const [ redirectToLogin, setRedirectToLogin ] = useState(false)

    return(
        <React.Fragment>
            <Grid container>
                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><SettingsTwoToneIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    MAQUINARIA
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <Link to='dashboard/machines-list' className='HomeComponentCardListLink'>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Listado de Maquinaria" />
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><GroupIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    CLIENTES (CRM)
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <Link to='clientes' className='HomeComponentCardListLink'>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Clientes" />
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><FormatListNumberedIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    ORDENES
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <Link to='ordenes-pedido' className='HomeComponentCardListLink'>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Ordenes de Pedido" />
                                        </ListItem>
                                    </Link>

                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardArrowRightIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Ordenes de Compra" />
                                    </ListItem>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardArrowRightIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Ordenes de Venta" />
                                    </ListItem>

                                    <Link to='metricas-ordenes-pedido' className='HomeComponentCardListLink'>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Metricas" />
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>

                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><ShoppingCartIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    VENTAS
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardArrowRightIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Ventas" />
                                    </ListItem>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>

                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><AssignmentIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    INVENTARIO
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <Link to='admin-productos' className='HomeComponentCardListLink'>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Administrar productos" />
                                        </ListItem>
                                    </Link>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardArrowRightIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Inventario" />
                                    </ListItem>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>

                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><ContactsIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    RH
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardArrowRightIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Recursos Humanos" />
                                    </ListItem>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>

                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><MonetizationOnIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    FINANZAS
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <Link to='recibos-de-caja' className='HomeComponentCardListLink'>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Recibos de Caja" />
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>

                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><LocalShippingIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    PRODUCCIÓN
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardArrowRightIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Producción" />
                                    </ListItem>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>

                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><DateRangeIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    PLANEACIÓN
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <ListItem button>
                                        <ListItemIcon>
                                            <KeyboardArrowRightIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Planeación" />
                                    </ListItem>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>

                <Grid  item xs={12} sm={4} className='HomeComponentCardContainer'>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <CardContent
                                classes={{
                                    root: 'admin-card-content-root'
                                }}
                            >
                                <div><DescriptionIcon className='HomeComponentCardIcons'/></div>
                                <Typography gutterBottom variant="h5" component="h2" className='HomeComponentCardTitle'>
                                    SOLICITUDES
                                </Typography>
                            </CardContent>

                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='HomeComponentCardExpansion'>
                            <div className='HomeComponentCardList'>
                                <List component="nav" aria-label="Main mailbox folders">
                                    <Link to='viaticos' className='HomeComponentCardListLink'>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <KeyboardArrowRightIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Viáticos" />
                                        </ListItem>
                                    </Link>
                                </List>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AdminDashboard;