import React, { Fragment } from 'react';
import './SearchMachine.css';
import SearchIcon from '@material-ui/icons/Search';
import SearchMachineController from './SearchMachineController';
import SellRentGalleryItemImages from "../SellRentGalleryItemImages/SellRentGalleryItemImages";
import NumberFormat from "react-number-format";
import { Button } from '@material-ui/core';
import Loading from '../LoadingComponent/Loading';
import Logo from '../../images/logo.svg';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import CancelIcon from '@material-ui/icons/Cancel';

const SearchMachine = () => {

    const controllerProps = SearchMachineController({});

    const { 
        searchText,
        setSearchText,
        handleSearch,
        loadingMachineSearching,
        paginationData,
        machinesData,
        onLoadMore,
        onClose,
        goToMachine,
        handleKeyPress,
    } = controllerProps;

    return(
        <div className='searchMachineWrapper'>

            <div className='searchMachineRoot'>
                <input 
                    type="search" 
                    id="search-machine" 
                    name="search-machine" 
                    aria-label="Search Machine By Reference" 
                    className='searchMachineInput' 
                    placeholder='CONSULTA TU REFERENCIA AQUÍ'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                />
                <div className='searchButton' onClick={handleSearch}>
                    <SearchIcon className="searchButtonIcon"/>
                </div>
            </div>

            <div className='searchMachineList'>
                
                <Loading loadingImage={Logo} loadingText={'Buscando maquinaria.'} loadingState={loadingMachineSearching}/>

                {
                    machinesData.length > 0 && <div onClick={onClose} className="searchMachineOnCloseButton"><CancelIcon/></div>
                }

                {
                    machinesData.map((machine, index) => (
                        <div key={index}>
                            <SingleMachineItem machine={machine} goToMachine={goToMachine}/>
                        </div>
                    ))
                }

                {
                    machinesData.length > 0 && paginationData.next_page_url &&
                    <Button variant="contained" color="secondary" onClick={() => onLoadMore()} className="searchMachineLoadMoreButton" fullWidth>
                       <ArrowDownwardSharpIcon className="searchMachineLoadMoreIcon"/> Ver Mas <ArrowDownwardSharpIcon className="searchMachineLoadMoreIcon"/>
                    </Button>
                }

                {
                    machinesData.length > 0 &&
                    <Button variant="contained" color="primary" onClick={() => onClose()} className="searchMachineLoadMoreButton" fullWidth>
                       Cerrar <CancelIcon color="secondary" className="searchMachineLoadMoreIcon"/>
                    </Button>
                }
                
            </div>
        </div>
        
    )
}

const SingleMachineItem = ({ machine, goToMachine }) => {
    return (
        <Fragment>
            <div>
                <div className='searchMachineDetailsTitle' onClick={() => goToMachine(machine.id)}>{`${machine.subcategory_name} - ${machine.brand_name} ${machine.model}`}</div>

                <div className='searchMachineInfoContainer'>

                    <div className='searchMachineImages'>
                        <SellRentGalleryItemImages data={machine.images} id={machine.id} single={true} status={machine.status} variant="small"/>
                    </div>

                    <div className='searchMachineDetails' onClick={() => goToMachine(machine.id)}>
                        <div className='searchMachinePriceTextBold'>PUBLICACIÓN #{machine.id}</div>
                        <div>
                            <NumberFormat
                                value={machine.sale_price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={machine.currency + ' $ '}
                            />
                        </div>
                        <div className='searchMachineData'>
                            <div>{`MARCA: ${machine.brand_name}`}</div>
                            <div>{`MODELO: ${machine.model}`}</div>
                            <div>{`AÑO: ${machine.year}`}</div>
                            <div>{`HORAS: ${machine.horometer}`}</div>
                            <div>{`UBICACIÓN: ${machine.location_city} ${machine.location_country}`}</div>
                        </div>
                    </div>

                </div>
                
            </div>
        </Fragment>
    )
}

export default SearchMachine;