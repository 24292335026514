import React from 'react';
import './MyProfile.css';
import MyProfileController from './MyProfileController';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import TMPSimpleCard from '../../primitive/TMPSimpleCard/TMPSimpleCard';

const MyProfile = () => {

    const controllerProps = MyProfileController({});

    const { user } = controllerProps;

    if(!user) return null;

    return(
        <div className='MyProfile-root'>
            <Container component="main" maxWidth="xl">
                <CssBaseline />
                <div className='MyProfile-paper'>

                    <Grid container spacing={4}>

                        <Grid item xs={12} md={6}>
                            <TextField
                                className='MyProfile-item'
                                name="firstName"
                                variant="outlined"
                                fullWidth
                                id="firstName"
                                label="Nombres"
                                value={user.name}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                className='MyProfile-item'
                                variant="outlined"
                                fullWidth
                                id="lastName"
                                label="Apellidos"
                                name="lastName"
                                autoComplete="lname"
                                value={user.last_name}
                                disabled
                            />
                        </Grid>

                        { /* PAIS */ }

                        <Grid item xs={12} md={6}>
                            <TextField
                                className='MyProfile-item'
                                autoComplete="phoneNumber"
                                name="phoneNumber"
                                variant="outlined"
                                fullWidth
                                id="phoneNumber"
                                label="Número de teléfono"
                                value={user.phone_number}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                className='MyProfile-item'
                                variant="outlined"
                                fullWidth
                                id="email"
                                label="Correo"
                                name="email"
                                autoComplete="email"
                                value={user.email}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TMPSimpleCard title={'Número de publicaciones'} value={ user.publications ? user.publications : 0 }/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TMPSimpleCard title={'Número de publicaciones destacadas'} value={ user.outstandings ? user.outstandings : 0 }/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TMPSimpleCard title={'Número de publicaciones urgentes'} value={ user.urgent_sales ? user.urgent_sales : 0 }/>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TMPSimpleCard title={'Número de publicaciones gestionandas por TMP'} value={ user.managements ? user.managements : 0 }/>
                        </Grid>
                        
                    </Grid>

                </div>
            </Container>
        </div>
    )
}

export default MyProfile;