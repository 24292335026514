import React from 'react';
import './TMPButton.css';

const TMPButton = props => {

    const {
        onClick,
        text,
        variant,
        disabled = false,
        loading = false,
    } = props;
   
    const getClassName = (variant) => {
        switch(variant){
            case 'sm-dark':
                return 'TMPButtonSmallDark'
            case 'dark':
                return 'TMPButtonDark'
            case 'light':
                return 'TMPButtonLight'
            default:
                return 'TMPButtonDark'
        }
    }

    return (
        <div onClick={onClick} className={`${getClassName(variant)} ${onClick ? 'TMPButtonLink' : ''} ${disabled ? 'TMPButtonDisabled' : ''}`} >
            {loading ? 'Cargando...' : text}
        </div>
    )

}

export default TMPButton;