import React from 'react';
import './Loading.css';
import { 
    Dialog, 
    DialogContent, 
    CircularProgress, 
    withMobileDialog  
} from '@material-ui/core';
import PropTypes from 'prop-types';

const Loading = (props) => {

    const {
        loadingState,
        loadingImage,
        loadingText,
    } = props;

    return(
        <Dialog
            fullScreen={false}
            fullWidth={true}
            open={loadingState}
            onClose={() => null}
            aria-labelledby='responsive-dialog-title'
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            classes={{
                paper: 'loading-background-paper',
            }}
        >
            <DialogContent className='loading-dialog-content'>
                <div className='loading-content-container'>
                    <img src={loadingImage} className='loading-logo-img' alt='loading-image'/>
                    <CircularProgress size={100}  className='loading-circle-img'/>
                </div>
                <div className='loading-text'>
                    <span>{loadingText}</span>
                </div>
            </DialogContent>
        </Dialog>
    )
    
}

Loading.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(Loading);