import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Button from "@material-ui/core/Button";
import { 
    GET_ADMIN_MACHINE_LIST, 
    GET_MACHINE_CATEGORIES, 
    GET_MACHINE_SUBCATEGORIES,
    GET_MACHINE_BRANDS, 
    GET_ADMIN_MACHINE_SEARCH ,
    UPDATE_MACHINE_STATUS,
    SET_MACHINE_VISIBILITY
} from '../../API';
import './AdminMachines.css'
import CompareArrowsTwoToneIcon from '@material-ui/icons/CompareArrowsTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { useHistory } from 'react-router-dom';
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';

export const AdminMachinesController = () => {

    const history = useHistory();

    const [ searchText, setSearchText ] = useState('');
    const [ tabValue, setTabValue ] = useState(0);
    const [ machines, setMachines ] = useState([]);
    const [ categories, setCategories ] = useState([]);
    const [ subcategories, setSubcategories ] = useState([]);
    const [ brands, setBrands ] = useState([]);
    const [ loadingData, setLoadingData ] = useState(false);
    const [ loadingCategories, setLoadingCategories ] = useState(false);
    const [ loadingSubcategories, setLoadingSubcategories ] = useState(false);
    const [ loadingBrands, setLoadingBrands ] = useState(false);
    const [ loadingMachineVisibility, setLoadingMachineVisibility ] = useState(false);
    const [ paginationData, setPaginationData ] = useState({});
    const [ isChangeStatusModalOpen, setIsChangeStatusModalOpen ] = useState(false);
    const [ currentMachine, setCurrentMachine ] = useState(null);
    const [ newStatus, setNewStatus ] = useState('');
    const [ updateMachineStatusLoading, setUpdateMachineStatusLoading ] = useState(false);
    

    const [ options, setOptions ] = useState({
        hideSizePerPage: true,
        page: 1,
        totalSize: 1,
        sizePerPage: 10,
        paginationSize: 1,
    })

    const machineTypes = [
        'active',
        'payment_pending',
        'sold',
        'expired'
    ]

    const tempStatus = {
        active: 'Activa',
        payment_pending: 'Pago Pendiente',
        sold: 'Vendida',
        expired: 'Expirada'
    }

    useEffect(() => {
        getCategories()
        getSubCategories()
        getBrands()
    }, [])

    useEffect(() => {
        if(categories.length === 0 || subcategories.length === 0 || brands.length === 0) return 
        updateMachines()
    }, [categories, subcategories, brands, tabValue])

    useEffect(() => {
        filterMachines()
    }, [searchText])

    const handleSearch = (value) => {
        setSearchText(value)
    }

    const handleCreateMachine = () => {
        history.push('/admin/dashboard/new-machine')
    }

    const handleEditMachine = (id) => {
        history.push(`/maquina/editar?maquinaId=${id}`)
    }

    const handleTabValueChange = (value) => {
        setTabValue(value)
        setSearchText('')
    }

    const getCategories = () => {
        setLoadingCategories(true)
        axios.get(GET_MACHINE_CATEGORIES).then((response) => {
            if(response.data.success){
                setCategories(response.data.data)
            }
        }).catch((error) => {
            setLoadingCategories(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de categorias.")
            }
        })
    }

    const getSubCategories = () => {
        setLoadingSubcategories(true)
        axios.get(GET_MACHINE_SUBCATEGORIES).then((response) => {
            if(response.data.success){
                setSubcategories(response.data.data)
            }
        }).catch((error) => {
            setLoadingSubcategories(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de subcategorias.")
            }
        })
    }

    const getBrands = () => {
        setLoadingBrands(true)
        axios.get(GET_MACHINE_BRANDS).then((response) => {
            if(response.data.success){
                setBrands(response.data.data)
            }
        }).catch((error) => {
            setLoadingBrands(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de marcas.")
            }
        })
    }

    const updateMachines = () => {
        setLoadingData(true)
        axios.get(`${GET_ADMIN_MACHINE_LIST}/${machineTypes[tabValue]}`).then((response) => {
            //missing validation of response
            updatePagintationData(response)
            formatMachines(response.data.data.data)
            setLoadingData(false)
        }).catch((error) => {
            setLoadingData(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de maquinas.")
            }
        })
    }

    const updatePagintationData = (response) => {
        setPaginationData(response.data.data)
        setOptions({ 
            ...options, 
            ...{
                page: response.data.data.current_page,
                paginationSize: response.data.data.last_page < 10 ? response.data.data.last_page : 10,
                totalSize: response.data.data.total
            }
        })
    }

    const onTableChange = (type, newState) => {
        setLoadingData(true)
        axios.get(`${paginationData.path}?page=${newState.page}`).then((response) => {
            //missing validation of response
            updatePagintationData(response)
            formatMachines(response.data.data.data)
            setLoadingData(false)
        }).catch((error) => {
            setLoadingData(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error de servidor cargando listado de maquinas.")
            }
        })
    }

    const formatMachines = (response) => {
        const machines_aux = response.map((machine) => {
            machine.category = getCategory(machine.category_id)
            machine.subcategory = getSubcategory(machine.subcategory_id)
            machine.brand = getBrand(machine.motor_brand_id)
            machine.price = `${machine.sale_price} ${machine.currency}`
            machine.contact = `${machine.contact_name} ${machine.contact_last_name}`
            machine.phone = `+${machine.contact_country_dial_code} ${machine.contact_phone_number_1}`
            machine.actions =  setMachineActions(machine)
            machine.visibility =  setVisibilityActions(machine)
            return machine
        })
        setMachines(machines_aux)
    }

    const setMachineVisibility = (machine_id, is_visible) => {
        setLoadingMachineVisibility(true)
        let formData = { machine_id, is_visible };
        axios.post(SET_MACHINE_VISIBILITY, formData).then((response) => {
            setLoadingMachineVisibility(false)
            updateMachines()
        }).catch((error) => {
            setLoadingMachineVisibility(false)
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error cambiando visibilidad de la maquinaria.")
            }
        })
    }

    const setMachineActions = (machine) => {
        return <Fragment>       
            <Button
                onClick={() => openChangeMachineStatusModal(machine)}
            >
                <CompareArrowsTwoToneIcon />    
            </Button>      
            <Button
                onClick={() => handleEditMachine(machine.id)}
            >
                <EditTwoToneIcon />
            </Button>
        </Fragment>
    }

    const setVisibilityActions = (machine) => {
        return <Fragment>       
            {
                ( machineTypes[tabValue] === 'sold' || machineTypes[tabValue] === 'active' ) &&
                <Button
                    onClick={() => setMachineVisibility(machine.id, !machine.is_visible) }
                >
                    { machine.is_visible ? <VisibilityTwoToneIcon /> : <VisibilityOffTwoToneIcon /> }
                </Button>
            }
        </Fragment>
    }

    const openChangeMachineStatusModal = (machine) => {
        setIsChangeStatusModalOpen(true)
        setCurrentMachine(machine)
        setNewStatus(machine.status)
    }

    const getCategory = (id) => {
        const cat = categories.find((category) => category.id == id)
        if(cat) return cat.name
        return ''
    }

    const getSubcategory = (id) => {
        const subcat = subcategories.find((subcategory) => subcategory.id === id)
        if(subcat) return subcat.name
        return ''
    }

    const getBrand = (id) => {
        const br = brands.find((brand) => brand.id === id)
        if(br) return br.name
        return ''
    }

    const filterMachines = () => {
        if(searchText === ''){
            updateMachines();
        } else {
            //this.setState({loadingData:true})
            axios.get(`${GET_ADMIN_MACHINE_SEARCH}/${machineTypes[tabValue]}/${searchText}`).then((response) => {
                //missing validation of response
                updatePagintationData(response)
                formatMachines(response.data.data)
                //setLoadingData(false)
            }).catch((error) => {
                console.log(error)
                //setLoadingData(false)
                if (error.response && error.response.data && error.response.data.message) {
                    alert(error.response.data.message)
                } else {
                    alert("ERROR: error de servidor cargando listado de maquinas.")
                }
            })
        }
    }

    const changeMachineStatus = () => {
        if(currentMachine.status !== newStatus) {
            if (window.confirm(`Estas seguro de cambiar la maquina ${currentMachine.id} del estado de ${tempStatus[currentMachine.status]} al estado ${tempStatus[newStatus]} ?`)) {

                let formData = {
                    machine_id: currentMachine.id,
                    status: newStatus
                };

                setUpdateMachineStatusLoading(true)
                axios.post(UPDATE_MACHINE_STATUS, formData).then((response) => {
                    setIsChangeStatusModalOpen(false)
                    setUpdateMachineStatusLoading(false)
                    updateMachines()
                }).catch((error) => {
                    setIsChangeStatusModalOpen(false)
                    setUpdateMachineStatusLoading(false)
                    if (error.response && error.response.data && error.response.data.message) {
                        alert(error.response.data.message)
                    } else {
                        alert(`ERROR: error de servidor actualizando estado de la maquinaria #${currentMachine.id}`)
                    }
                })
                

            }
        } else {
            alert('El nuevo estado de la maquinaria tienes que ser diferente al estado actual')
        }
    }

    const handleChangeStatusModalClose = () => {
        setIsChangeStatusModalOpen(false)
    }

    return {
        handleCreateMachine,
        searchText,
        handleSearch,
        handleTabValueChange,
        tabValue,
        machines,
        onTableChange,
        loadingData,
        loadingMachineVisibility,
        options,
        machineTypes,
        isChangeStatusModalOpen,
        handleChangeStatusModalClose,
        changeMachineStatus,
        newStatus,
        setNewStatus,
        updateMachineStatusLoading,
    }
}
