import React, { Component, Fragment } from 'react';
import './CitiesComponent.css';
import {Grid, Paper, Typography, AppBar, Tabs, Tab, TextField, MenuItem, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import {
    SelectionState,
    PagingState,
    IntegratedPaging,
    SearchState,
    IntegratedFiltering,

} from '@devexpress/dx-react-grid';
import {
    Grid as GridTable,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel
} from '@devexpress/dx-react-grid-material-ui';


import axios from "axios";
import {API_URL} from "../../API";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiInput: {
            root:{
                width: "100%",
                "&$focused": {
                    '&:after': {
                        borderBottom: "2px solid #000000 !important",
                        width: "100%"
                    }
                },
            },

        }
    }
});

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#000000',
    },
    tabsRoot: {
        borderBottom: '1px solid #000000',
    },
    tabsIndicator: {
        backgroundColor: '#ff9b30',
        height: "4px",
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: "\"machineFont\" !important",
        fontSize: "18px",
        '&:hover': {
            color: '#ff9b30',
            opacity: 5,
        },
        '&:focus': {
            color: '#ff9b30',
        },
    },
    tabSelected: {
        color: '#ff9b30',
    },
    typography: {
        padding: theme.spacing.unit * 3,
    },
    input: {
        backgroundColor: "blue",
    }
});

class CitiesComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            value: 0,
            list: [],
            countries:[],
            columns: [
                { name: 'id', title: 'Id' },
                { name: 'city_name', title: 'Nombre de la ciudad' },
                { name: 'country_name', title: 'Pais' },
                { name: 'actions', title: 'Acciones' },
            ],

            city_name: '',
            error_city_name: '',

            country_id: '',
            error_country_id: '',

        };

        this.changeSelection = selection => this.setState({ selection });
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this._getCountries();

    };

    _getCountries(){
        axios.get(API_URL+'/countries').then((response) => {


            let elements = response.data.map(element => ({
                id: element.id,
                country_name: element.country_name,
                country_code: element.country_code,
                country_dial_code: element.country_dial_code,
                actions: this.addActions.call(this,  element.id ),
            }));
            this.setState({
                countries: elements
            })
            this._updateList();
        });
    }

    getCountryName(country_id){
        let country = this.state.countries.find(country => country.id === country_id);
        return country.country_name;
    }

    _updateList(){
        axios.get(API_URL+'/cities').then((response) => {


            let elements = response.data.map(element => ({
                id: element.id,
                city_name: element.city_name,
                country_id: element.country_id,
                country_name: this.getCountryName(element.country_id),
                actions: this.addActions.call(this,  element.id ),
            }));


            this.setState({
                list: elements
            })
        });
    }

    addActions = (id) => {
        return (
            <div>
                <Button

                    color="primary"
                    onClick={this.updateAction.bind(this, id)}
                >
                    Editar
                </Button>

                <Button

                    color="primary"
                    onClick={this.deleteAction.bind(this, id)}
                >
                    Eliminar
                </Button>
            </div>
        );
    };

    updateAction(id) {
        alert("Proximamente editando: " + id)
    }

    deleteAction(id) {
        alert("Proximamente eliminando: " + id)
    }

    _create(){

        let formData = {
            city_name: this.state.city_name,
            country_id: this.state.country_id,
        };

        axios.post(API_URL+'/cities', formData).then(() => {
            this.setState({
                city_name: "",
                country_id: "",
            })
            this._updateList();
        })
    }

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    handleChange(event) {
        this.setState({[event.target.name]:event.target.value});
    }

    render() {
        const { classes } = this.props;
        return (

            <Fragment>
                <MuiThemeProvider theme={theme}>
                    <Grid container className={["mainContainer","yellowBackground"].join(" ")}>
                        <Grid item xs={12} className="mainRow">
                            <Paper className="mainPaperCotizar">

                                <Typography variant="h3" className="cotizarTitle">Parametrización Ciudades</Typography>

                                <AppBar position="static" className={classes.root}>
                                    <Tabs variant="fullWidth" value={this.state.value} onChange={this.handleTabChange} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                                        <Tab label="Listado de Paises" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}/>
                                        <Tab label="Crear Pais" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}  />
                                    </Tabs>
                                </AppBar>

                                {this.state.value === 0 && <TabContainer>
                                    <GridTable
                                        rows={this.state.list}
                                        columns={this.state.columns}
                                    >
                                        <PagingState
                                            defaultCurrentPage={0}
                                            pageSize={10}
                                        />
                                        <SelectionState
                                            selection={this.state.selection}
                                            onSelectionChange={this.changeSelection}
                                        />
                                        <SearchState />
                                        <IntegratedFiltering />
                                        <IntegratedPaging />
                                        <Table />
                                        <TableHeaderRow />
                                        <Toolbar />
                                        <SearchPanel
                                            messages={{searchPlaceholder: 'Buscar...'}}
                                            InputProps={{
                                                className: classes.input,
                                            }}
                                        />
                                        <PagingPanel />
                                    </GridTable>
                                </TabContainer>}
                                {this.state.value === 1 &&
                                <Fragment>
                                    <Grid container className={["mainContainer","yellowBackground"].join(" ")}>
                                        <Grid item xs={12} className="mainRow">
                                            <Paper className="mainPaperCotizar">

                                                <Typography variant="h3" className="cotizarTitle">Crear Ciudad</Typography>

                                                <form autoComplete="off">
                                                    <Grid container spacing={24} className="cotizarFormContainer">

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                id="outlined-city-name"
                                                                name="city_name"
                                                                label="Nombre de la ciudad"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.city_name}
                                                                onChange={this.handleChange}
                                                                error ={this.state.error_city_name.length !== 0 }
                                                                helperText={this.state.error_city_name}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                id="select-country"
                                                                select
                                                                error ={this.state.error_country_id.length !== 0 }
                                                                helperText={this.state.error_provider_country_id}
                                                                label="Pais"
                                                                margin="normal"
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                    name: 'country_id',
                                                                }}
                                                                fullWidth
                                                                value={this.state.country_id}
                                                                onChange={this.handleChange}
                                                            >
                                                                {this.state.countries.map(option => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.country_name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid container className="cotizacionButtonContainer">
                                                            <Grid item xs={12} md={12} className="cotizacionButtonContainer">
                                                                <Button onClick={this._create.bind(this)} className="cotizacionPanelButton"><FontAwesomeIcon icon={faCog}/> Agregar Ciudad</Button>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </form>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

export default withStyles(styles)(CitiesComponent);





