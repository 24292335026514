import React, { useEffect, useState } from 'react';
import MyPostComponent from './MyPostComponent';
import axios from 'axios';
import { API_URL, GET_USER_MACHINES } from '../../API';
import { message_responses } from '../../MESSAGE_RESPONSES';

const MyPostsComponent = () => {

    const [ loadingItems, setLoadingItems ] = useState(false);
    const [ items, setItems ] = useState([]);

    useEffect(() => {
        getProducts();
    }, [])

    const getProducts = () => {

        let user = JSON.parse(localStorage.getItem('TMPData'));
        if(user){
            let userId = user.user.id;    

            setLoadingItems(true);
    
            axios.get(API_URL + GET_USER_MACHINES + userId).then((response) => {
                setLoadingItems(false);
                if(response.data.success){
                    setItems(response.data.data);
                }else{
                    alert(message_responses[response.data.message]);
                }
            }).catch((error) => {
                setLoadingItems(false);
                if(error.response && error.response.data){
                    alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
                }else{
                    alert("Por favor discúlpanos :( , hemos tenido un error cargando tus maquinas.")
                }
            })
        }
        
    }

    return(
        <>
            {
                items.length === 0 && 
                <>
                    Aun no has publicado ninguna maquinaria.
                </>
            }
            {items.map((item) => (
                <MyPostComponent machine={
                    {
                        id: item.id,
                        category: item.category,
                        subcategory: item.subcategory,
                        model: item.model,
                        currency: item.currency,
                        sale_price: item.sale_price,
                        location_country: item.location_country,
                        location_city: item.location_city,
                        visits: item.visits,
                        image: item.images[0] ? item.images[0].path : '',
                        getProducts: getProducts,
                        status: item.status
                    }
                }/>
            ))}
            
        </>
    );
}

export default MyPostsComponent;