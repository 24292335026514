import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';

export const useAuthUser = () => {
  const [ isAuth, setIsAuth ] = useState(false);
  const [ authUser, setAuthUser ] = useState();
  const history = useHistory();  

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('TMPData'));

    if(user){
      setIsAuth(true)
      setAuthUser(user)
    }else{
      setIsAuth(false)
      setAuthUser(undefined)
      history.push('/login')
    }
  }, [])

  return { isAuth, authUser }
}
