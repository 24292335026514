import React, { Fragment } from 'react';
import OutstandingMachinesController from './OutstandingMachinesController';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import SellRentGalleryItem from "../SellRentGalleryItem/SellRentGalleryItem";
import TMPButton from '../primitive/TMPButton/TMPButton';
import TitleLabel from '../../components/primitive/TitleLabel/TitleLabel';

const OutstandingMachines = () => {

    const controllerProps = OutstandingMachinesController({});

    const { 
        loading,
        machinesData,
        onLoadMore,
        paginationData,
    } = controllerProps;

    return (
        <Container maxWidth="xl">

            { machinesData && machinesData.length > 0 && 
                <Fragment>
                    <TitleLabel text='Publicaciones destacadas' variant='dark' /> 
                    <Grid container spacing={4}>
                        {
                            machinesData.map( machine => (
                                <Grid item xs={12} sm={6} md={4} xl={3} key={machine.id}  >
                                    <SellRentGalleryItem data={machine}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <TMPButton 
                        variant='sm-dark' 
                        loading={loading}
                        disabled={!!paginationData && paginationData.next_page_url === null}
                        onClick={onLoadMore} 
                        text='Ver más destacados' 
                    />
                </Fragment>
            }
            
            
        </Container>
    )
}

export default OutstandingMachines;