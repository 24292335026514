import React from 'react';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Logo from "../../images/logo.png";
import {Link, Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/styles";
import axios from 'axios';
import { API_URL, USER_SIGN_UP } from "../../API";
import {message_responses} from "../../MESSAGE_RESPONSES";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {all_countries} from '../../countries';

const styles = {
    paper: {
        margin: '10% 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    logoContainer: {
        textAlign: 'center',
    },
    logo: {
        maxHeight: '100%',
        maxWidth: '50%',
    },
    error: {
        color: '#ef2020',
    },
    success:{
        color: '#28a745'
    },
    progress: {
        color: '#ff9b01',
    },
    submit: {
        minHeight: '56px',
        color: '#000000',
        borderRadius: '4px !important',
    },
    link: {
        textDecoration: 'none',
        color: '#000000 !important',
        '&:hover': {
            textDecoration: 'underline',
            color: '#000000 !important',
        }
    }
};


class SignUpComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            password: '',
            passwordConfirm: '',

            firstNameError: '',
            lastNameError: '',
            phoneNumberError: '',
            emailError: '',
            passwordError: '',
            passwordConfirmError: '',
            signUpError: '',
            signUpSuccess: '',

            redirectToHome: false,
            doingSignUp: false,

            showPassword: false,
            showPasswordConfirm: false,

            all_countries
        }
    }

    componentDidMount() {
        this.checkAuth();
    }

    checkAuth(){
        if(localStorage.getItem('TMPData')){
            this.setState({redirectToHome: true})
        }
    }

    handleClickShowPassword(){
        this.setState({showPassword:!this.state.showPassword})
    }

    handleMouseDownPassword(event){
        event.preventDefault();
    }

    handleClickShowPasswordConfirm(){
        this.setState({showPasswordConfirm:!this.state.showPasswordConfirm})
    }

    handleMouseDownPasswordConfirm(event){
        event.preventDefault();
    }

    redirectToHome = () => {
        if (this.state.redirectToHome) {
            return <Redirect push to='/' />
        }
    }

    clearForm(){
        this.setState({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            password: '',
            passwordConfirm: '',

            firstNameError: '',
            lastNameError: '',
            phoneNumberError: '',
            emailError: '',
            passwordError: '',
            passwordConfirmError: '',
            signUpError: '',
            signUpSuccess: '',
            country: null,
            country_error: '',

            redirectToHome: false,
            doingSignUp: false,

            showPassword: false,
            showPasswordConfirm: false,
        })
    }

    doSignUp(){
        if(this.validateField()) {

            this.setState({doingSignUp: true})

            let formData = {
                name: this.state.firstName,
                last_name: this.state.lastName,
                country: this.state.country.label,
                phone_number: '+(' + this.state.country.phone + ')' + this.state.phoneNumber,
                email: this.state.email,
                password: this.state.password,
                c_password: this.state.passwordConfirm,
            }

            console.log(formData)
            
            axios.post(API_URL + USER_SIGN_UP, formData).then((response) => {
                this.setState({doingSignUp:false})
                if(response.data.success){
                    this.clearForm();
                    localStorage.clear();
                    this.setState({
                        signUpSuccess: message_responses[response.data.message],
                    })
                }else{
                    localStorage.clear();
                    this.setState({
                        signUpError: message_responses[response.data.message],
                    })
                }
            }).catch((error) => {
                localStorage.clear();
                this.setState({doingSignUp:false})
                if(error.response && error.response.data){
                    this.setState({
                        signUpError: message_responses[error.response.data.message],
                    })
                }else{
                    this.setState({
                        loginError: "ERROR DE SERVIDOR",
                    })
                }
            })
            
        }
    }

    validateField(){
        return this.validateFirstName()
            && this.validateLastName()
            && this.validateCountry('country', 'country_error')
            && this.validatePhoneNumber()
            && this.validateEmail()
            && this.validatePassword()
            && this.validatePasswordMatch();
    }

    validateFirstName(){
        let field = this.state.firstName;
        if(field === ''){
            this.setState({firstNameError: "El nombre no puede estar vacío"})
            return false;
        }else{
            this.setState({firstNameError: ""})
            return true;
        }
    }

    validateLastName(){
        let field = this.state.lastName;
        if(field === ''){
            this.setState({lastNameError: "El apellido no puede estar vacío"})
            return false;
        }else{
            this.setState({lastNameError: ""})
            return true;
        }
    }

    validateCountry(field, errorMessage){
        let value = this.state[field];
        if(value === '' || value === null || value === undefined){
            this.setState({[errorMessage] : "Debes seleccionar el país."})
            return false;
        }else if(value.length > 64 ){
            this.setState({[errorMessage] : "Este campo excede la longitud máxima de 64 caracteres."})
            return false;
        }else{
            this.setState({[errorMessage] : ""})
            return true;
        }
    }

    validatePhoneNumber(){
        let field = this.state.phoneNumber;
        if(field === ''){
            this.setState({phoneNumberError: "El número de teléfono no puede estar vacío"})
            return false;
        }else{
            this.setState({phoneNumberError: ""})
            return true;
        }
    }

    validateEmail(){
        let field = this.state.email;
        if(field === ''){
            this.setState({emailError: "El correo no puede estar vacío"})
            return false;
        }else{
            this.setState({emailError: ""})
            return true;
        }
    }

    validatePassword(){
        let field = this.state.password;
        if(field === ''){
            this.setState({passwordError: "La contraseña no puede estar vacía"})
            return false;
        }else{
            this.setState({passwordError: ""})
            return true;
        }
    }

    validatePasswordMatch(){
        let field = this.state.password;
        let compare_field = this.state.passwordConfirm;
        if(field !== compare_field){
            this.setState({passwordConfirmError: "Las contraseñas no coinciden"})
            return false;
        }else{
            this.setState({passwordConfirmError: ""})
            return true;
        }
    }

    handleChange(event){
        this.setState({[event.target.name] : event.target.value})
    }

    countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }

    autocompleteChange(event, newValue, field){
        this.setState({ [field] : newValue });
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.redirectToHome()}
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} className={classes.logoContainer}>
                                <img src={Logo} alt="logo" className={classes.logo}/>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="Nombres"
                                    value={this.state.firstName}
                                    onChange={this.handleChange.bind(this)}
                                />
                                { this.state.firstNameError !== '' && <span className={classes.error}>{this.state.firstNameError}</span> }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Apellidos"
                                    name="lastName"
                                    autoComplete="lname"
                                    value={this.state.lastName}
                                    onChange={this.handleChange.bind(this)}
                                />
                                { this.state.lastNameError !== '' && <span className={classes.error}>{this.state.lastNameError}</span> }
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    id="country-select-demo-delivery-country"
                                    style={{ width: '100%' }}
                                    options={this.state.all_countries}
                                    onChange={(event, newValue) => this.autocompleteChange(event, newValue, 'country')}
                                    value={this.state.country}
                                    className={classes.select}
                                    classes={{
                                        option: classes.option,
                                    }}
                                    autoHighlight
                                    getOptionLabel={option => option.label}
                                    renderOption={option => (
                                        <React.Fragment>
                                            <span>{this.countryToFlag(option.code)}</span>
                                            {option.label} ({option.code}) +{option.phone}
                                        </React.Fragment>
                                    )}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Pais"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                                {
                                    this.state.country_error !== '' &&
                                    <span className={classes.error}>{this.state.country_error}</span>
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="phoneNumber"
                                    name="phoneNumber"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    type='number'
                                    id="phoneNumber"
                                    label="Número de teléfono"
                                    value={this.state.phoneNumber}
                                    onChange={this.handleChange.bind(this)}
                                    InputProps={{
                                        startAdornment: this.state.country !== '' && this.state.country !== null && this.state.country !== undefined ? <InputAdornment position="start">{"+" + this.state.country.phone}</InputAdornment> : null,
                                    }}
                                />
                                { this.state.phoneNumberError !== '' && <span className={classes.error}>{this.state.phoneNumberError}</span> }
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo"
                                    name="email"
                                    autoComplete="email"
                                    value={this.state.email}
                                    onChange={this.handleChange.bind(this)}
                                />
                                { this.state.emailError !== '' && <span className={classes.error}>{this.state.emailError}</span> }
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    value={this.state.password}
                                    onChange={this.handleChange.bind(this)}
                                    InputProps={{
                                        'aria-label': 'bare',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword.bind(this)}
                                                    onMouseDown={this.handleMouseDownPassword.bind(this)}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                { this.state.passwordError !== '' && <span className={classes.error}>{this.state.passwordError}</span> }
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="passwordConfirm"
                                    label="Confirmar Contraseña"
                                    type={this.state.showPasswordConfirm ? 'text' : 'password'}
                                    id="passwordConfirm"
                                    autoComplete="current-password"
                                    value={this.state.passwordConfirm}
                                    onChange={this.handleChange.bind(this)}
                                    InputProps={{
                                        'aria-label': 'bare',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPasswordConfirm.bind(this)}
                                                    onMouseDown={this.handleMouseDownPasswordConfirm.bind(this)}
                                                >
                                                    {this.state.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                { this.state.passwordConfirmError !== '' && <span className={classes.error}>{this.state.passwordConfirmError}</span> }
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <br/>
                                </Grid>
                                { this.state.signUpError !== '' && <span className={classes.error}>{this.state.signUpError}</span> }
                                { this.state.signUpSuccess !== '' && <span className={classes.success}>{this.state.signUpSuccess}</span> }
                                <Grid item>
                                    <br/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => this.doSignUp()}
                                    disabled={this.state.doingSignUp}
                                >
                                    {this.state.doingSignUp ? <CircularProgress className={classes.progress} color="secondary" /> : 'REGISTRARSE'}
                                </Button>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <br/>
                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <Link to="/login" className={classes.link}>
                                        Ya tienes una cuenta en <strong>TMP</strong> ? Inicia sesión.
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>

                    </div>
                </Container>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(SignUpComponent);