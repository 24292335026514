import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { API_URL, CREATE_COMMENT_REPONSE } from "../../API";
import { message_responses } from "../../MESSAGE_RESPONSES";
import dashFormat from "../Utils/dates";

const useStyles = makeStyles({
  card: {
    display: "flex",
    backgroundColor: "#f5f5f5",
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: "160px",
  },
  cardButton: {
    margin: "5px",
  },
  cardChip: {
    margin: "5px",
  },
  progress: {
    color: "#ff9b01",
  },
  cardContainer: {
    margin: "20px 0px",
  },
  paddingContainer: {
    padding: "10px",
  },
  paddingGrid: {
    padding: "0px 5px;",
  },
  root: {
    backgroundColor: "#ffa726",
  },
  rot: {
    backgroundColor: "#bdbdbd",
    textAling: "left",
  },
});

const PostCommentComponent = ({ comment, getComments, response }) => {
  const classes = useStyles();

  const userData = JSON.parse(localStorage.getItem("TMPData"));
  const [commentResponse, setCommentResponse] = useState("");
  const [loadingItems, setLoadingItems] = useState(false);

  useEffect(() => {
    emptyCommentBox();
  }, []);

  const handleReponseTextChange = (event) => {
    setCommentResponse(event.target.value);
  };

  const handleSendReponse = (event) => {
    event.preventDefault();
    createCommentReponse();
  };

  const emptyCommentBox = () => {
    setCommentResponse("");
  };

  const createCommentReponse = () => {
    setLoadingItems(true);

    const formData = {
      comment_id: comment.id,
      machine_id: comment.machineId,
      response: commentResponse,
    };

    axios
      .post(API_URL + CREATE_COMMENT_REPONSE, formData)
      .then((response) => {
        setLoadingItems(false);
        if (response.data.success) {
          emptyCommentBox();
          getComments();
        } else {
          alert(message_responses[response.data.message]);
        }
      })
      .catch((error) => {
        setLoadingItems(false);
        if (error.response && error.response.data) {
          alert(
            error.response.data.message === "VALIDATOR_ERROR"
              ? error.response.data.data.error_message
              : message_responses[error.response.data.message]
          );
        } else {
          alert(
            "Por favor discúlpanos :( , hemos tenido un error al enviar la respuesta al comentario de la máquina"
          );
        }
      });
  };
  return (
    <>
      <Grid
        item
        xs={12}
        md={9}
        className={classes.cardContainer}
        direction="column"
      >
        <Card className={classes.card}>
          <div className={classes.cardDetails}>
            <CardContent className={classes.root}>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography gutterBottom variant="subtitle1">
                      {comment.userComment}
                    </Typography>
                    <Typography variant="body2">
                      <div>{comment.comment}</div>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Publicado: {dashFormat(comment.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        </Card>
      </Grid>
      {response !== null ? (
        response.comment_id === comment.id ? (
          <Grid container>
            <Grid xs={3} md={2}></Grid>
            <Grid item xs={9} md={7} className={classes.paddingGrid}>
              <Card className={classes.card}>
                <div className={classes.cardDetails}>
                  <CardContent className={classes.rot}>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography gutterBottom variant="subtitle1">
                            {response.user_response}
                          </Typography>
                          <Typography variant="body2">
                            <div>{response.response}</div>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">
                          Publicado: {dashFormat(response.created_at)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )
      ) : userData !== null ? (
        userData.user.id === comment.createdBy ? (
          <Grid className={classes.paddingContainer} container>
            <Grid item xs={12} md={9} className={classes.paddingGrid}>
              <TextField
                multiline={true}
                rows={1}
                variant="outlined"
                id="comment-basic"
                label="Responder"
                fullWidth
                value={commentResponse}
                onChange={handleReponseTextChange}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSendReponse}
                fullWidth
                disabled={loadingItems}
              >
                {loadingItems ? (
                  <CircularProgress
                    className={classes.progress}
                    color="primary"
                  />
                ) : (
                  "Responder"
                )}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )
      ) : (
        <div></div>
      )}
    </>
  );
};

export default PostCommentComponent;
