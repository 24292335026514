import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PublicIcon from '@material-ui/icons/Public';
import md5 from 'md5';

const useStyles = makeStyles({
    submitButton: {
        color: '#000000',
        backgroundColor: '#ff9b01',
        width: '100%',
        minHeight: '50px',
        padding: '6px 16px',
        fontSize: '0.875rem',
        minWidth: '64px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        border: 0,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        '&:hover': {
            backgroundColor: 'rgb(178, 108, 0)',
            cursor: 'pointer',
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&:focus': {
            outline: 'unset',
        },
    },
    submitButtonDisabled: {
        color: '#fff',
        backgroundColor: '#5a5a5a',
        width: '100%',
        minHeight: '50px',
        padding: '6px 16px',
        fontSize: '0.875rem',
        minWidth: '64px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        border: 0,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        '&:hover': {
            cursor: 'not-allowed',
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        '&:focus': {
            outline: 'unset',
        },
    },
    payUSubmitForm: {
        width: '100%',
    },
    payUSubmitFormContainer: {
        padding: '20px',
    },
});

const PayUPaymentFormComponent = ({
    packageName,
    packageId,
    user,
    machineId,
    amount,
    disabled,
}) => {
    
    const classes = useStyles();

    const PAYU_API_KEY = process.env.REACT_APP_PAYU_ON_TESTING ? "4Vj8eK4rloUd272L48hsrarnUA" : process.env.REACT_APP_PAYU_API_KEY;
    const PAYU_MERCHANT_ID = process.env.REACT_APP_PAYU_ON_TESTING ? "508029" : process.env.REACT_APP_PAYU_MERCHANT_ID;
    const PAYU_ACCOUNT_ID = process.env.REACT_APP_PAYU_ON_TESTING ? "512321" : process.env.REACT_APP_PAYU_ACCOUNT_ID;

    const initialTaxReturnBase = (amount * 100)/119;
    const tax = Math.floor(amount - initialTaxReturnBase);
    const taxReturnBase = Math.floor(initialTaxReturnBase);
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const transactionDate = date+'_'+time;
    const referenceCode = `TMP_${user.id}_${transactionDate}`;
    const signature = `${PAYU_API_KEY}~${PAYU_MERCHANT_ID}~${referenceCode}~${amount}~COP`;
    const encryptedSignature = md5(signature);

    let shippingAddress = "";
    let shippingCity = "";
    let shippingCountry = "";

    const extra1 = JSON.stringify({
        packageName,
        packageId,
        userId: user.id,
        ...(machineId && { machineId }),
    });

    return(
        <div className={classes.payUSubmitFormContainer}>
            <form 
                method="post" 
                action={
                    process.env.REACT_APP_PAYU_ON_TESTING ? 
                    "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/" 
                    : 
                    "https://checkout.payulatam.com/ppp-web-gateway-payu"
                } 
                className={classes.payUSubmitForm}
            >
                {       
                    process.env.REACT_APP_PAYU_ON_TESTING &&  
                    <input name="test"      type="hidden"  value="1"/>
                }
                <input name="merchantId"        type="hidden"  value={PAYU_MERCHANT_ID}/>
                <input name="accountId"         type="hidden"  value={PAYU_ACCOUNT_ID}/>
                <input name="description"       type="hidden"  value={packageName}/>
                <input name="referenceCode"     type="hidden"  value={referenceCode}/>
                <input name="amount"            type="hidden"  value={amount}/>
                <input name="tax"               type="hidden"  value={tax}/>
                <input name="taxReturnBase"     type="hidden"  value={taxReturnBase}/>
                <input name="currency"          type="hidden"  value="COP"/>
                <input name="signature"         type="hidden"  value={encryptedSignature}/>
                <input name="buyerFullName"     type="hidden"  value={`${user.name} ${user.last_name}`}/>
                <input name="buyerEmail"        type="hidden"  value={user.email}/>

                <input name="shippingAddress"   type="hidden"  value={shippingAddress}/>
                <input name="shippingCity"      type="hidden"  value={shippingCity}/>
                <input name="shippingCountry"   type="hidden"  value={shippingCountry}/>

                <input name="telephone"         type="hidden"  value={user.phone_number}/>
                <input name="extra1"            type="hidden"  value={extra1}/>
                <input name="responseUrl"       type="hidden"  value={`${process.env.REACT_APP_URL}ventas/detalles-maquina?maquinaId=${machineId}`}/>
                <input name="confirmationUrl"   type="hidden"  value={`${process.env.REACT_APP_API_ENDPOINT}payu-confirmation`}/>
                <button name="Submit"           type="submit"  className={disabled ? classes.submitButtonDisabled : classes.submitButton} disabled={disabled}>PAGA CON PAYU&nbsp;<PublicIcon/></button>
            </form>
        </div>
        
    )
}

export default PayUPaymentFormComponent;