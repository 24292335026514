import React, { Component, Fragment } from 'react';
import AdminHeader from '../../components/AdminHeader/AdminHeader';
import AdminDashboardComponent from "../../components/AdminDashboardComponent/AdminDashboard";

class AdminDashboard extends Component{
    render(){
        return(
            <Fragment>
                <AdminHeader/>
                <AdminDashboardComponent/>
            </Fragment>
        );
    }
}

export default AdminDashboard;