import { useEffect, useState } from 'react';
import { GET_OUTSTANDING_MACHINES } from '../../API';
import axios from 'axios';

const OutstandingMachinesController = () => {

    const [ paginationData, setPaginationData ] = useState(null);
    const [ machinesData, setMachinesData ] = useState([]);
    const [ loadingMachineSearching, setLoadingMachineSearching ] = useState(false);

    useEffect(() => {
        getOutstandingMachines();
    }, [])

    useEffect(() => {
        console.log(machinesData);
        console.log(paginationData);
    }, [machinesData, paginationData])

    const getOutstandingMachines = () => {
        setLoadingMachineSearching(true)

        axios.get(GET_OUTSTANDING_MACHINES).then((response) => {
            setLoadingMachineSearching(false)
            if(response.data.success && response.data.data !== null){
                setPaginationData(response.data.data)
                setMachinesData(response.data.data.data)
            }else{
                setPaginationData(null)
                setMachinesData([])
            }   
        }).catch((error) => {
            setLoadingMachineSearching(false)
            setPaginationData(null)
            setMachinesData([])
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error buscando maquinaria.")
            }
        })
    }

    const onLoadMore = () => {
        if(!paginationData || !paginationData.next_page_url) return
        setLoadingMachineSearching(true)
        axios.get(paginationData.next_page_url).then((response) => {
            setLoadingMachineSearching(false)
            if(response.data.success && response.data.data !== null){
                setPaginationData(response.data.data)
                setMachinesData([...machinesData, ...response.data.data.data])
                /*if(Array.isArray(response.data.data.data) && response.data.data.data.length === 0){
                    throwAlert()
                }*/
            }else{
                setPaginationData(null)
                setMachinesData([])
            }   
        }).catch((error) => {
            setLoadingMachineSearching(false)
            setPaginationData(null)
            setMachinesData([])
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error buscando maquinaria.")
            }
        })
    }

    return {
        loading: loadingMachineSearching,
        machinesData,
        onLoadMore,
        paginationData,
    }
}

export default OutstandingMachinesController;