import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import AssignmentSharpIcon from '@material-ui/icons/AssignmentSharp';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import FavoriteSharpIcon from '@material-ui/icons/FavoriteSharp';
import UnarchiveSharpIcon from '@material-ui/icons/UnarchiveSharp';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import MyPostsComponent from "../Dashboard/MyPostsComponent";
import MyMetricsComponent from "../Dashboard/Statistics/MyMetricsComponent";
import MyProfile from "../Dashboard/MyProfile/MyProfile";

import {Redirect} from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    position: 'absolute',
    top: 'unset',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerContainer: {
    position: 'absolute',
    top: 'unset',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuSelected: {
    backgroundColor: '#ff9b01',
  }
}));

const DashboardComponent = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [currentComponent, setCurrentComponent] = useState('profile');
  
  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = () => {
      if(!localStorage.getItem('TMPData')){
        setRedirectToHome(true);
      }
  }

  const doRedirectToHome = () => {
      if (redirectToHome) {
          return <Redirect push to='/' />
      }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
      <>
        <div className={classes.root}>
            {doRedirectToHome()}
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                    })}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap>
                    Panel de administración
                </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
                })}
                classes={{
                paper: clsx(classes.drawerContainer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
                }}
            >
                <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
                </div>
                <List className={currentComponent === 'profile' && classes.menuSelected  }>
                    <ListItem button key={'Mi Perfil'} onClick={() => setCurrentComponent('profile')} >
                        <ListItemIcon><AccountCircleSharpIcon /></ListItemIcon>
                        <ListItemText primary={'Mi Perfil'} />
                    </ListItem>
                </List>
                <List className={currentComponent === 'myPosts' && classes.menuSelected  }>
                    <ListItem button key={'Mis publicaciones'} onClick={() => setCurrentComponent('myPosts')} >
                        <ListItemIcon><AssignmentSharpIcon /></ListItemIcon>
                        <ListItemText primary={'Mis publicaciones'} />
                    </ListItem>
                </List>
                <List className={currentComponent === 'myMetrics' && classes.menuSelected  }>
                    <ListItem button key={'Mis métricas'} onClick={() => setCurrentComponent('myMetrics')} >
                        <ListItemIcon><AssessmentIcon /></ListItemIcon>
                        <ListItemText primary={'Mis métricas'} />
                    </ListItem>
                </List>
                <List className={currentComponent === 'Mi Lista de Deseos' && classes.menuSelected  }>
                    <ListItem button key={'Mi Lista de Deseos'} onClick={() => null /*setCurrentComponent('Mi Perfil')*/} >
                        <ListItemIcon><FavoriteSharpIcon /></ListItemIcon>
                        <ListItemText primary={'Mi Lista de Deseos'} />
                    </ListItem>
                </List>

                <List>
                    <ListItem button key={'Mi Historial de Publicaciones'} onClick={() => null /*setCurrentComponent('Mi Perfil')*/} >
                        <ListItemIcon><UnarchiveSharpIcon /></ListItemIcon>
                        <ListItemText primary={'Mi Historial de Publicaciones'} />
                    </ListItem>
                </List>
                <List>
                    <ListItem button key={'Ajustes'} onClick={() => null /*setCurrentComponent('Mi Perfil')*/} >
                        <ListItemIcon><SettingsSharpIcon /></ListItemIcon>
                        <ListItemText primary={'Ajustes'} />
                    </ListItem>
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />

                { currentComponent === 'profile' && <MyProfile/> }
                { currentComponent === 'myPosts' && <MyPostsComponent/> }
                { currentComponent === 'myMetrics' && <MyMetricsComponent/> }

            </main>
        </div>
      </>
    
  );
}

export default DashboardComponent;