import React from 'react';
import './TMPSimpleCard.css';

const TMPSimpleCard = props => {

    const {
        title,
        value,
    } = props;
   
    return (
        <div className='TMPSimpleCard-root'>
            <div className='TMPSimpleCard-title'>{title}</div>
            <div className='TMPSimpleCard-value'>{value}</div>
        </div>
    )

}

export default TMPSimpleCard;