import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SellingCategoriesComponent from '../../components/SellingCategoriesComponent/SellingCategoriesComponent';
import OurServicesComponent from '../../components/OurServicesComponent/OurServicesComponent';
import OutstandingMachines from '../../components/OutstandingMachinesComponent/OutstandingMachines';
import TopSearchedFor from '../../components/TopSearchedFor/TopSearchedFor';

import HomeFooter from '../HomeFooterComponent/HomeFooter';
import TitleLabel from '../../components/primitive/TitleLabel/TitleLabel';

import Slider1 from '../../images/slider1.jpg';
import Slider2 from '../../images/slider2.jpg';
import Slider3 from '../../images/slider3.jpg';
import Slider4 from '../../images/slider4.jpg';

class HomeComponent extends React.Component{
    render(){

        const settings = {
            dots: true,
            infinite: true,
            //speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };

        return (
            <React.Fragment>

                <div className='home-carousel-card-container'>
                    
                    <Slider {...settings}>
                        <div>
                            <img src={Slider1} alt='slide-1'/>
                        </div>
                        <div>
                            <img
                                src={Slider2} alt='slide-2'/>
                        </div>
                        <div>
                            <img src={Slider3} alt='slide-3'/>
                        </div>
                        <div>
                            <img
                                src={Slider4} alt='slide-4'/>
                        </div>

                    </Slider>
                </div>

                <TitleLabel text='Los más buscados en' variant='dark' />

                <TopSearchedFor/>

                <OutstandingMachines/>

                <TitleLabel text='Compra / Venta' variant='dark' />

                <SellingCategoriesComponent/>

                <TitleLabel text='Nuestros Servicios' variant='dark' />

                <OurServicesComponent/>

                <TitleLabel text='Todas Las Marcas' variant='dark' />

                <HomeFooter/>
            
            </React.Fragment>
        );
    }
}

export default HomeComponent;