import React from 'react';
import Header from '../../components/Header/Header';
import EditMachineComponent from "../../components/EditMachineComponent/EditMachineComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import queryString from 'query-string';

const EditMachine = (props) => {
    return(
        <>
            <Header/>
            <EditMachineComponent url_id={queryString.parse(props.location.search)}/>
            <FooterComponent/>
        </>
    )
}

export default EditMachine