import React, { Component, Fragment } from 'react';
import AdminHeader from '../../components/AdminHeader/AdminHeader';
import AdminLoginComponent from "../../components/AdminLoginComponent/AdminLogin";
import { getUrlParams } from "../../utils/utils";

class AdminLogin extends Component{
    render(){
        return(
            <Fragment>
                <AdminHeader/>
                <AdminLoginComponent urlParams={getUrlParams(this.props.location.search)}/>
            </Fragment>
        );
    }
}

export default AdminLogin;