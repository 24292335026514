import React, { useEffect, useState } from 'react';

const MyProfileController = () => {

    const [ user, setUser ] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("TMPData"));
        if(!user) return
        setUser(user.user)
    }, [])

    return { user }
}

export default MyProfileController;