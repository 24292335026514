import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  API_URL,
  GET_MACHINES_COMMENTS,
  CREATE_MACHINE_COMMENT,
  GET_REPONSE_BY_MACHINE,
} from "../../API";
import { message_responses } from "../../MESSAGE_RESPONSES";
import dashFormat from "../Utils";
import PostCommentComponent from "../PostCommentComponent/PostCommentComponent";

const useStyles = makeStyles({
  paddingContainer: {
    padding: "10px 0 30px 0",
  },
  paddingGrid: {
    padding: "0px 5px;",
  },
  progress: {
    color: "#ff9b01",
  },
});

const CommentComponent = ({ machine, location }) => {
  const userData = JSON.parse(localStorage.getItem("TMPData"));

  const classes = useStyles();

  const [loadingItems, setLoadingItems] = useState(false);
  const [items, setItems] = useState([]);
  const [responses, setResponse] = useState([]);
  const [comment, setComment] = useState("");

  useEffect(() => {
    getComments();
  }, []);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createComment();
  };

  const emptyCommentBox = () => {
    setComment("");
    setItems([]);
  };

  const getComments = () => {
    setLoadingItems(true);

    axios
      .get(API_URL + GET_MACHINES_COMMENTS + machine.machineId)
      .then((response) => {
        setLoadingItems(false);
        if (response.data.success) {
          setItems(response.data.data.reverse());
        } else {
          alert(message_responses[response.data.message]);
        }
      })
      .catch((error) => {
        setLoadingItems(false);
        if (error.response && error.response.data) {
          alert(
            error.response.data.message === "VALIDATOR_ERROR"
              ? error.response.data.data.error_message
              : message_responses[error.response.data.message]
          );
        } else {
          alert(
            "Por favor discúlpanos :( , hemos tenido un error al cargar los comentarios de la máquina"
          );
        }
      });
  };

  const createComment = () => {
    setLoadingItems(true);
    const formData = {
      user_id: userData.user.id,
      machine_id: machine.machineId,
      comment,
    };
    axios
      .post(API_URL + CREATE_MACHINE_COMMENT, formData)
      .then((response) => {
        setLoadingItems(false);
        if (response.data.success) {
          emptyCommentBox();
          getComments();
        } else {
          alert(message_responses[response.data.message]);
        }
      })
      .catch((error) => {
        setLoadingItems(false);
        if (error.response && error.response.data) {
          alert(
            error.response.data.message === "VALIDATOR_ERROR"
              ? error.response.data.data.error_message
              : message_responses[error.response.data.message]
          );
        } else {
          alert(
            "Por favor discúlpanos :( , hemos tenido un error al cargar los comentarios de la máquina"
          );
        }
      });
  };

  return (
    <>
      <Grid item xs={12}>
        {userData !== null ? (
          userData.user.id !== machine.createdBy ? (
            <Grid>
              <Typography variant="body2" component="div">
                <div>
                  <strong>Pregúntale al Vendedor</strong>
                </div>
              </Typography>

              <Grid className={classes.paddingContainer} container>
                <Grid item xs={12} md={9} className={classes.paddingGrid}>
                  <TextField
                    multiline={true}
                    rows={3}
                    variant="outlined"
                    id="comment-basic"
                    label="Comentario"
                    value={comment}
                    onChange={handleCommentChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                    disabled={loadingItems}
                  >
                    {loadingItems ? (
                      <CircularProgress
                        className={classes.progress}
                        color="primary"
                      />
                    ) : (
                      "Preguntar"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div></div>
          )
        ) : (
          <Grid>
            <Grid>
              <Typography variant="body2" c omponent="div">
                <div>
                  <strong>Para preguntar al vendedor, por favor debes </strong>
                  <Link
                    to={
                      "/login?redirect=" + location.pathname + location.search
                    }
                  >
                    <strong>Iniciar Sesión</strong>
                  </Link>
                </div>
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid>
          <Typography variant="body2" component="div">
            <div>
              <strong>Últimas preguntas.</strong>
            </div>
          </Typography>

          {items.map((item) => (
            <PostCommentComponent
              comment={{
                createdBy: machine.createdBy,
                machineId: machine.machineId,
                id: item.id,
                createdAt: item.created_at,
                comment: item.comment,
                userComment: item.user_comment,
                answered: item.answered,
              }}
              getComments={getComments}
              response={item.responses}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CommentComponent;
