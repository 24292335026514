import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    galleryItemImg: {
        height: 250,
        overflow: 'hidden',
        display: 'block',
        width: '100%',
    },
    singleItemImg: {
        height: 350,
        overflow: 'hidden',
        display: 'block',
        width: '100%',
    },
    singleItemImgSmall: {
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        maxHeight: 120,
    },
    mobileStepper:{
        marginTop: '-48px',
    },
    dotActive:{
        backgroundColor: '#ff9b01',
    },
    sellRentGalleryItemSold:{
        position: 'absolute',
        opacity: '0.8',
        fontSize: '4em',
        color: '#ff9b01',
        width: '100%',
        textAlign: 'center',
        zIndex: '1000',
        backgroundColor: '#000000'
    },
    sellRentGalleryItemSoldSmall:{
        position: 'absolute',
        opacity: '0.8',
        fontSize: '2em',
        color: '#ff9b01',
        width: '100%',
        textAlign: 'center',
        zIndex: '1000',
        backgroundColor: '#000000'
    },
    sellRentGalleryItemContainer:{
        position: 'relative',
    }
});

class SellRentGalleryItemImages extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeStep: 0,
        }
    }

    handleNext = () => {
        if(!(this.state.activeStep === this.props.data.length - 1)){
            this.setState(prevState => ({
                activeStep: prevState.activeStep + 1,
            }));
        }
    };

    handleBack = () => {
        if(!(this.state.activeStep === 0)){
            this.setState(prevState => ({
                activeStep: prevState.activeStep - 1,
            }));
        }
    };

    render() {
        const { classes, theme, variant } = this.props;
        const { activeStep } = this.state;
        //const maxSteps = this.props.data.length;
        //console.log("..."+ this.state.product_images[0].imgPath);
        return (
            <React.Fragment>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.sellRentGalleryItemContainer}
                >
                    {
                        this.props.status === 'sold' && <div className={variant === 'normal' ? classes.sellRentGalleryItemSold : classes.sellRentGalleryItemSoldSmall}>VENDIDA</div>
                    }
                    
                    <Grid item xs={12}>
                        <div className='sellRentGalleryItemFullWidth'>

                            {
                                this.props.single ?
                                    <div className={classes.root}>
                                        <img
                                            className={variant === 'normal' ? classes.singleItemImg : classes.singleItemImgSmall }
                                            src={this.props.data !== undefined && this.props.data[activeStep] !== undefined ? this.props.data[activeStep].path : ''}
                                            alt='machine'
                                        />
                                    </div>
                                    :
                                    <Link to={"/ventas/detalles-maquina?maquinaId="+this.props.id}>
                                        <div className={classes.root}>
                                            <img
                                                className={ classes.galleryItemImg }
                                                src={this.props.data !== undefined && this.props.data[activeStep] !== undefined ? this.props.data[activeStep].path : ''}
                                                alt='machine'
                                            />
                                        </div>
                                    </Link>
                            }

                            <MobileStepper
                                variant="dots"
                                //steps={maxSteps}
                                steps={0}
                                position="static"
                                activeStep={activeStep}
                                className={classes.mobileStepper}
                                classes={{
                                    dotActive: classes.dotActive
                                }}
                                nextButton={
                                    <Button size="small" onClick={this.handleNext}>
                                        {theme.direction === 'rtl' ? <KeyboardArrowLeft className='sell-rent-galley-img-btn'/> : <KeyboardArrowRight className='sell-rent-galley-img-btn'/>}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={this.handleBack}>
                                        {theme.direction === 'rtl' ? <KeyboardArrowRight className='sell-rent-galley-img-btn'/> : <KeyboardArrowLeft className='sell-rent-galley-img-btn'/>}
                                    </Button>
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}


export default withStyles(styles, { withTheme: true })(SellRentGalleryItemImages);
