import React, { Component, Fragment } from 'react';
import './Countries.css';
import CountriesComponent from "../../components/CountriesComponent/CountriesComponent";


class Countries extends Component{
    render(){
        return(
            <Fragment>
                <CountriesComponent/>
            </Fragment>
        );
    }
}

export default Countries;