import React from 'react';
import './OurServicesComponent.css';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import PeritajeImage from '../../images/peritaje.jpg';
import VentasImage from '../../images/venta.jpg';
import PartesImage from '../../images/partes.jpg';
import MantenimientoImage from '../../images/mantenimiento.jpg';
import {Link} from "react-router-dom";

const services = [
    {
        link: '/peritaje',
        image: PeritajeImage,
        title: '¿Necesitas comprar o vender Maquinaria pesada?',
        text: `realizamos Inspección, evaluación
                técnica de componentes mecánicos, hidráulicos, eléctricos, electrónicos y estructurales,
                para valorar funcional y económicamente el estado de una máquina.`,
    },
    {
        link: '/ventas',
        image: VentasImage,
        title: 'Compra, venta y alquiler de maquinaria de segunda Mano: TMP',
        text: `Somos el punto de encuentro en
                línea para vendedores, compradores, arrendadores y arrendatarios de maquinaria pesada,
                liviana, agrícola, equipos industriales y de manejo de cargas. Al contar con nuestro sello de
                calidad en el Peritaje, el comprador de maquinaria cuenta con plena seguridad del
                estado de la máquina que está adquiriendo.`,
    },
    {
        link: '/partes',
        image: PartesImage,
        title: 'Partes:',
        text: `Somos exportadores y comercializadores de partes para todo tipo de maquinaria
                pesada y plantas eléctricas desde la ciudad de Miami (Estados Unidos) para toda
                Latinoamérica y el Caribe.`,
    },
    {
        link: '/mantenimiento',
        image: MantenimientoImage,
        title: 'Programas de mantenimiento preventivo y correctivo:',
        text: `Mantenimiento hidráulico,
                eléctrico, electrónico y mecánico de maquinaria pesada e industrial, contamos con un
                software propio de mantenimiento especializado.`,
    },
]


const SingleService = ({ service }) => {
    return (
        <Card className='home-card-full-height'>
            <Link to={service.link} className='services-card-link'>
            <CardActionArea>
                <CardMedia
                    className='home-card-media'
                    image={service.image}
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p" className='services-text'>
                        <strong>{service.title}</strong>
                        {service.text}
                    </Typography>
                </CardContent>
            </CardActionArea>
            </Link>
            <CardActions className='services-show-more-container'>
                <Link to={service.link}>
                    <Button size="small" color="primary" className='services-show-more'>
                        Ver Mas
                    </Button>
                </Link>
            </CardActions>
        </Card>
    )
}

const OurServicesComponent = () => {
    return (
        <Grid container spacing={0}>
            {
                services.map((service) => (
                    <Grid item xs={12} sm={6} md={3} className='home-card'>
                        <SingleService service={service} />
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default OurServicesComponent;