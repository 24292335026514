import React, { Component, Fragment } from 'react';
import './Cotizaciones.css';
import Header from '../../components/Header/Header';
import CotizacionesList from '../../components/CotizacionesList/CotizacionesList';

class Cotizaciones extends Component{
    render(){
        return(
            <Fragment>
                <Header/>
                <CotizacionesList/>
            </Fragment>
        );
    }
}

export default Cotizaciones;