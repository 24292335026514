import React, { Component } from "react";
import "./Header.css";
import { AppBar, Toolbar, Button } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Logo from "../../images/logo.png";
import Facebook from "../../images/facebook.svg";
import Instagram from "../../images/instagram.svg";
import LinkedIn from "../../images/linkedin.svg";
import YouTube from "../../images/youtube.svg";
import Whatsapp from "../../images/whatsapp.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@material-ui/core/Popover";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SearchMachine from "../SearchMachineComponent/SearchMachine";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: false,
      authUser: null,
      anchorEl: null,
      redirectToDashboard: false,
    };
  }

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth() {
    let user = JSON.parse(localStorage.getItem("TMPData"));
    if (user) {
      this.setState({ isAuth: true, authUser: user.user });
    } else {
      this.setState({ isAuth: false, authUser: null });
    }
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  closeSession() {
    localStorage.clear();
    window.location.reload();
  }

  doRedirectToDashboard() {
    this.setState({ redirectToDashboard: true });
  }

  redirectToDashboard = () => {
    if (this.state.redirectToDashboard) {
      return <Redirect push to="/dashboard" />;
    }
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <AppBar position="sticky" className="mainHeader">
        {this.redirectToDashboard()}
        <Grid
          container
          spacing={0}
          justify="space-between"
          className="header-header"
        >
          <div className="header-header-left-panel">
            <div className="header-header-left-panel-item">
              <Link to={"/acerca-de-nosotros"} className="grow">
                <Button color="inherit" className="grow-button-head">
                  ACERCA DE NOSOTROS
                </Button>
              </Link>
              <Link to={"/contactanos"} className="grow">
                <Button color="inherit" className="grow-button-head">
                  CONTACTENOS
                </Button>
              </Link>
            </div>
            <div className="header-header-left-panel-item">
              <Link
                to={this.state.isAuth ? "/publicar" : "/login"}
                className="grow"
              >
                <Button color="inherit" className="grow-button-head-yellow">
                  PUBLICA TU MÁQUINA
                </Button>
              </Link>
              {this.state.isAuth ? (
                <>
                  <Button
                    aria-describedby={id}
                    onClick={(e) => this.handleClick(e)}
                    color="inherit"
                    className="grow-button-head"
                  >
                    {this.state.authUser.name}&nbsp;
                    <ArrowDropDownIcon />
                    &nbsp;
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.handleClose()}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div className="user-top-main-menu">
                      <div
                        className="user-top-main-menu-item"
                        onClick={() => this.doRedirectToDashboard()}
                      >
                        <DashboardIcon />
                        <span>MI PANEL</span>
                      </div>
                      <div
                        className="user-top-main-menu-item"
                        onClick={() => this.closeSession()}
                      >
                        <AccountCircleIcon />
                        <span>CERRAR SESIÓN</span>
                      </div>
                    </div>
                  </Popover>
                </>
              ) : (
                <Link to={"/login"} className="grow">
                  <Button color="inherit" className="grow-button-head">
                    INICIAR SESIÓN
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </Grid>
        <Grid container spacing={0} className="header-middle">

          <Grid item xs={12} md={4} className="header-middle-left-panel">
            <img src={Logo} className="header-logo" alt="header-logo" />
          </Grid>

          <Grid item xs={12} md={4} className="header-middle-center-panel">
            <SearchMachine/>
          </Grid>

          <Grid item xs={12} md ={4} className="header-middle-right-panel">
            <a
              href="https://www.facebook.com/TMPtodoenmaquinariapesada/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Facebook}
                className="header-social-icon"
                alt="header-social-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/tmptodoenmaquinariapesada/?hl=es-la"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Instagram}
                className="header-social-icon"
                alt="header-social-icon"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/tmp-todoenmaquinariapesada/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={LinkedIn}
                className="header-social-icon"
                alt="header-social-icon"
              />
            </a>
            <a href="https://www.youtube.com/@tmptodoenmaquinariapesada8969" target="_blank" rel="noopener noreferrer">
              <img
                src={YouTube}
                className="header-social-icon"
                alt="header-social-icon"
                rel="noopener noreferrer"
              />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=573138052400"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Whatsapp}
                className="header-social-icon"
                alt="header-social-icon"
              />
            </a>
          </Grid>
        </Grid>
        <Toolbar className="main-toolbar-header">
          {/*
                        <Link to={'/'}>
                        <IconButton color="inherit" aria-label="Menu">
                            <FontAwesomeIcon icon={faCogs} className='header-home-icon'/>
                        </IconButton>
                    </Link>
                        * */}

          <Link to={"/"} className="grow">
            <Button color="inherit" className="grow-button">
              Inicio
            </Button>
          </Link>

          <Link to={"/peritaje"} className="grow">
            <Button color="inherit" className="grow-button">
              Peritaje
            </Button>
          </Link>

          <Link to={"/ventas"} className="grow">
            <Button color="inherit" className="grow-button">
              Venta
            </Button>
          </Link>

          <Link to={"/partes"} className="grow">
            <Button color="inherit" className="grow-button">
              Partes
            </Button>
          </Link>
          <Link to={'/seguros'} className="grow">
              <Button color="inherit" className="grow-button">Seguros</Button>
          </Link>

          <Link to={'/mantenimiento'} className="grow">
              <Button color="inherit" className="grow-button">Mantenimiento</Button>
          </Link>

                </Toolbar>
            </AppBar>
        );
    }
}
export default Header;
