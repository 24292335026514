import React from 'react';
import Grid from "@material-ui/core/Grid";

class FooterComponent extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Grid container spacing={0} className='footer-container'>
                    <Grid item xs={12} md={6} className='footer-panel'>
                        <div><strong>Sede Bogotá - Colombia</strong></div>
                        <div>Cra. 55 No. 15-82 Puente Aranda</div>
                        <div>Cel.: (+57) 313 805 24 00</div>
                        <div>Tel.: (571) 405 07 72</div>
                        <div>info@todoenmaquinariapesada.com</div>
                    </Grid>
                    <Grid item xs={12} md={6} className='footer-panel'>
                        <div><strong>Sede Miami - Estados Unidos</strong></div>
                        <div>4452 NW 74 AV Miami FL 33166</div>
                        <div>Cel.: (+1) 786 250 6168</div>
                        <div>info@todoenmaquinariapesada.com</div>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default FooterComponent;