import React, { useEffect, useState } from "react";
import "./AdminHeader.css";
import { AppBar, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Popover from "@material-ui/core/Popover";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useHistory } from 'react-router-dom';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';

const AdminHeader = () => {
    const history = useHistory();
    const [ isAuth, setIsAuth ] = useState(false);
    const [ authUser, setAuthUser ] = useState(null);
    const [ anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        checkAuth();
    }, [])

    const checkAuth = () => {
        let user = JSON.parse(localStorage.getItem("TMPData"));
        if (user && user.user&& user.user.is_admin) {
            setIsAuth(true);
            setAuthUser(user.user);
        } else {
            localStorage.clear();
            history.push('/admin')
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const closeSession = () => {
        localStorage.clear();
        window.location.reload();
    }

    const id = Boolean(anchorEl) ? "simple-popover" : undefined;

    if(!isAuth || !authUser) return null

    return (
        <AppBar position="sticky" className="mainHeader">
            <Grid
                container
                spacing={0}
                justify="space-between"
                className="header-header"
            >
                <div className="header-header-left-panel">

                    <div className="header-header-right-panel-item">
                        <Button
                            aria-describedby={id}
                            onClick={() => history.push('/admin/dashboard')}
                            color="inherit"
                            className="grow-button-head"
                        >
                            <ViewComfyIcon />
                        </Button>
                    </div>

                    <div className="header-header-left-panel-item">
                        <Button
                            aria-describedby={id}
                            onClick={(e) => handleClick(e)}
                            color="inherit"
                            className="grow-button-head"
                        >
                            {authUser.name}&nbsp;
                            <ArrowDropDownIcon />
                            &nbsp;
                        </Button>
                        
                        <Popover
                            id={id}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => handleClose()}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <div className="user-top-main-menu">
                                <div
                                    className="user-top-main-menu-item"
                                    onClick={() => closeSession()}
                                >
                                    <AccountCircleIcon />
                                    <span>CERRAR SESIÓN</span>
                                </div>
                            </div>
                        </Popover>
                    </div>
                </div>
            </Grid>
        </AppBar>
    );
    
}
export default AdminHeader;