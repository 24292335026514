import React, { Component, Fragment } from 'react';
import './CotizacionesList.css';
import {Grid, Paper, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import {
    SelectionState,
    PagingState,
    IntegratedPaging,
    SearchState,
    IntegratedFiltering,

} from '@devexpress/dx-react-grid';
import {
    Grid as GridTable,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel
} from '@devexpress/dx-react-grid-material-ui';


import axios from "axios";
import {API_URL} from "../../API";


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiInput: {
            root:{
                width: "100%",
                "&$focused": {
                    '&:after': {
                        borderBottom: "2px solid #000000 !important",
                        width: "100%"
                    }
                },
            },

        }
    }
});

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#000000',
    },
    tabsRoot: {
        borderBottom: '1px solid #000000',
    },
    tabsIndicator: {
        backgroundColor: '#ff9b30',
        height: "4px",
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: "\"machineFont\" !important",
        fontSize: "18px",
        '&:hover': {
            color: '#ff9b30',
            opacity: 5,
        },
        '&:focus': {
            color: '#ff9b30',
        },
    },
    tabSelected: {
        color: '#ff9b30',
    },
    typography: {
        padding: theme.spacing.unit * 3,
    },
    input: {
        backgroundColor: "blue",
    }
});

class CotizacionesList extends Component{

    constructor(props){
        super(props);
        this.state = {
            quotationRequestsList: [],
            columns: [
                { name: 'id', title: '#' },
                { name: 'customerName', title: 'Nombre' },
                { name: 'customerLastName', title: 'Apellido' },
                //{ name: 'customerEmail', title: 'Correo' },
                //{ name: 'customerPhone', title: 'Telefono' },
                { name: 'customerCountry', title: 'Pais' },
                //{ name: 'customerCity', title: 'Ciudad' },
                { name: 'machineCategory', title: 'Categoria' },
                { name: 'machineBrand', title: 'Marca' },
                { name: 'machineModel', title: 'Modelo' },
                { name: 'machineYear', title: 'Año' },
            ],
            value: 0,
        };

        this.changeSelection = selection => this.setState({ selection });
    }

    _updateQuotationRequestsList(){
        axios.get(API_URL+'/quotationRequests').then((response) => {


            let quotationRequestList = response.data.map(quotationRequestsResponse => ({
                id: quotationRequestsResponse.id,
                customerName : quotationRequestsResponse.customer_name,
                customerLastName : quotationRequestsResponse.customer_lastname,
                customerEmail : quotationRequestsResponse.customer_email,
                customerPhone : quotationRequestsResponse.customer_phone,
                customerCountry : quotationRequestsResponse.customer_country,
                customerCity : quotationRequestsResponse.customer_city,
                machineCategory : quotationRequestsResponse.machine_category,
                machineBrand : quotationRequestsResponse.machine_brand,
                machineModel : quotationRequestsResponse.machine_model,
                machineYear : quotationRequestsResponse.machine_year,
                machineSN : quotationRequestsResponse.machine_SN,
                machineImgPlate : quotationRequestsResponse.machine_plate_img,
            }));


            this.setState({
                quotationRequestsList: quotationRequestList
            })


        });
    }

    componentDidMount() {
        this._updateQuotationRequestsList();
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        return (

            <Fragment>
                <MuiThemeProvider theme={theme}>
                <Grid container className={["mainContainer","yellowBackground"].join(" ")}>
                    <Grid item xs={12} className="mainRow">
                        <Paper className="mainPaperCotizar">

                            <Typography variant="h3" className="cotizarTitle">Cotizaciones</Typography>

                            <AppBar position="static" className={classes.root}>
                                <Tabs variant="fullWidth" value={this.state.value} onChange={this.handleChange} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                                    <Tab label="Nuevas" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}/>
                                    <Tab label="En Proceso" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}  />
                                    <Tab label="Archivadas" classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
                                </Tabs>
                            </AppBar>

                            {this.state.value === 0 && <TabContainer>
                                <GridTable
                                    rows={this.state.quotationRequestsList}
                                    columns={this.state.columns}
                                >
                                    <PagingState
                                        defaultCurrentPage={0}
                                        pageSize={10}
                                    />
                                    <SelectionState
                                        selection={this.state.selection}
                                        onSelectionChange={this.changeSelection}
                                    />
                                    <SearchState />
                                    <IntegratedFiltering />
                                    <IntegratedPaging />
                                    <Table />
                                    <TableHeaderRow />
                                    <Toolbar />
                                    <SearchPanel
                                        messages={{searchPlaceholder: 'Buscar...'}}
                                        InputProps={{
                                            className: classes.input,
                                        }}
                                    />
                                    <PagingPanel />
                                </GridTable>
                            </TabContainer>}
                            {this.state.value === 1 && <TabContainer>En Proceso</TabContainer>}
                            {this.state.value === 2 && <TabContainer>Archivadas</TabContainer>}
                        </Paper>
                    </Grid>
                </Grid>
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

export default withStyles(styles)(CotizacionesList);





