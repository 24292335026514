import React, { Component, Fragment } from 'react';
import './Brands.css';
import BrandsComponent from "../../components/BrandsComponent/BrandsComponent";


class Brands extends Component{
    render(){
        return(
            <Fragment>
                <BrandsComponent/>
            </Fragment>
        );
    }
}

export default Brands;