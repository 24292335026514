import React from 'react';
import Header from "../../components/Header/Header";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import NewMahineComponent from "../../components/NewMachineComponent/NewMahineComponent";

class NewMachine extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Header/>
                <NewMahineComponent variant='user'/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default NewMachine;