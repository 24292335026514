import React, { Component } from 'react';
import queryString from "query-string";
import {Redirect} from "react-router-dom";
import {API_URL, VALIDATE_ACCOUNT_EMAIL} from "../../API";
import axios from 'axios';
import {message_responses} from "../../MESSAGE_RESPONSES";

class VerifyEmailComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            email: '',
            key: '',
            redirectToLogin: false,
            successVerified: false,
            verificationMessage: '',
        }
    }

    componentDidMount() {
        this._getUrlParams();
    }

    _getUrlParams() {
        //console.log(this.props.url)
        let url_params = queryString.parse(this.props.url);

        if(url_params.email === undefined || url_params.key === undefined){
            this.setState({redirectToLogin: true})
        }

        this.setState({
            email: url_params.email,
            key: url_params.key,
        }, () => {
            this.validateParameters()
        })
    }

    validateParameters(){

        let formData = {
            email: this.state.email,
            key: this.state.key,
        }

        axios.post(API_URL + VALIDATE_ACCOUNT_EMAIL, formData).then((response) => {
            if(response.data.success){
                this.setState({
                    successVerified: true,
                    verificationMessage: message_responses[response.data.message],
                }, () => {
                    this.setState({redirectToLogin:true})
                })
            }else{
                this.setState({
                    successVerified: false,
                    verificationMessage: message_responses[response.data.message],
                }, () => {
                    this.setState({redirectToLogin:true})
                })
            }
        }).catch((error) => {
            if(error.response && error.response.data){
                this.setState({
                    successVerified: false,
                    verificationMessage: message_responses[error.response.data.message],
                }, () => {
                    this.setState({redirectToLogin:true})
                })
            }else{
                this.setState({
                    successVerified: false,
                    verificationMessage: "ERROR DE SERVIDOR",
                }, () => {
                    this.setState({redirectToLogin:true})
                })
            }
        })
    }

    redirectToLogin = () => {
        if (this.state.redirectToLogin) {
            return <Redirect push to={{
                pathname: '/',
                state: {
                    successVerified: this.state.successVerified,
                    verificationMessage : this.state.verificationMessage,
                }
            }} />
        }
    }

    render(){
        return(
            <React.Fragment>
                {this.redirectToLogin()}
                VERIFICANDO EMAIL ...
            </React.Fragment>
        );
    }
}

export default VerifyEmailComponent;