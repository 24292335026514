import React, { Component } from 'react';
import {Button, ExpansionPanel, Grid, Typography, ExpansionPanelSummary, ExpansionPanelDetails , Divider} from '@material-ui/core';
import StartIcon from '@material-ui/icons/Star';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import axios from 'axios';
import {API_URL, GET_MACHINE_FOR_SELL_BY_ID} from "../../API";
import SellRentGalleryItemImages from "../SellRentGalleryItemImages/SellRentGalleryItemImages";
import NumberFormat from "react-number-format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import {message_responses} from "../../MESSAGE_RESPONSES";
import Skeleton from "@material-ui/lab/Skeleton";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from "@material-ui/core/Tooltip";
import CommentComponent from '../PostCommentComponent/CommentComponent'
import { Link, Redirect } from "react-router-dom";
import { getUrlParams } from "../../utils/utils";
import md5 from 'md5';
import Swal from "sweetalert2";
import { Strings, Colors } from "../../utils/constants";

const styles = {
    mainContainer : {
        justifyContent: "center"
    },
    mainRow : {
        maxWidth: "1200px",
        margin: "10px 0px 10px 0px",
        paddingTop: "3%"
    },
    mainImg : {
        maxWidth: "100%",
        maxHeight: "300px"
    },
    centerContent : {
        justifyContent: "center"
    },
    headerContainer :{
        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        //padding: "15px"
    },
    descriptionText : {
        lineHeight: "20px",
        height: "200px",
        textAlign: "justify"
    },
    gold : {
        color: "gold"
    },
    priceText:{
        width: "50%",
        textAlign: "center",
        letterSpacing: "3px"
    },
    priceTextBold:{
        fontWeight: "bold",
        width: "50%",
        textAlign: "center",
        letterSpacing: "3px",
        color: "gray",
    },
    buyButton:{
        backgroundColor: "#ff9b01",
        width: "50%",
        textAlign: "center",
        margin: "0",
        borderRadius: "0",
        letterSpacing: "3px"
    },
    buttomGroup:{
        border: "#ff9b01",
        borderStyle: "solid",
        borderWidth: "1px",
        padding: "0",
        display: "flex",
        alignItems: "center"
    },
    descriptionTextBottom : {
        lineHeight: "20px",
        textAlign: "justify",
        paddingBottom: "10px"
    },
    bottomContainer: {
        padding: "10px"
    },
    lastPanel: {
        borderRadius: "0px"
    },
    rightPanelDetails: {
        padding: '25px',
    },
    priceStyle: {
        fontSize: "30px",
        fontWeight: "lighter",
    },
    rightPanelDetailsPhone:{
        padding: "7px 0px",
    },
    rightPanelPhoneIcons:{
        fontSize: "40px",
        padding: "0px 10px",
    },
    sellRentItemDetailsTitle:{
        padding: "10px 0px",
        width: "100%",
        backgroundColor: "#000000",
        color: "#ff9b01",
        textAlign: "center",
        fontSize: "40px",
        fontWeight: "lighter",
    }
}

class SellRentItemDetailsComponent extends Component{

    constructor(props){
        super(props);

        this.state = {
            activeStep: 0,
            product: {},
            gettingProduct: false,
            errorProduct: '',
            product_images: [],
            isAuth: false,
            redirectToHome: false,
        }
    }

    componentDidMount() {
        this.checkAuth();
        this.getUrlParameters();
        this._getProduct();
    }

    getUrlParameters(){
        const urlParameters = getUrlParams(this.props.location.search);

        if(urlParameters.transactionState){
            let status;
            let title;
            let html;
            let icon = 'error';
            const PAYU_API_KEY = process.env.REACT_APP_PAYU_ON_TESTING ? "4Vj8eK4rloUd272L48hsrarnUA" : process.env.REACT_APP_PAYU_API_KEY;
            const PAYU_MERCHANT_ID = process.env.REACT_APP_PAYU_ON_TESTING ? "508029" : process.env.REACT_APP_PAYU_MERCHANT_ID;
            const signature = `${PAYU_API_KEY}~${PAYU_MERCHANT_ID}~${urlParameters.referenceCode}~${parseInt(urlParameters.TX_VALUE, 10)}~COP~${urlParameters.transactionState}`;
            const encryptedSignature = md5(signature);
    
            /*console.log(urlParameters.signature);
            console.log(encryptedSignature);
            console.log(urlParameters.signature === encryptedSignature);*/

            const extra1 = JSON.parse(urlParameters.extra1);

            if (urlParameters.transactionState == '4' ) {
                status = "APROBADA";
                title = Strings.PAYMENT_MACHINE_SUCCESS_TITLE;
                html = Strings.PAYMENT_MACHINE_SUCCESS_HTML(extra1.machineId, status, urlParameters.transactionId, urlParameters.referenceCode, urlParameters.pseBank, urlParameters.pseReference1);
                icon = 'success';
            }

            if (urlParameters.transactionState == '6' ) {
                status = "DECLINADA";
                title = Strings.PAYMENT_MACHINE_DECLINED_TITLE;
                html = Strings.PAYMENT_MACHINE_DECLINED_HTML(extra1.machineId, status, urlParameters.transactionId, urlParameters.referenceCode, urlParameters.pseBank, urlParameters.pseReference1);
                icon = 'error';
            }

            if (urlParameters.transactionState == '7' ) {
                status = "PENDIENTE DE PAGO";
                title = Strings.PAYMENT_MACHINE_PENDING_TITLE;
                html = Strings.PAYMENT_MACHINE_PENDING_HTML(extra1.machineId, status, urlParameters.transactionId, urlParameters.referenceCode, urlParameters.pseBank, urlParameters.pseReference1);
                icon = 'warning';
            }

            if (urlParameters.transactionState == '104' ) {
                status = "ERROR";
                title = Strings.PAYMENT_MACHINE_ERROR_TITLE;
                html = Strings.PAYMENT_MACHINE_ERROR_HTML(extra1.machineId, status, urlParameters.transactionId, urlParameters.referenceCode, urlParameters.pseBank, urlParameters.pseReference1);
                icon = 'error';
            }          

            Swal.fire({
                title,
                html,
                icon,
                width: "80%",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                confirmButtonColor: Colors.SUCCESS_MAIN,
            });
            
        }
        
    }

    checkAuth(){
        let user = JSON.parse(localStorage.getItem('TMPData'));
        if(user){
            this.setState({isAuth: true})
        }else{
            this.setState({isAuth: false})
        }
    }

    _getProduct(){
        let end_point = GET_MACHINE_FOR_SELL_BY_ID + this.props.url_id.maquinaId;
        let requestStatus = "gettingProduct";
        let errorVar = "errorProduct";
        let array = "product";
        this.setState({[requestStatus]:true})
        axios.get(API_URL + end_point).then((response) => {
            this.setState({[requestStatus]:false})
            if(response.data.success){
                this.setState({[array] : response.data.data})
            }else{
                this.setState({
                    [errorVar]: message_responses[response.data.message],
                })
            }
        }).catch((error) => {
            this.setState({[requestStatus]:false})
            if(error.response && error.response.data){
                this.setState({
                    [errorVar]: message_responses[error.response.data.message],
                })
                alert(message_responses[error.response.data.message]);
                this.setState({redirectToHome : true});
            }else{
                this.setState({
                    [errorVar]: "ERROR DE SERVIDOR",
                })
            }
        })
    }

    handleWhatsappClick = () => {
        window.open("https://api.whatsapp.com/send?phone=" + this.state.product.contact_country_dial_code + this.state.product.contact_phone_number_1);
    };

    handlePhoneCallClick = () => {
        window.open("tel:" + this.state.product.contact_country_dial_code + this.state.product.contact_phone_number_1);
    };

    redirectToHome = () => {
        if (this.state.redirectToHome) {
            return <Redirect push to='/' />
        }
    }


    render(){
        return(
            <Grid container style={styles.mainContainer}>
                <Grid item xs={12} style={styles.mainRow}>
                    <Grid container style={styles.headerContainer}>
                        {this.redirectToHome()}
                        {
                            this.state.gettingProduct ?
                                <React.Fragment>
                                    <Grid container spacing={1}>
                                        <Grid item sm={12}>
                                            <Skeleton variant='rect' width="100%" height={60} />
                                        </Grid>

                                        <Grid item sm={6}>
                                            <Skeleton variant='rect' width="100%" height={350} />
                                        </Grid>

                                        <Grid item sm={6}>
                                            <Skeleton variant='rect' width="100%" height={350} />
                                        </Grid>

                                        <Grid item sm={12}>
                                            <Skeleton variant='rect' width="100%" height={60} />
                                        </Grid>

                                        <Grid item sm={12}>
                                            <Skeleton variant='rect' width="100%" height={60} />
                                        </Grid>

                                        <Grid item sm={12}>
                                            <Skeleton variant='rect' width="100%" height={60} />
                                        </Grid>

                                        <Grid item sm={12}>
                                            <Skeleton variant='rect' width="100%" height={60} />
                                        </Grid>
                                    </Grid>
                                </React.Fragment>

                                :
                                <React.Fragment>
                                    <Grid item sm={12}>
                                        <div style={styles.sellRentItemDetailsTitle}>{this.state.product.subcategory + ' - ' + this.state.product.brand + ' ' + this.state.product.model}</div>
                                    </Grid>

                                    <Grid item sm={6} style={styles.centerContent}>
                                        <SellRentGalleryItemImages data={this.state.product.images} id={this.props.url_id.maquinaId} single={true} status={this.state.product.status} variant="normal"/>
                                    </Grid>

                                    <Grid item sm={6} >
                                        <div style={styles.rightPanelDetails}>
                                            <Typography  variant="body2" component="div">
                                                <span style={styles.priceTextBold}>PUBLICACIÓN #{this.state.product.id}</span>
                                            </Typography>

                                            <div style={styles.priceStyle}>
                                                <NumberFormat
                                                    value={this.state.product.sale_price}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={this.state.product.currency + ' $ '}
                                                />
                                            </div>

                                            <Typography variant="h6" component="p" className="generalFont">
                                                <StartIcon style={styles.gold}/>
                                                <StartIcon style={styles.gold}/>
                                                <StartIcon style={styles.gold}/>
                                                <StartIcon style={styles.gold}/>
                                                <StartIcon />
                                            </Typography>
                                            <Typography  variant="body2" component="div">
                                                <span style={styles.priceText}><strong>MARCA:</strong> {this.state.product.brand}</span>
                                            </Typography>
                                            <Typography  variant="body2" component="div">
                                                <span style={styles.priceText}><strong>MODELO:</strong> {this.state.product.model}</span>
                                            </Typography>
                                            <Typography  variant="body2" component="div">
                                                <span style={styles.priceText}><strong>AÑO:</strong> {this.state.product.year}</span>
                                            </Typography>
                                            <Typography  variant="body2" component="div">
                                                <span style={styles.priceText}><strong>HORAS:</strong> {this.state.product.horometer}</span>
                                            </Typography>
                                            <Typography  variant="body2" component="div">
                                                <span style={styles.priceText}><strong>UBICACIÓN:</strong> {this.state.product.location_city},&nbsp;{this.state.product.location_country}</span>
                                            </Typography>
                                            {
                                                this.state.isAuth ? 
                                                <>
                                                    <Grid item xs={12} style={styles.rightPanelDetailsPhone}>
                                                        <Button
                                                            className="panelButton"
                                                            onClick={() => this.handleWhatsappClick()}
                                                        >
                                                            <FontAwesomeIcon icon={faWhatsapp} style={styles.rightPanelPhoneIcons} />
                                                            {"(+" + this.state.product.contact_country_dial_code + ") " + this.state.product.contact_phone_number_1}
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} style={styles.rightPanelDetailsPhone}>
                                                        <Button
                                                            className="panelButton"
                                                            onClick={() => this.handlePhoneCallClick()}
                                                        >
                                                            <FontAwesomeIcon icon={faPhone} style={styles.rightPanelPhoneIcons} />
                                                            {"(+" + this.state.product.contact_country_dial_code + ") " + this.state.product.contact_phone_number_1}
                                                        </Button>
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                <Link to={"/login?redirect="+this.props.location.pathname+this.props.location.search}>
                                                    <Grid item xs={12} style={styles.rightPanelDetailsPhone}>
                                                        <Button
                                                            className="panelButton"
                                                        >
                                                            <FontAwesomeIcon icon={faPhone} style={styles.rightPanelPhoneIcons} />
                                                            Para ver los datos del vendedor debes iniciar sesión.
                                                        </Button>
                                                    </Grid>
                                                </Link>
                                                </>
                                            }
                                            

                                        </div>
                                    </Grid>

                                    <Grid container spacing={0} >

                                        <Grid item xs={12}>
                                            <ExpansionPanel style={styles.lastPanel}>
                                                <ExpansionPanelSummary expandIcon={<KeyboardArrowDown/>}>
                                                    <Typography variant="h5" component="p" className="generalFont">Datos de contacto</Typography>
                                                        <Tooltip title="Se refiere a los datos para que contactes el vendedor para cualquier información adicional sobre la máquina de la cual estas interesado y puedas realizar tu negocio. ">
                                                            <HelpIcon color="primary"/>
                                                        </Tooltip>

                                                </ExpansionPanelSummary>
                                                <Divider variant="middle" />
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={1}>
                                                        {
                                                            this.state.isAuth ? 
                                                            <>
                                                            {
                                                                this.state.product.contact_name !== undefined && this.state.product.contact_name !== '' && this.state.product.contact_name !== null &&
                                                                <Grid item xs={12} sm={4}>
                                                                    <Typography  variant="body2" component="div">
                                                                        <div><strong>Nombres:</strong></div>
                                                                        <div><span style={styles.priceText}>{this.state.product.contact_name}</span></div>
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                            {
                                                                this.state.product.contact_last_name !== undefined && this.state.product.contact_last_name !== '' && this.state.product.contact_last_name !== null &&
                                                                <Grid item xs={12} sm={4}>
                                                                    <Typography  variant="body2" component="div">
                                                                        <div><strong>Apellidos:</strong></div>
                                                                        <div><span style={styles.priceText}>{this.state.product.contact_last_name}</span></div>
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                            {
                                                                this.state.product.contact_country !== undefined && this.state.product.contact_country !== '' && this.state.product.contact_country !== null &&
                                                                <Grid item xs={12} sm={4}>
                                                                    <Typography  variant="body2" component="div">
                                                                        <div><strong>Ubicación del contacto:</strong></div>
                                                                        <div><span style={styles.priceText}>{this.state.product.contact_country}</span></div>
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                            {
                                                                this.state.product.contact_phone_number_1 !== undefined && this.state.product.contact_phone_number_1 !== '' && this.state.product.contact_phone_number_1 !== null &&
                                                                <Grid item xs={12} sm={4}>
                                                                    <Typography  variant="body2" component="div">
                                                                        <div><strong>Número teléfono móvil 1:</strong></div>
                                                                        <div><span style={styles.priceText}>{"(+" + this.state.product.contact_country_dial_code + ") " + this.state.product.contact_phone_number_1}</span></div>
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                            {
                                                                this.state.product.contact_phone_number_2 !== undefined && this.state.product.contact_phone_number_2 !== '' && this.state.product.contact_phone_number_2 !== null &&
                                                                <Grid item xs={12} sm={4}>
                                                                    <Typography  variant="body2" component="div">
                                                                        <div><strong>Número teléfono móvil 2:</strong></div>
                                                                        <div><span style={styles.priceText}>{this.state.product.contact_phone_number_2}</span></div>
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                            {
                                                                this.state.product.contact_email !== undefined && this.state.product.contact_email !== '' && this.state.product.contact_email !== null &&
                                                                <Grid item xs={12} sm={4}>
                                                                    <Typography  variant="body2" component="div">
                                                                        <div><strong>Correo electrónico</strong></div>
                                                                        <div><span style={styles.priceText}>{this.state.product.contact_email}</span></div>
                                                                    </Typography>
                                                                </Grid>
                                                            }
                                                            </>
                                                            :
                                                            <>Para ver los datos del vendedor debes&nbsp;<Link to={"/login?redirect="+this.props.location.pathname+this.props.location.search}><strong>iniciar sesión.</strong></Link></>
                                                        }
                                                        
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <ExpansionPanel style={styles.lastPanel}>
                                                <ExpansionPanelSummary expandIcon={<KeyboardArrowDown/>}>
                                                    <Typography variant="h5" component="p" className="generalFont">Detalles de comercio</Typography>
                                                    <Tooltip title="Se refiere a los términos de negociación de la máquina como precio, moneda de negociación y términos de entrega.">
                                                            <HelpIcon color="primary"/>
                                                     </Tooltip>
                                                </ExpansionPanelSummary>
                                                <Divider variant="middle" />
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={1}>
                                                        {
                                                            this.state.product.currency !== undefined && this.state.product.currency !== '' && this.state.product.currency !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Moneda: </strong></div>
                                                                    <div><span style={styles.priceText}>{"(" + this.state.product.currency + ") " + this.state.product.currency_symbol + " " + this.state.product.currency_name}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.sale_price !== undefined && this.state.product.sale_price !== '' && this.state.product.sale_price !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Precio de venta: </strong></div>
                                                                    <div>
                                                        <span style={styles.priceText}>
                                                            <NumberFormat
                                                                value={this.state.product.sale_price}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                thousandSeparator={","}
                                                                decimalSeparator={"."}
                                                                fixedDecimalScale={true}
                                                                isNumericString
                                                                prefix={this.state.product.currency_symbol}
                                                                suffix={this.state.product.currency}
                                                            />
                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.is_negotiable !== undefined && this.state.product.is_negotiable !== '' && this.state.product.is_negotiable !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Precios negociables?: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.is_negotiable}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.location_country !== undefined && this.state.product.location_country !== '' && this.state.product.location_country !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>País de ubicación: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.location_country}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.location_city !== undefined && this.state.product.location_city !== '' && this.state.product.location_city !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Ciudad de ubicación: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.location_city}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.delivery_terms !== undefined && this.state.product.delivery_terms !== '' && this.state.product.delivery_terms !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Términos de comercio internacional:</strong></div>
                                                                    <div>

                                                                        <span style={styles.priceText}>
                                                                            <Tooltip title="¿Que son los términos de comercio internacional?">
                                                                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                                                <a href="https://vicentferrer.com/incoterms/" target="_blank">
                                                                                    <HelpIcon color="primary"/>
                                                                                </a>
                                                                            </Tooltip>
                                                                            {this.state.product.delivery_terms}
                                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.delivery_country !== undefined && this.state.product.delivery_country !== '' && this.state.product.delivery_country !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>País de entrega: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.delivery_country}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.delivery_place !== undefined && this.state.product.delivery_place !== '' && this.state.product.delivery_place !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Lugar de entrega: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.delivery_place}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <ExpansionPanel  style={styles.lastPanel}>
                                                <ExpansionPanelSummary expandIcon={<KeyboardArrowDown/>}>
                                                    <Typography variant="h5" component="p" className="generalFont">Caracteristicas</Typography>
                                                    <Tooltip title="Se refiere a las características generales de la máquina como tipo de máquina, marca, modelo, año, tipo de combustible, y demás información general de la máquina.">
                                                            <HelpIcon color="primary"/>
                                                     </Tooltip>
                                                </ExpansionPanelSummary>
                                                <Divider variant="middle" />
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={1}>

                                                        {
                                                            this.state.product.category !== undefined && this.state.product.category !== '' && this.state.product.category !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Categoría: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.category}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.subcategory !== undefined && this.state.product.subcategory !== '' && this.state.product.subcategory !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Subcategoría: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.subcategory}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.brand !== undefined && this.state.product.brand !== '' && this.state.product.brand !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Marca fabricante: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.brand}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.model !== undefined && this.state.product.model !== '' && this.state.product.model !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Modelo: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.model}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.year !== undefined && this.state.product.year !== '' && this.state.product.year !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Año: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.year}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.horometer !== undefined && this.state.product.horometer !== '' && this.state.product.horometer !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Horómetro: </strong></div>
                                                                    <div>
                                                                        <span style={styles.priceText}>
                                                                            <NumberFormat
                                                                                value={this.state.product.horometer}
                                                                                displayType={'text'}
                                                                                decimalScale={0}
                                                                                thousandSeparator={","}
                                                                                decimalSeparator={"."}
                                                                                fixedDecimalScale={true}
                                                                                isNumericString
                                                                                suffix={' Horas'}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.condition !== undefined && this.state.product.condition !== '' && this.state.product.condition !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Condición: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.condition}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.serial_number !== undefined && this.state.product.serial_number !== '' && this.state.product.serial_number !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Serial de la maquina: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.serial_number}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.useful_weight !== undefined && this.state.product.useful_weight !== '' && this.state.product.useful_weight !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Peso útil de la maquina: </strong></div>
                                                                    <div>
                                                                        <span style={styles.priceText}>
                                                                            <NumberFormat
                                                                                value={this.state.product.useful_weight}
                                                                                displayType={'text'}
                                                                                decimalScale={2}
                                                                                thousandSeparator={","}
                                                                                decimalSeparator={"."}
                                                                                fixedDecimalScale={true}
                                                                                isNumericString
                                                                                suffix={'kg'}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.motor_brand !== undefined && this.state.product.motor_brand !== '' && this.state.product.motor_brand !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Marca fabricante del motor: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.motor_brand}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.motor_model !== undefined && this.state.product.motor_model !== '' && this.state.product.motor_model !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Modelo del motor: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.motor_model}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.engine_potency !== undefined && this.state.product.engine_potency !== '' && this.state.product.engine_potency !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Potencia del motor: </strong></div>
                                                                    <div>
                                                                        <span style={styles.priceText}>
                                                                            <NumberFormat
                                                                                value={this.state.product.engine_potency}
                                                                                displayType={'text'}
                                                                                decimalScale={0}
                                                                                thousandSeparator={","}
                                                                                decimalSeparator={"."}
                                                                                fixedDecimalScale={true}
                                                                                isNumericString
                                                                                suffix={' (HP) A 2100 RPM'}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.fuel_type !== undefined && this.state.product.fuel_type !== '' && this.state.product.fuel_type !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Tipo de combustible: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.fuel_type}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.fuel_consumption !== undefined && this.state.product.fuel_consumption !== '' && this.state.product.fuel_consumption !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Consumo de Combustible: </strong></div>
                                                                    <div>
                                                                        <span style={styles.priceText}>
                                                                            <NumberFormat
                                                                                value={this.state.product.fuel_consumption}
                                                                                displayType={'text'}
                                                                                decimalScale={0}
                                                                                thousandSeparator={","}
                                                                                decimalSeparator={"."}
                                                                                fixedDecimalScale={true}
                                                                                isNumericString
                                                                                suffix={this.state.product.fuel_type_unit}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.cylinders_liters !== undefined && this.state.product.cylinders_liters !== '' && this.state.product.cylinders_liters !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Cilindrada del motor: </strong></div>
                                                                    <div>
                                                                        <span style={styles.priceText}>
                                                                            <NumberFormat
                                                                                value={this.state.product.cylinders_liters}
                                                                                displayType={'text'}
                                                                                decimalScale={1}
                                                                                thousandSeparator={","}
                                                                                decimalSeparator={"."}
                                                                                fixedDecimalScale={true}
                                                                                isNumericString
                                                                                suffix={"Litros"}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.has_air_conditioner !== undefined && this.state.product.has_air_conditioner !== '' && this.state.product.has_air_conditioner !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Aire acondicionado?: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.has_air_conditioner}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.has_gps !== undefined && this.state.product.has_gps !== '' && this.state.product.has_gps !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>GPS?: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.has_gps}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.cabin_type !== undefined && this.state.product.cabin_type !== '' && this.state.product.cabin_type !== null &&
                                                            <Grid item xs={12} sm={3}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Tipo de cabina?: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.cabin_type}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }

                                                        {
                                                            this.state.product.comments !== undefined && this.state.product.comments !== '' && this.state.product.comments !== null &&
                                                            <Grid item xs={12}>
                                                                <Typography  variant="body2" component="div">
                                                                    <div><strong>Comentarios adicionales: </strong></div>
                                                                    <div><span style={styles.priceText}>{this.state.product.comments}</span></div>
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Grid>


                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>

                                        {
                                            this.state.product.details !== undefined && this.state.product.details.length > 0 &&
                                            <React.Fragment>
                                                <Grid item xs={12}>
                                                    <ExpansionPanel style={styles.lastPanel}>
                                                        <ExpansionPanelSummary expandIcon={<KeyboardArrowDown/>}>
                                                            <Typography variant="h5" component="p" className="generalFont">{"Caracteristicas " + this.state.product.subcategory}</Typography>
                                                            <Tooltip title="se refiere a capacidades de carga, de fuerza y medidas de trabajo de la máquina en específico.">
                                                                 <HelpIcon color="primary"/>
                                                            </Tooltip>
                                                        </ExpansionPanelSummary>
                                                        <Divider variant="middle" />
                                                        <ExpansionPanelDetails>
                                                            <Grid container spacing={1}>
                                                                {
                                                                    this.state.product.details.reverse().map((detail, index) => (
                                                                        <Grid item xs={12} sm={3} key={index}>
                                                                            <Typography  variant="body2" component="div">
                                                                                <div><strong>{detail.field + ":"}</strong></div>
                                                                                {
                                                                                    isNaN(detail.value) ?
                                                                                        <div><span style={styles.priceText}>{detail.value + detail.unit}</span></div>
                                                                                        :
                                                                                        <div>
                                                                                            <span style={styles.priceText}>
                                                                                                <NumberFormat
                                                                                                    value={detail.value}
                                                                                                    displayType={'text'}
                                                                                                    decimalScale={2}
                                                                                                    thousandSeparator={","}
                                                                                                    decimalSeparator={"."}
                                                                                                    fixedDecimalScale={true}
                                                                                                    isNumericString
                                                                                                    suffix={detail.unit !== null && detail.unit !== undefined && detail.unit !== '' ? detail.unit: ''}
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                }

                                                                            </Typography>
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                         {
                                            this.state.product.details !== undefined && this.state.product.details.length > 0 &&
                                            <React.Fragment>
                                                <Grid item xs={12}>
                                                    <ExpansionPanel style={styles.lastPanel}>
                                                        <ExpansionPanelSummary expandIcon={<KeyboardArrowDown/>}>
                                                            <Typography variant="h5" component="p" className="generalFont">Comentarios</Typography>
                                                            <Tooltip title="En esta sección puedes realizar o ver los comentarios o preguntas concernientes a la máquina de la publicación y el dueño de la misma publicación te puede contestar dichos comentarios.   ">
                                                                 <HelpIcon color="primary"/>
                                                            </Tooltip>
                                                        </ExpansionPanelSummary>
                                                        <Divider variant="middle" />
                                                        <ExpansionPanelDetails>
                                                            <CommentComponent machine={
                                                                {
                                                                    machineId : this.state.product.id,
                                                                    createdBy : this.state.product.created_by,
                                                                }
                                                            }
                                                            location={this.props.location}
                                                            />
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </Grid>
                                            </React.Fragment>
                                        }


                                    </Grid>
                                </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default SellRentItemDetailsComponent;