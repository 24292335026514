import React, { Fragment } from 'react';
import { AdminMachinesController } from './AdminMachinesController';
import { Button, Grid, AppBar, Tabs, Tab, InputBase, Modal, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Loading from '../LoadingComponent/Loading';
import Logo from '../../images/logo.svg';
import './AdminMachines.css'

const AdminMachines = () => {

    const controllerProps = AdminMachinesController();

    const {
        handleCreateMachine,
        searchText,
        handleSearch,
        handleTabValueChange,
        tabValue,
        machines,
        onTableChange,
        loadingData,
        loadingMachineVisibility,
        options,
        machineTypes,
        isChangeStatusModalOpen,
        handleChangeStatusModalClose,
        changeMachineStatus,
        newStatus,
        setNewStatus,
        updateMachineStatusLoading,
    } = controllerProps;

    let columns = [
        {
            dataField: 'id',
            text: '#',
        }, {
            dataField: 'category',
            text: 'Categoría',
        }, {
            dataField: 'subcategory',
            text: 'Subcategoría',
        }, {
            dataField: 'brand',
            text: 'Marca',
        }, {
            dataField: 'year',
            text: 'Año',
        }, {
            dataField: 'condition',
            text: 'Condición',
        }, {
            dataField: 'price',
            text: 'Precio',
        }, {
            dataField: 'contact_country',
            text: 'País',
        }, {
            dataField: 'contact',
            text: 'Contacto',
        }, {
            dataField: 'phone',
            text: 'Teléfono',
        }, {
            dataField: 'actions',
            text: 'Acciones',
            classes: 'actions-custom-cell'
        }
    ];

    let columnsWithVisibility = [
        {
            dataField: 'id',
            text: '#',
        }, {
            dataField: 'category',
            text: 'Categoría',
        }, {
            dataField: 'subcategory',
            text: 'Subcategoría',
        }, {
            dataField: 'brand',
            text: 'Marca',
        }, {
            dataField: 'year',
            text: 'Año',
        }, {
            dataField: 'condition',
            text: 'Condición',
        }, {
            dataField: 'price',
            text: 'Precio',
        }, {
            dataField: 'contact_country',
            text: 'País',
        }, {
            dataField: 'contact',
            text: 'Contacto',
        }, {
            dataField: 'phone',
            text: 'Teléfono',
        }, {
            dataField: 'visibility',
            text: 'Visibilidad',
            classes: 'actions-custom-cell'
        }, {
            dataField: 'actions',
            text: 'Acciones',
            classes: 'actions-custom-cell'
        }
    ];

    const rowStyleMachines = (row, rowIndex) => {

        if(!row) return null;

        /*let orderDate = row.tentative_shipping_date;
        orderDate.setHours(0, 0, 0);
        let today = new Date();
        today.setHours(0, 0, 0);
        let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        let diffDays = Math.round((orderDate - today) / oneDay);
        style.backgroundColor = diffDays > 0 && diffDays < ALERT_REQUESTED_ORDERS_DAYS ? 'rgba(55, 164, 62, 0.30)' : orderDate >= today ? 'white' : 'rgba(255, 24, 24, 0.30)';
        */

        const color = '#000000'
        const height = '45px'
        const cursor = 'pointer'
        const backgroundColor = '#ff9b0157'

        const style = {
            color,
            height,
            cursor,
            backgroundColor
        }
        
        return style
    };


    return (
        <Fragment>
            <Loading loadingImage={Logo} loadingText={'Cargando maquinas.'} loadingState={loadingData}/>
            <Loading loadingImage={Logo} loadingText={'Actualizando estado.'} loadingState={updateMachineStatusLoading}/>
            <Loading loadingImage={Logo} loadingText={'Cambiando visibilidad.'} loadingState={loadingMachineVisibility}/>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={isChangeStatusModalOpen}
                onClose={handleChangeStatusModalClose}
                className='TMPModalContainer'
            >
                <div className='TMPModalContent'>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className='TMPModalTitle'><h2>CAMBIAR ESTADO DE LA MAQUINARIA</h2></div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className='TMPSelect' required>
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Nuevo Estado
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={newStatus}
                                    onChange={(event) => setNewStatus(event.target.value)}
                                    labelWidth={110}
                                >
                                    <MenuItem value={'active'}>Activa</MenuItem>
                                    <MenuItem value={'payment_pending'}>Pago Pendiente</MenuItem>
                                    <MenuItem value={'sold'}>Vendida</MenuItem>
                                    <MenuItem value={'expired'}>Expirada</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} sm={6} className='changeStatusButtonContainer'>
                            <Button variant="contained" color="primary" onClick={() => changeMachineStatus()} fullWidth>
                                Cambiar estado
                            </Button>
                        </Grid>

                    </Grid>
                </div>
            </Modal>

            <Grid container spacing={0} className='module-main-container module-main-container-admin-machines' >
                {
                    <Button variant="contained" color="primary" onClick={handleCreateMachine}>
                        CREAR NUEVA MAQUINA
                    </Button>
                }

                <Grid item xs={12}>
                    <div className='machines-search-box'>
                        <InputBase
                            placeholder='BUSCAR MAQUINA. . . '
                            classes={{
                                root: 'machines-search-input-root',
                                input: 'machines-search-input-input',
                            }}
                            value={searchText}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <h3 className='table-title'>LISTADO DE MAQUINAS</h3>
                </Grid>

                <AppBar position='static' className='root'>
                    <Tabs variant='fullWidth' value={tabValue} onChange={(_, value) => handleTabValueChange(value)} classes={{ root: 'tabs-root', indicator: 'tabs-indicator' }}>
                        <Tab label='ACTIVAS' classes={{ root: 'tab-root', selected: 'tab-selected' }} />
                        <Tab label='PAGO PENDIENTE' classes={{ root: 'tab-root', selected: 'tab-selected' }} />
                        <Tab label='VENDIDAS' classes={{ root: 'tab-root', selected: 'tab-selected' }} />
                        <Tab label='EXPIRADAS' classes={{ root: 'tab-root', selected: 'tab-selected' }} />
                    </Tabs>
                </AppBar>

                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <BootstrapTable
                            remote
                            keyField='id'
                            data={machines}
                            columns={machineTypes[tabValue] === 'sold' || machineTypes[tabValue] === 'active' ? columnsWithVisibility : columns }
                            rowStyle={rowStyleMachines}
                            classes='bootstrap-table'
                            wrapperClasses='bootstrap-table-wrapper'
                            headerClasses='bootstrap-table-header'
                            pagination={paginationFactory(options)}
                            onTableChange={ (type, newState) => onTableChange(type, newState) }
                        />
                    </Grid>
                </Grid>
                
            </Grid>
        </Fragment>
    )
}

export default AdminMachines;