import React from 'react';
import {Button, Grid} from "@material-ui/core";
import ContactUS from "../../images/about-us.jpg";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import {Link} from "react-router-dom";
import {ReactComponent as SVGButton} from "../../images/logo.svg";
import ContactDialog from "../ContactDialog/ContactDialog";

class AboutUSComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            open: false,
            option_one: false,
            option_two: false,
            option_three: false,
            option_four: false,
        }
    }

    changeOption(option){
        this.setState({
            option_one: option === 'one' ? !this.state.option_one : false,
            option_two: option === 'two' ? !this.state.option_two : false,
            option_three: option === 'three' ? !this.state.option_three : false,
            option_four: option === 'four' ? !this.state.option_four : false,
        })
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleContactOpen = () => {
        this.setState({ open: true });
    };

    render(){
        return(
            <React.Fragment>
                <Grid container spacing={0}>
                    <img src={ContactUS} alt='inspected-seal' className='inspected-seal-img' />
                </Grid>
                <Grid container spacing={0} className='about-us-panel-container'>
                    <p className='contact-us-paragraph-title'>
                        La maquinaria pesada es la principal herramienta técnica para alcanzar los objetivos de su negocio
                        dentro del sector de construcción, minería, petróleos, forestal o agroindustrial, sin embargo, para
                        nosotros, en “TODO EN MAQUINARIA PESADA” es nuestro único sentido en ayudarlos y
                        encuentren un mayor costo beneficio en estos activos, Por esto contamos con la siguiente
                        solución.
                    </p>

                    <ExpansionPanel onClick={this.changeOption.bind(this, "one")} expanded={this.state.option_one}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography><strong>¿Quiénes Somos?</strong></Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <p><strong>TMP</strong>, somos especialistas en el suministro de soluciones integrales necesarias para todo tipo de maquinaria amarilla y plantas eléctricas, dedicada a minería, construcción, petróleos, forestal, agroindustrial y demás sectores económicos en Latinoamérica y el Caribe, que utilizan la maquinaria como herramienta principal para el cumplimiento de sus objetivos de sus proyectos.</p>
                                <p><strong>TMP</strong> contamos con un talento humano altamente calificado, profesional y con experiencia, así como los recursos técnicos y tecnológicos, logrando de esta manera la confianza para nuestros clientes y generando una fuerte alianza estratégica.</p>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel onClick={this.changeOption.bind(this, "two")} expanded={this.state.option_two}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography><strong>Nuestro Compromiso</strong></Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <p>Somos un grupo de profesionales íntegros, responsables, capacitados y expertos en la inspección, peritaje y evaluación de maquinaria pesada con principios éticos y morales, que busca el bienestar y confianza de todos sus clientes. </p>

                                <p>Nuestro cliente siempre obtendrá una inspección imparcial, donde quedará evidenciado por medio de herramientas de medición, tecnológicas, fotos y videos el estado real y actual de la máquina y la vida útil de los diferentes elementos de desgaste, dando la tranquilidad y seguridad al comprador y al propietario de la misma, además ofreciendo servicios de Venta y Renta de maquinaria pesada, partes y Servicio de mantenimiento para este tipo de máquinas.</p>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel onClick={this.changeOption.bind(this, "three")} expanded={this.state.option_three}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography><strong>Misión</strong></Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <p>Suministramos soluciones integrales y eficientes para el funcionamiento, rendimiento óptimo de maquinaria pesada en sus proyectos; obteniendo un mayor beneficio a un costo bajo con el soporte de nuestro talento humano, recursos técnicos y tecnológicos.</p>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel onClick={this.changeOption.bind(this, "four")} expanded={this.state.option_four}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography><strong>Visión</strong></Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                <p>En el año 2025 <strong>TMP</strong> será reconocida como una empresa líder a nivel latinoamericano y del Caribe en la prestación de soluciones integrales, oportunas y confiables para necesidades relacionadas con maquinaria de línea amarilla.</p>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <Grid container spacing={0} justify='center'>
                        <Grid item xs={6} className='expertise-panel'>
                            <Link to='/contactanos' className='button-link'>
                                <Button className="panelButton"><SVGButton className='svgButton'/>  Cotizar</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} className='expertise-panel'>
                            <Button className="panelButton" onClick={this.handleContactOpen}><SVGButton className='svgButton'/>  Contáctanos</Button>
                        </Grid>
                    </Grid>

                </Grid>

                <ContactDialog
                    open={this.state.open}
                    onClose={this.handleClose}
                />


            </React.Fragment>
        )
    }
}

export default AboutUSComponent;