import React, { Component, Fragment } from 'react';
import Header from '../../components/Header/Header';
import InsuranceComponent from "../../components/InsuranceComponent/InsuranceComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";

class Insurance extends Component{
    render(){
        return(
            <Fragment>
                <Header/>
                <InsuranceComponent/>
                <FooterComponent/>
            </Fragment>
        );
    }
}

export default Insurance;