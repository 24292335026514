import React, {useState } from 'react';
import Header from '../../components/Header/Header';
import NewInsuranceComponent from "../../components/InsuranceComponent/NewInsuranceComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";

import queryString from "query-string";


const NewInsuranceResume = ({location}) =>{
    const [data] = useState(queryString.parse(location.search));

    return(
        <>
            <Header/>
            <NewInsuranceComponent type={data.typeId}/>
            <FooterComponent/>
        </>
    );
}

export default NewInsuranceResume;