import React, { Component, Fragment } from 'react';
import './Providers.css';
import ProvidersList from "../../components/ProvidersList/ProvidersList";


class Providers extends Component{
    render(){
        return(
            <Fragment>
                <ProvidersList/>
            </Fragment>
        );
    }
}

export default Providers;