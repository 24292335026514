import React, { Component, Fragment } from 'react';
import './ProviderTypesComponent.css';
import {Grid, Paper, Typography, AppBar, Tabs, Tab, TextField, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import {
    SelectionState,
    PagingState,
    IntegratedPaging,
    SearchState,
    IntegratedFiltering,

} from '@devexpress/dx-react-grid';
import {
    Grid as GridTable,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel
} from '@devexpress/dx-react-grid-material-ui';


import axios from "axios";
import {API_URL} from "../../API";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiInput: {
            root:{
                width: "100%",
                "&$focused": {
                    '&:after': {
                        borderBottom: "2px solid #000000 !important",
                        width: "100%"
                    }
                },
            },

        }
    }
});

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#000000',
    },
    tabsRoot: {
        borderBottom: '1px solid #000000',
    },
    tabsIndicator: {
        backgroundColor: '#ff9b30',
        height: "4px",
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        fontFamily: "\"machineFont\" !important",
        fontSize: "18px",
        '&:hover': {
            color: '#ff9b30',
            opacity: 5,
        },
        '&:focus': {
            color: '#ff9b30',
        },
    },
    tabSelected: {
        color: '#ff9b30',
    },
    typography: {
        padding: theme.spacing.unit * 3,
    },
    input: {
        backgroundColor: "blue",
    }
});

class ProviderTypesComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            value: 0,
            list: [],
            columns: [
                { name: 'id', title: 'Id' },
                { name: 'provider_type_name', title: 'Tipo proveedor' },
                { name: 'provider_type_description', title: 'Pequeña descripción' },
                { name: 'actions', title: 'Acciones' },
            ],

            providerTypeName: '',
            errorProviderTypeName: '',

            providerTypeDescription: '',
            errorProviderTypeDescription: '',


        };

        this.changeSelection = selection => this.setState({ selection });
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this._updateList();
    };

    _updateList(){
        axios.get(API_URL+'/providersTypes').then((response) => {


            let providers_types = response.data.map(providers_type => ({
                id: providers_type.id,
                provider_type_name: providers_type.provider_type_name,
                provider_type_description: providers_type.provider_type_description,
                actions: this.addActions.call(this,  providers_type.id ),
            }));
            this.setState({
                list: providers_types
            })
        });
    }

    addActions = (id) => {
        return (
            <div>
                <Button

                    color="primary"
                    onClick={this.updateAction.bind(this, id)}
                >
                    Editar
                </Button>

                <Button

                    color="primary"
                    onClick={this.deleteAction.bind(this, id)}
                >
                    Eliminar
                </Button>
            </div>
        );
    };

    updateAction(id) {
        alert("Proximamente editando: " + id)
    }

    deleteAction(id) {
        alert("Proximamente eliminando: " + id)
    }

    _create(){

        let formData = {
            provider_type_name: this.state.providerTypeName,
            provider_type_description: this.state.providerTypeDescription,
        };

        axios.post(API_URL+'/providersTypes', formData).then((response) => {
            this.setState({
                providerTypeName: "",
                providerTypeDescription: "",
            })
            this._updateList();
        })
    }

    handleTabChange = (event, value) => {
        this.setState({ value });
    };

    handleChange(event) {
        this.setState({[event.target.name]:event.target.value});
    }

    render() {
        const { classes } = this.props;
        return (

            <Fragment>
                <MuiThemeProvider theme={theme}>
                    <Grid container className={["mainContainer","yellowBackground"].join(" ")}>
                        <Grid item xs={12} className="mainRow">
                            <Paper className="mainPaperCotizar">

                                <Typography variant="h3" className="cotizarTitle">Parametrización Tipos Proveedores</Typography>

                                <AppBar position="static" className={classes.root}>
                                    <Tabs variant="fullWidth" value={this.state.value} onChange={this.handleTabChange} classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                                        <Tab label="Listado Tipos de Proveedor" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}/>
                                        <Tab label="Crear Tipo de Proveedor" classes={{ root: classes.tabRoot, selected: classes.tabSelected }}  />
                                    </Tabs>
                                </AppBar>

                                {this.state.value === 0 && <TabContainer>
                                    <GridTable
                                        rows={this.state.list}
                                        columns={this.state.columns}
                                    >
                                        <PagingState
                                            defaultCurrentPage={0}
                                            pageSize={10}
                                        />
                                        <SelectionState
                                            selection={this.state.selection}
                                            onSelectionChange={this.changeSelection}
                                        />
                                        <SearchState />
                                        <IntegratedFiltering />
                                        <IntegratedPaging />
                                        <Table />
                                        <TableHeaderRow />
                                        <Toolbar />
                                        <SearchPanel
                                            messages={{searchPlaceholder: 'Buscar...'}}
                                            InputProps={{
                                                className: classes.input,
                                            }}
                                        />
                                        <PagingPanel />
                                    </GridTable>
                                </TabContainer>}
                                {this.state.value === 1 &&
                                <Fragment>
                                    <Grid container className={["mainContainer","yellowBackground"].join(" ")}>
                                        <Grid item xs={12} className="mainRow">
                                            <Paper className="mainPaperCotizar">

                                                <Typography variant="h3" className="cotizarTitle">Crear Tipo de Proveedor</Typography>

                                                <form autoComplete="off">
                                                    <Grid container spacing={24} className="cotizarFormContainer">

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                id="outlined-name"
                                                                name="providerTypeName"
                                                                label="Tipo de proveedor"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.providerTypeName}
                                                                onChange={this.handleChange}
                                                                error ={this.state.errorProviderTypeName.length !== 0 }
                                                                helperText={this.state.errorProviderTypeName}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                id="outlined-name"
                                                                name="providerTypeDescription"
                                                                label="Pequeña Descripción"
                                                                margin="normal"
                                                                variant="outlined"
                                                                value={this.state.providerTypeDescription}
                                                                onChange={this.handleChange}
                                                                error ={this.state.errorProviderTypeDescription.length !== 0 }
                                                                helperText={this.state.errorProviderTypeDescription}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.cssLabel,
                                                                        focused: classes.cssFocused,
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.cssOutlinedInput,
                                                                        focused: classes.cssFocused,
                                                                        notchedOutline: classes.notchedOutline,
                                                                    },
                                                                    className: classes.cssFilled,
                                                                }}

                                                            />
                                                        </Grid>




                                                        <Grid container className="cotizacionButtonContainer">
                                                            <Grid item xs={12} md={12} className="cotizacionButtonContainer">
                                                                <Button onClick={this._create.bind(this)} className="cotizacionPanelButton"><FontAwesomeIcon icon={faCog}/> Agregar Tipo Proveedor</Button>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </form>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            </Fragment>
        );
    }
}

export default withStyles(styles)(ProviderTypesComponent);





