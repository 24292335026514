import React from 'react';
import {Typography, Button} from '@material-ui/core';
import Header from '../../components/Header/Header';
import SellingComponent from '../../components/SellingComponent/SellingComponent';
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import SellingCategoriesComponent from '../../components/SellingCategoriesComponent/SellingCategoriesComponent';
import SellingSubcategoriesComponent from '../../components/SellingSubcategoriesComponent/SellingSubcategoriesComponent';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import useWindowDimensions from '../../components/Hooks/useWindowDimensions';

const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
        color: 'black',
        fontFamily: 'BebasFont, serif !important',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    breadcrumbOl:{
        justifyContent: 'center',
        height: '60px',
        background: '#ff9b30'
    },
    categoriesTitle:{
        textAlign: 'center',
        color: 'white',
    },
    categoriesTitleButton:{
        textAlign: 'center',
    },
    categoriesTitleButtonText: {
        fontWeight: 'bold',
    },
    categoriesTitleContainer:{
        padding: '1rem',
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'space-evenly',
    }
}));

const Selling = ({location}) => {

    const classes = useStyles();
    const urlQuery = queryString.parse(location.search);
    const { width } = useWindowDimensions();

    return(
        <>
            <Header/>
            <Breadcrumbs 
                aria-label="breadcrumb" 
                classes={{
                    ol: classes.breadcrumbOl,
                }}
            >
                <Link className={classes.link} to="/ventas">
                    Venta de Maquinaria
                </Link>
                
                {urlQuery.category_name && 
                    <Link className={classes.link} to={"/ventas?category_id=" + urlQuery.category_id + '&category_name=' + urlQuery.category_name}>
                        {urlQuery.category_name}
                    </Link>
                }

                {urlQuery.subcategory_name && 
                    <Link className={classes.link} to={"/ventas?category_id=" + urlQuery.category_id + '&category_name=' + urlQuery.category_name + "&subcategory_id=" + urlQuery.subcategory_id + '&subcategory_name=' + urlQuery.subcategory_name}>
                        {urlQuery.subcategory_name}
                    </Link>
                }
  
            </Breadcrumbs>
            <div className={classes.categoriesTitleContainer}>
                <Typography variant="h5" component="p" className={["generalFont", classes.categoriesTitle].join(" ")}>DESEAS COMPRAR! ENCUENTRA TU MÁQUINA</Typography>
                <Link to={"/publicar"} className={classes.categoriesTitleButton}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.categoriesTitleButtonText}
                    >
                        HAZ CLIC AQUÍ PARA VENDER
                    </Button>
                </Link>
            </div>
            {!urlQuery.category_id && !urlQuery.subcategory_id && <SellingCategoriesComponent/>}
            {urlQuery.category_id && !urlQuery.subcategory_id && <SellingSubcategoriesComponent category_id={urlQuery.category_id} category_name={urlQuery.category_name}/>}
            {urlQuery.category_id && urlQuery.subcategory_id && <SellingComponent subcategory_id={urlQuery.subcategory_id} windowsWitdh={width}/>}
            <FooterComponent/>
        </>
    );
}

export default Selling;