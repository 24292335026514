import React from 'react';
import SellRentItemDetailsComponent from '../../components/SellRentItemDetailsComponent/SellRentItemDetailsComponent'
import queryString from 'query-string';
import Header from "../../components/Header/Header";
import FooterComponent from "../../components/FooterComponent/FooterComponent";

class SellRentItemDetails extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Header/>
                <SellRentItemDetailsComponent location={this.props.location} url_id={queryString.parse(this.props.location.search)}/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default SellRentItemDetails;