import React, { Component, Fragment } from 'react';
import Header from '../../components/Header/Header';
import ExpertiseComponent from "../../components/ExpertiseComponent/ExpertiseComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";

class Peritaje extends Component{
    render(){
        return(
            <Fragment>
                <Header/>
                <ExpertiseComponent/>
                <FooterComponent/>
            </Fragment>
        );
    }
}

export default Peritaje;