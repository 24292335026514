import React from 'react';
import {Grid} from '@material-ui/core';
import SlideOptionOne from "../../images/seguros.jpg";
import cumpliento from "../../images/poliza_cumplimiento.jpg";
import maquinaria from "../../images/poliza_maquinaria.jpg";
import personal from "../../images/poliza_personal.jpg";
import Card from "../shared/card/Card";
import './InsuranceComponent.css';

const cards = [
    {
        "link": "/seguro?InsuranceId=1",
        "image": personal,
        "title": "Beneficios póliza Personal",
        "description": "Es un seguro que ofrece protección ante cualquier accidente, entendido este como: el suceso imprevisto, repentino, violento, externo, ajeno a la voluntad, que produzca la muerte o lesiones corporales al asegurado.",
    },
    {
        "link": "/seguro?InsuranceId=2",
        "image": maquinaria,
        "title": "Beneficios póliza todo riesgo Maquinaria",
        "description": "Es un seguro que ofrece protección contra los daños y pérdidas materiales producidas de manera accidental, súbita e imprevista a la maquinaria y equipo Móvil de propiedad de constructores, el interés asegurado.",
    },
    {
        "link": "/seguro?InsuranceId=3",
        "image": cumpliento,
        "title": "Beneficios póliza Cumplimiento",
        "description": "Los seguros de cumplimiento tienen el fin de garantizar el cumplimiento de un contrato de diversa naturaleza. De esta manera, una póliza de cumplimiento cubre los perjuicios ocasionados al contratante en caso de que este contrato no se cumpla.",
    },
];


const InsuranseResume = () => {

    return(
        <Grid container spacing={0}>
            <div className='insurance-component-carousel-container'>
                <img src={SlideOptionOne} alt='slide-1'/>
            </div>
            <Grid item xs={12}>
                <Grid container spacing={0}>
                    {cards.map((data, index) => (
                        <Grid item xs={12} sm={6} md={4} className='insurance-component-card-container'>
                            <Card data={data} key={index} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            
        </Grid>
    );
}


export default InsuranseResume;