import React from 'react';
import './HowToPublish.css';
import TitleLabel from '../primitive/TitleLabel/TitleLabel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const HowToPublish = () => {

    return <div className='how-to-publish-root'>
        <TitleLabel text='¿Cómo publicar mi máquina?' variant='dark' />
        <div className='how-to-publish-body'>
            <div>
                <Link className='how-to-publish-download-pdf' to={{ pathname: "https://tmp-production-bucket.s3.amazonaws.com/assets/es/manual_fotografia.pdf" }} target="_blank" download>
                    <FontAwesomeIcon icon={faFilePdf}/>
                    <span>Manual de fotografía</span>
                </Link>
            </div>
            <div>
                <Link className='how-to-publish-download-pdf' to={{ pathname: "https://www.youtube.com/watch?v=iAPF35wJ5-o" }} target="_blank">
                    <FontAwesomeIcon icon={faYoutube}/>
                    <span>Video manual de fotografía</span>
                </Link>
            </div>
        </div>
        
    </div>
}

export default HowToPublish;