import React, { Fragment } from 'react';
import './AdminLogin.css';
import { AdminLoginController } from './AdminLoginController';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {Link} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Logo from "../../images/logo.png";
import Loading from '../LoadingComponent/Loading';

const AdminLogin = (props) => {

    const controllerProps = AdminLoginController({
        urlParams: props.urlParams,
    });

    const {
        email,
        setEmail,
        password,
        setPassword,
        errors,
        showPassword,
        setShowPassword,
        doingLogin,
        doLogin,
    } = controllerProps;

    return(
        <Fragment>
            <Loading loadingImage={Logo} loadingText={'Iniciando sesión'} loadingState={doingLogin}/>
            <Container component="main" maxWidth="sm">
                <div className='paper'>
                    <Grid container spacing={2}>

                        <Grid item xs={12} className='logoContainer'>
                            <img src={Logo} alt="logo" className='logo'/>
                        </Grid>

                        <div className='loginType'>ADMINISTRADOR</div>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Correo"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            { errors.email !== '' && <span className='error'>{errors.email}</span> }
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    'aria-label': 'bare',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(true)}
                                                onMouseDown={() => setShowPassword(false)}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            { errors.password !== '' && <span className='error'>{ errors.password }</span> }
                        </Grid>

                        <Grid container justify="center">
                            <Grid item>
                                <br/>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            { errors.login !== '' && <span className='error'>{ errors.login }</span> }
                        </Grid>

                        <Grid container justify="center">
                            <Grid item>
                                <br/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className='submit'
                                onClick={() => doLogin()}
                                disabled={doingLogin}
                            >
                                { doingLogin ? <CircularProgress className='progress' color="primary" /> : 'INICIAR SESIÓN'}
                            </Button>
                        </Grid>
                        <Grid container justify="center">
                            <Grid item>
                                <Link to="/password-recovery" className='link'>
                                    Olvidaste tu contraseña ?
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container justify="center">
                            <Grid item>
                                <br/>
                            </Grid>
                        </Grid>

                    </Grid>
                </div>
            </Container>
        </Fragment>
    )
}

export default AdminLogin