import React, { Component, Fragment } from 'react';
import Header from '../../components/Header/Header';
import PasswordResetComponent from "../../components/PasswordResetComponent/PasswordResetComponent.js";
import FooterComponent from "../../components/FooterComponent/FooterComponent";


class PasswordReset extends Component{
    render(){
        return(
            <Fragment>
                <Header/>
                <PasswordResetComponent url={this.props.location.search}/>
                <FooterComponent/>
            </Fragment>
        );
    }
}

export default PasswordReset;