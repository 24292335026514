import React, { Component, Fragment } from 'react';
import './ExpertiseComponent.css';
import {Button, Grid} from '@material-ui/core';
import ContactDialog from '../ContactDialog/ContactDialog';
import {Link} from "react-router-dom";
import InspectedSeal from "../../images/inspected.png";
import SlideOptionOne from "../../images/slide_option_one.jpg";
import SlideOptionTwo from "../../images/slide_option_two.jpg";
import SlideOptionThree from "../../images/slide_option_three.jpg";
import SlideOptionFour from "../../images/slide_option_four.jpg";
import Map from "../../images/map.jpg";
import { ReactComponent as SVGButton } from "../../images/logo.svg";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Slider from "react-slick";


class ExpertiseComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            open: false,
            option_one: false,
            option_two: false,
            option_three: false,
            option_four: false,
            option_five: false,
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleContactOpen = () => {
        this.setState({ open: true });
    };

    changeOption(option){
        this.setState({
            option_one: option === 'one' ? !this.state.option_one : false,
            option_two: option === 'two' ? !this.state.option_two : false,
            option_three: option === 'three' ? !this.state.option_three : false,
            option_four: option === 'four' ? !this.state.option_four : false,
            option_five: option === 'five' ? !this.state.option_five : false,
        })
    }


    render(){

        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            cssEase: "linear"
        };

        return(
            <Fragment>

                <Grid container spacing={0}>
                    <div className='home-carousel-card-container'>
                        <Slider {...settings}>
                        <div>
                            <img src={SlideOptionOne} alt='slide-1'/>
                        </div>
                        <div>
                            <img src={SlideOptionTwo} alt='slide-2'/>
                        </div>
                        <div>
                            <img src={SlideOptionThree} alt='slide-3'/>
                        </div>
                        <div>
                            <img src={SlideOptionFour} alt='slide-4'/>
                        </div>

                    </Slider>
                    </div>

                    <Grid item xs={12} sm={6} className='inspected-seal-img-container'>
                        <img src={InspectedSeal} alt='inspected-seal' className='inspected-seal-img-panel' />

                        <Grid container spacing={0} justify='center'>
                            <Grid item xs={10} className='expertise-panel'>
                                <Link to='/contactanos' className='button-link'>
                                    <Button className="panelButton"><SVGButton className='svgButton'/>  Cotizar</Button>
                                </Link>
                            </Grid>
                            <Grid item xs={10} className='expertise-panel'>
                                <Button className="panelButton" onClick={this.handleContactOpen}><SVGButton className='svgButton'/>  Contáctanos</Button>
                            </Grid>
                        </Grid>

                    </Grid>



                    <Grid item xs={12} sm={6} className='expertise-panel-options'>
                        <ExpansionPanel onClick={this.changeOption.bind(this, "one")} expanded={this.state.option_one}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography><strong>¿Necesita un peritaje?</strong></Typography>
                            </ExpansionPanelSummary>
                            { this.state.option_one && <React.Fragment><img src={SlideOptionOne} alt='inspected-seal' className='inspected-seal-img' /></React.Fragment> }
                            <ExpansionPanelDetails>
                                <Typography>
                                    <p>Sabemos que cuando usted desea adquirir una maquina pesada usada, su principal temor es el
                                        estado real de la misma y el tener que invertir en costosas reparaciones futuras. Nuestro equipo
                                        de profesionales está capacitado y cuenta con la experiencia para determinar el estado real de la
                                        maquina en sus sistemas hidráulicos, mecánicos, eléctricos y electrónicos para detectar por medio
                                        de un peritaje elaborado y estructurado cualquier falla actual o futura y la vida útil de los
                                        elementos de desgaste.</p>

                                    <p>TMP somos una empresa especializada en inspecciones y peritaje de maquinaria pesada, de línea
                                        amarilla y agrícola, usada para los sectores de construcción, minería, agroindustrial, petróleos,
                                        forestal, entre otros. Nuestra casa matriz se encuentra en Colombia, sin embargo, contamos con
                                        una red de Ingenieros y técnicos que se desplazan en todo el continente americano los cuales se
                                        encargan de la inspección, evaluación y diagnóstico de la maquinaria.</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel onClick={this.changeOption.bind(this, "two")} expanded={this.state.option_two}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography><strong>¿Por qué confiar en TMP?</strong></Typography>
                            </ExpansionPanelSummary>
                            { this.state.option_two && <React.Fragment><img src={SlideOptionTwo} alt='inspected-seal' className='inspected-seal-img' /></React.Fragment> }
                            <ExpansionPanelDetails>
                                <Typography>
                                    <p>Somos un grupo de profesionales íntegros, responsables, capacitados y expertos en la inspección,
                                        peritaje y evaluación de maquinaria pesada con principios éticos y morales, que busca el bienestar
                                        y confianza de todos sus clientes.</p>

                                    <p>Nuestro cliente siempre obtendrá una inspección imparcial, donde quedará evidenciado por
                                        medio de herramientas de medición, tecnológicas, fotos y videos el estado real y actual de la
                                        máquina y la vida útil de los diferentes elementos de desgaste, dando la tranquilidad y seguridad
                                        al comprador y al propietario de la misma.</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel onClick={this.changeOption.bind(this, "three")} expanded={this.state.option_three}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography><strong>Nuestros recursos</strong></Typography>
                            </ExpansionPanelSummary>
                            { this.state.option_three && <React.Fragment><img src={SlideOptionThree} alt='inspected-seal' className='inspected-seal-img' /></React.Fragment> }
                            <ExpansionPanelDetails>
                                <Typography>
                                    <p>1. En TMP Contamos con un equipo profesional y calificado que cuenta con una amplia experiencia
                                        en el sector de mantenimiento y diagnóstico de maquinaria.</p>

                                    <p>2. TMP dispone de toda la herramienta de medición necesaria para realizar las inspecciones, así,
                                        como los software y manuales de cada fabricante de la industria para comparar los diferentes
                                        valores obtenidos de las mediciones de las maquinas con los parámetros dados por los fabricantes.</p>

                                    <p>3. En TMP trabajamos para brindarte lo mejor, por eso hemos desarrollado un Software propio
                                        que permite cuantificar y cualificar el estado verdadero de la máquina para ser suministrado al
                                        cliente en tiempo real.</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel onClick={this.changeOption.bind(this, "four")} expanded={this.state.option_four}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4a-content"
                                id="panel4a-header"
                            >
                                <Typography><strong>Más detalles</strong></Typography>
                            </ExpansionPanelSummary>
                            { this.state.option_four && <React.Fragment><img src={SlideOptionFour} alt='inspected-seal' className='inspected-seal-img' /></React.Fragment> }
                            <ExpansionPanelDetails>
                                <Typography>
                                    <p>Los profesionales de TMP evaluaran de manera técnica y tecnológica por medio de las
                                        correspondientes herramientas de medición y diagnóstico los diferentes componentes hidráulicos,
                                        mecánicos, eléctricos y electrónicos.</p>

                                    <p>Realizamos las inspecciones en la cabina y todos sus elementos; en el chasis y demás partes
                                        estructurales; en el motor y todos sus componentes; en los sistemas hidráulicos incluyendo
                                        bombas, controles de válvulas, mangueras, válvulas, motores de traslación, actuadores, cilindros,
                                        servo transmisiones y demás componentes hidráulicos. En elementos de traslación tales como
                                        trenes de rodaje o llantas; en herramientas como cucharones, baldes, palas, martillos, ganchos,
                                        dragas, rodillos, horquillas, entre otros, y en sí todos los componentes de excavadoras, cargadores,
                                        retrocargadores, minicargadores, vibrocompactadores, motoniveladoras, bulldozers, tractores,
                                        grúas, plantas eléctricas, recicladores, en las diferentes marcas que se encuentran en el mercado.
                                        Podemos presupuestarle el coste de cualquier reparación de alguno de sus equipos.</p>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel onClick={this.changeOption.bind(this, "five")} expanded={this.state.option_five}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel5a-content"
                                id="panel5a-header"
                            >
                                <Typography><strong>¿Dónde trabajamos para usted?</strong></Typography>
                            </ExpansionPanelSummary>

                            <ExpansionPanelDetails>
                                <Typography>
                                    <p>Los profesionales de TMP están ubicados en Colombia, pero podrán desplazarse a los países de
                                        América Central, América del Sur y el Caribe.</p>
                                </Typography>
                            </ExpansionPanelDetails>
                            <div className='map-img-container'><img src={Map} alt='inspected-seal' className='map-img' /></div>

                        </ExpansionPanel>
                    </Grid>
                </Grid>

                <ContactDialog
                    open={this.state.open}
                    onClose={this.handleClose}
                />

            </Fragment>
        );
    }
}

export default ExpertiseComponent;