import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SellRentGalleryItemImages from "../SellRentGalleryItemImages/SellRentGalleryItemImages";
import NumberFormat from "react-number-format";
import {Link} from "react-router-dom";
import StarsIcon from '@material-ui/icons/Stars';

const styles = theme => ({
    card: {
        /*maxWidth: 400,*/
        borderRadius: '0px',
    },
    actions: {
        display: 'flex',
    },
    link: {
        color: '#000000',
    },
    postNumber: {
        fontWeight: "bold",
    },
    outstandingTag: {
        textAlign: 'center',
        border: 'solid 2px',
        borderRadius: '1rem',
        padding: '5px',
        backgroundColor: '#000000',
        color: '#ffffff',
        display: 'grid',
        gridTemplateColumns: '4fr 1fr',
        alignItems: 'center',
        justifyItems: 'center',
    },
    outstandingIcon: {
        color: '#ff9b01',
        fontSize: '2rem',
    }
});

class SellRentGalleryItem extends React.Component {

    state = { expanded: false };

    render() {
        const { classes } = this.props;
        console.log(this.props)
        return (

                <Card className={classes.card}>
                    <SellRentGalleryItemImages data={this.props.data.images} id={this.props.data.id} single={false} status={this.props.data.status} variant="normal"/>
                    <Link to={"/ventas/detalles-maquina?maquinaId="+this.props.data.id} className={classes.link}>
                        <CardContent className='card-product-content'>
                            <Typography variant="h6" className='galleryPriceStyle'>
                                <NumberFormat
                                    value={this.props.data.sale_price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$ '}
                                    suffix={' ' + this.props.data.currency}
                                />
                            </Typography>
                            <Typography variant="subtitle2">
                                { `${this.props.data.brand.name ? this.props.data.brand.name : this.props.data.brand} | ${this.props.data.model} | ${this.props.data.year}`}
                                <NumberFormat
                                    value={this.props.data.horometer}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={' | '}
                                    suffix={' Horas'}
                                />
                            </Typography>
                            
                            {
                                this.props.data.is_outstanding ?
                                <Grid container spacing={0} justify='center' alignItems='center' >
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography variant="subtitle2">
                                            {this.props.data.location_city + ", " + this.props.data.location_country }
                                        </Typography>
                                        <strong>
                                            <Typography variant="subtitle2" className={classes.postNumber}>
                                                Id. Publicación #
                                                <NumberFormat
                                                    value={this.props.data.id}
                                                    displayType={'text'}
                                                />
                                            </Typography>
                                        </strong>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <div className={classes.outstandingTag}>
                                            Destacado <StarsIcon className={classes.outstandingIcon} />
                                        </div>
                                    </Grid>
                                </Grid>
                                :
                                <Fragment>
                                    <Typography variant="subtitle2">
                                        {this.props.data.location_city + ", " + this.props.data.location_country }
                                    </Typography>
                                    <strong>
                                        <Typography variant="subtitle2" className={classes.postNumber}>
                                            Id. Publicación #
                                            <NumberFormat
                                                value={this.props.data.id}
                                                displayType={'text'}
                                            />
                                        </Typography>
                                    </strong>
                                </Fragment>
                            }

                            
                        </CardContent>
                    </Link>
                    {/*
                    <Divider/>
                    <CardActions className={classes.actions} disableActionSpacing>
                        <Grid container spacing={0}>

                                <Grid item xs={6} className='card-product-action-button'>
                                    <IconButton aria-label="Add to favorites">
                                        <FavoriteIcon />
                                    </IconButton>
                                </Grid>

                            <Grid item xs={12}>
                                <IconButton aria-label="Share">
                                    <ShareIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </CardActions>
                    */}
                </Card>
        );
    }
}

export default withStyles(styles)(SellRentGalleryItem);