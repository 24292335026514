import React, { useState, useEffect, Fragment } from 'react';
import {
  Typography,
  Container,
  Grid,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import StarIcon from '@material-ui/icons/StarBorder';
import PayUPaymentFormComponent from '../PayUPaymentFormComponent/PayUPaymentFormComponent';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import axios from 'axios';
import { GET_PACKAGES } from "../../API";
import { useAuthUser } from '../Hooks';
import Swal from "sweetalert2";
import { Strings, Colors } from "../../utils/constants";
import './styles.css';
import { message_responses } from "../../MESSAGE_RESPONSES";
import Logo from '../../images/logo.svg';
import Loading from '../LoadingComponent/Loading';

export const Pricing = ({
  machineId,
  variant = ''
}) => {
  const { authUser } = useAuthUser();
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPackages();
  }, [])

  const getPackages = () => {
    let errorMessage;
    setLoading(true)
    axios.get(GET_PACKAGES).then((response) => {
      setLoading(false)
      if (response.data.success) {
        setPackages(response.data.data)
      } else {
        errorMessage = message_responses[response.data.message];
      }
    }).catch((error) => {
      setLoading(false)
      if (error.response && error.response.data) {
        errorMessage = message_responses[error.response.data.message];
      } else {
        errorMessage = Strings.GENERAL_ERROR;
      }
      Swal.fire({
        title: 'Oops...',
        text: errorMessage,
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        confirmButtonColor: Colors.SUCCESS_MAIN,
      });
    })
  }

  return <Fragment>
    <Loading loadingImage={Logo} loadingText={'Cargando paquetes.'} loadingState={loading} />
    <Container maxWidth="lg">
      <Grid container spacing={5}>
        <Typography variant="h3" className='subtitle'>
          <Tooltip title="Los datos suministrados en esta sección serán públicos y visibles una vez tu publicación sea aprobada, esto con el fin de que los compradores interesados en tu maquinaria puedan contactarte para concretar la venta de tu maquinaria.">
            <HelpIcon color="primary" />
          </Tooltip>
          &nbsp;Ofertas :
        </Typography>

        {packages.map((package_, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card className={package_.status === 'coming_soon' ? 'ribbonContainerDisable' : 'ribbonContainer'}>
              {package_.status === 'coming_soon' && <div className='cornerRibbon'>PROXIMAMENTE</div>}
              <CardHeader
                title={package_.name}
                subheader={package_.subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                action={package_.title === 'BÁSICO ORO' ? <StarIcon /> : null}
                className={package_.status === 'coming_soon' ? 'cardHeaderDisabled' : 'cardHeader'}
              />
              <CardContent>
                <div className='cardPricing'>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    ${package_.price}
                  </Typography>
                </div>
                <ul>
                  {package_.products.map((product, index) => (
                    <Typography component="div" variant="subtitle1" align="center" key={index} className='cardItems'>
                      {product.is_include ? <CheckIcon className='checkIcon' /> : <CloseIcon className='closeIcon' />}
                      {product.quantity > 0 && product.quantity} {product.description}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              {authUser && variant !== 'only-info' && machineId &&
                <PayUPaymentFormComponent
                  packageName={package_.name}
                  packageId={package_.id}
                  user={authUser}
                  machineId={machineId}
                  amount={package_.price}
                  disabled={package_.status === 'coming_soon'}
                />
              }
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  </Fragment>

}
