import React, { Component, Fragment } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Home from './layouts/Home/Home';
import Cotizacion from './layouts/Cotizacion/Cotizacion';
import NotFound from './layouts/NotFound/NotFound';
import Login from './layouts/Login/Login';
import Cotizaciones from './layouts/Cotizaciones/Cotizaciones';
import Providers from './layouts/Providers/Providers';
import ProviderTypes from './layouts/ProviderTypes/ProviderTypes';
import Brands from './layouts/Brands/Brands';
import Countries from './layouts/Countries/Countries';

import UploadImage from "./components/UploadImage/UploadImage";
import Cities from './layouts/Cities/Cities';
import Peritaje from "./layouts/Peritaje/Peritaje";
import ContactUS from "./layouts/ContactUS/ContactUS";
import AboutUS from "./layouts/AboutUS/AboutUS";
import Selling from "./layouts/Selling/Selling";
import Parts from "./layouts/Parts/Parts";
import Maintenance from "./layouts/Maintenance/Maintenance";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SellRentItemDetails from "./layouts/SellRentItemDetails/SellRentItemDetails";
import SellRentNewMachine from "./layouts/SellRentNewMachine/SellRentNewMachine";
import NewMachine from "./layouts/NewMachine/NewMachine";
import EditMachine from "./layouts/EditMachine/EditMachine";
import SignUp from "./layouts/SignUp/SignUp";
import PasswordRecovery from "./layouts/PasswordRecovery/PasswordRecovery";
import PasswordReset from "./layouts/PasswordReset/PasswordReset";
import Dashboard from "./layouts/Dashboard/Dashboard";
import VerifyEmail from "./layouts/VerifyEmail/VerifyEmail";
import Seguros from "./layouts/Seguros/Seguros";
import InsuranceResume from "./layouts/Seguros/InsuranceResume";
import NewInsuranceResume from "./layouts/Seguros/NewInsurance";

import AdminLogin from './layouts/AdminLogin/AdminLogin';
import AdminDashboard from './layouts/AdminDashboard/AdminDashboard';
import AdminMachines from './layouts/AdminMachines/AdminMachines';
import AdminNewMachine from "./layouts/AdminNewMachine/AdminNewMachine";

import WhatsappFloatButton from "./components/WhatsappFloatButton/WhatsappFloatButton";

const THEME = createMuiTheme({
  typography: {
    "fontFamily": "\"Montserrat\", sans-serif",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  palette: {
    primary: {
      main: '#ff9b01',
    },
    secondary: {
      main: '#000000',
    },
    danger: {
      main: '#dc3545',
    },
    warning: {
      main: '#ffc107',
    }
  },
});

class App extends Component {
  render() {
    return (
        <Fragment>
          <MuiThemeProvider theme={THEME}>
            <Router>
              <ScrollToTop>
              <Switch>
                <Route exact path="/"  component={Home} />
                <Route exact path="/peritaje"  component={Peritaje} />
                <Route exact path="/seguros"  component={Seguros} />
                <Route exact path="/contactanos"  component={ContactUS} />
                <Route exact path="/acerca-de-nosotros"  component={AboutUS} />

                <Route exact path="/ventas"  component={Selling} />
                <Route exact path="/ventas/detalles-maquina"  component={SellRentItemDetails} />
                <Route exact path="/ventas/nueva-publicacion"  component={SellRentNewMachine} />
                <Route exact path="/publicar"  component={NewMachine} />
                <Route exact path="/maquina/editar"  component={EditMachine} />

                <Route exact path="/dashboard"  component={Dashboard} />

                <Route exact path="/partes"  component={Parts} />
                <Route exact path="/mantenimiento"  component={Maintenance} />

                <Route exact path="/cotizar"  component={Cotizacion} />

                <Route exact path="/admin/cotizaciones"  component={Cotizaciones} />
                <Route exact path="/uploadImage"  component={UploadImage} />
                <Route exact path="/admin/proveedores"  component={Providers} />

                <Route exact path="/parametrizar/tipos-proveedor"  component={ProviderTypes} />
                <Route exact path="/parametrizar/marcas"  component={Brands} />
                <Route exact path="/parametrizar/paises"  component={Countries} />
                <Route exact path="/parametrizar/ciudades"  component={Cities} />

                <Route exact path="/login"  component={Login} />
                <Route exact path="/sign-up"  component={SignUp} />AdminNewMachine
                <Route exact path="/password-recovery"  component={PasswordRecovery} />
                <Route exact path="/password-reset"  component={PasswordReset} />
                <Route exact path="/verify-email"  component={VerifyEmail} />
                <Route exact path="/seguro"  component={InsuranceResume} />
                <Route exact path="/new-insurance"  component={NewInsuranceResume} />

                <Route exact path="/admin"  component={AdminLogin} />
                <Route exact path="/admin/dashboard" component={AdminDashboard} />
                <Route exact path="/admin/dashboard/machines-list" component={AdminMachines} />
                <Route exact path="/admin/dashboard/new-machine" component={AdminNewMachine} />
                
                

                <Route component={NotFound} />
              </Switch>
              </ScrollToTop>
              <WhatsappFloatButton/>
            </Router>
          </MuiThemeProvider>
        </Fragment>
    );
  }
}

export default App;
