import React from 'react';
import Header from "../../components/Header/Header";
import ContactUSComponent from "../../components/ContactUSComponent/ContactUSComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import {Redirect} from "react-router-dom";

class ContactUS extends React.Component{
    redirectToHome = () => {
        return <Redirect push to='/' />
    }
    render(){
        return(
            <React.Fragment>
                {this.redirectToHome()}
                <Header/>
                <ContactUSComponent/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default ContactUS;