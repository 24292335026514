import React from 'react';
import Header from '../../components/Header/Header';
import DashboardComponent from "../../components/DashboardComponent/DashboardComponent.js";


const Dashboard = () => {
    return(
        <>
            <Header/>
            <DashboardComponent/>
        </>
    );
}

export default Dashboard;