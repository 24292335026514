import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import { DELETE_MACHINE, API_URL, MARK_MACHINE_AS_SOLD } from '../../API'; 
import { message_responses } from '../../MESSAGE_RESPONSES'; 
import Chip from '@material-ui/core/Chip';
import {Link} from "react-router-dom";
import axios from 'axios';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  cardButton: {
      margin: '5px'
  },
  cardChip: {
    margin: '5px'
  },
    progress: {
        color: '#ff9b01',
    },
    cardContainer: {
        margin: '20px 0px',
    }
});




const MyPostComponent = (props) => {
    const classes = useStyles();
    const { machine } = props;


    const [deletingItem, setDeletingItem] = useState(false);
    const [changingToSold, setChangingToSold] = useState(false);

    const doDeleteMachine = (id) => {

        if(window.confirm("Esta es una operación destructiva, la maquinaria no podrá ser recuperada de ninguna manera, esta seguro de continuar?")){
            axios.delete(API_URL + DELETE_MACHINE + id).then((response) => {
                setDeletingItem(false);
                if(response.data.success){
                    alert("Maquinaria eliminada correctamente. !")
                    machine.getProducts();
                }else{
                    alert(message_responses[response.data.message]);
                }
            }).catch((error) => {
                setDeletingItem(false);
                if(error.response && error.response.data){
                    alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
                }else{
                    alert("Por favor discúlpanos :( , hemos tenido un error eliminando tu maquinaria.")
                }
            })
        }
    }

    const doChangeToSold = (machine_id) => {
        
        if(window.confirm("Esta es una operación irreversible, la maquinaria pasara a estado de vendida y no podra ser revertido de ninguna manera, esta seguro de continuar?")){
            let formData = {
                machine_id
            };
            
            axios.post(API_URL + MARK_MACHINE_AS_SOLD, formData).then((response) => {
                setChangingToSold(false);
                if(response.data.success){
                    alert("Maquinaria marcada como vendida correctamente. !")
                    machine.getProducts();
                }else{
                    alert(message_responses[response.data.message]);
                }
            }).catch((error) => {
                setChangingToSold(false);
                if(error.response && error.response.data){
                    alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
                }else{
                    alert("Por favor discúlpanos :( , hemos tenido un error cambiando el estado de tu maquinaria.")
                }
            })
        }
    }

    return (
        <Grid item xs={12} className={classes.cardContainer}>
            <CardActionArea component="a">
            <Card className={classes.card}>
                <div className={classes.cardDetails}>
                <CardContent>
                    <Typography component="h2" variant="h5">
                        {`Id. publicación: #${machine.id}`}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {`${machine.category} - ${machine.subcategory}`}
                    </Typography>
                    <Chip label={"Pais : " + machine.location_country} className={classes.cardChip} />
                    <Chip label={"Ciudad : " + machine.location_city} className={classes.cardChip} />
                    <Chip label={"Modelo : " + machine.model} className={classes.cardChip} />
                    <div>
                        {
                            machine.status === 'active' &&
                            <>
                                <Link to={"/ventas/detalles-maquina?maquinaId=" + machine.id}>
                                    <Button variant="contained" color="primary" className={classes.cardButton} >
                                        Ver
                                    </Button>
                                </Link>
                                <Link to={"/maquina/editar?maquinaId=" + machine.id}>
                                    <Button variant="contained" color="secondary" className={classes.cardButton}>
                                        Editar
                                    </Button>
                                </Link>
                                <Button variant="contained" className={classes.cardButton} onClick={() => {doDeleteMachine(machine.id)}}>
                                    { deletingItem ? <CircularProgress className={classes.progress} color="danger" /> : 'Eliminar'}
                                </Button>
                                <Button variant="contained" color="secondary" className={classes.cardButton} onClick={() => {doChangeToSold(machine.id)}}>
                                    { changingToSold ? <CircularProgress className={classes.progress} color="danger" /> : 'Marcar como vendida'}
                                </Button>
                            </>
                        }

                        {
                            machine.status === 'sold' &&
                            <Button variant="contained" color="primary" className={classes.cardButton}>
                                Vendida
                            </Button>
                        }
                    </div>
                    Tú anuncio ha sido visitado {machine.visits} veces.
                 </CardContent>
                </div>
                <Hidden xsDown>
                <CardMedia className={classes.cardMedia} image={machine.image} title={machine.imageTitle} />
                </Hidden>
            </Card>
            </CardActionArea>
        </Grid>
    );
}

export default MyPostComponent;