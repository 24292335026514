import React from 'react';
import './TitleLabel.css';

const TitleLabel = props => {

    const {
        onClick,
        text,
        variant,
    } = props;

    const getClassName = (variant) => {
        switch(variant){
            case 'dark':
                return 'titleLabelDark'
            case 'light':
                return 'titleLabelLight'
            default:
                return 'titleLabelDark'
        }
    }

    return (
        <div onClick={onClick} className={`${getClassName(variant)} ${onClick ? 'titleLabelLink' : ''}`}>
            {text}
        </div>
    )

}

export default TitleLabel;