import React, { useState } from 'react';
import {Grid,Button} from '@material-ui/core';
import SlideOptionOne from "../../images/seguros.jpg";
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cumpliento from "../../images/poliza_cumplimiento.jpg";
import maquinaria from "../../images/poliza_maquinaria.jpg";
import personal from "../../images/poliza_personal.jpg";
import {Redirect} from "react-router-dom";

const useStyles = makeStyles(() => ({
  content:{
    margin: "20px 0px 0 150px",
    "@media (max-width:600px)": {
      margin: "0px",
    },
  },
  image: {
    maxWidth: 400,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  heading: {
    fontSize: "24px",
    fontFamily: "BebasFont, serif !important",
  },
  description: {
    fontSize: "16px",
    fontFamily: "services-text-italic",
  },
  panel: {
    margin: '20px 0px',
  }
}));


const insurances = [
    {   
        "id": "1",
        "active": true, 
        "image": personal,
        "title":"Beneficios póliza Personal",
        "description": "Es un seguro que ofrece protección ante cualquier accidente, entendido este como: el suceso imprevisto, repentino, violento, externo, ajeno a la voluntad, que produzca la muerte o lesiones corporales al asegurado.",
        "benefits": [
          {   
            "title":"Acompañamiento",
            "description":"Es un seguro que ofrece protección a tus colaboradores, frente a los accidentes que puedan sufrir dentro o fuera de las instalaciones de la organización."
          },
          
          { 
            "title":"Apoyo",
            "description":"Nuestro producto está diseñado para apoyarte a ti y a tus colaboradores en diversas eventualidades tales como: muerte accidental, Invalidez parcial o total."},
          { 
            "title":"Respaldo",
            "descriptions":[
              "Consulta médica especializada.",
              "Capacitaciones en manejo de riesgo",
              "Valoración nutricional.",
              "Valoración por psicología y apoyo familiar.",
              "Manejo de terapia física, ocupacional."
              ]
          }
        ]
    },
    {
        "id":"2",
        "active": false, 
        "image":maquinaria,
        "title":"Beneficios póliza todo riesgo Maquinaria",
        "description": "Es un seguro que ofrece protección contra los daños y pérdidas materiales producidas de manera accidental, súbita e imprevista a la maquinaria y equipo Móvil de propiedad de constructores, el interés asegurado.",
        "benefits": [
          { 
            "title":"Acompañamiento",
            "description":"Es un seguro que ofrece protección a tu maquinaria, frente a los accidentes que puedan sufrir dentro o fuera de las instalaciones de la organización."},
          { 
            "title":"Apoyo",
            "description":"Nuestro producto está respaldado por las mejores compañías de seguros del país en el sector de maquinaria"},
          { 
            "title":"Respaldo",
            "description":"Cada póliza es diseñada de acuerdo a tus necesidades especificas que van desde pólizas individuales y colectivas con las coberturas que tú requieres."}
        ]
    },
    {
        "id":"3",
        "active": false,
        "image":cumpliento,
        "title":"Beneficios póliza Cumplimiento",
        "description": "Los seguros de cumplimiento tienen el fin de garantizar el cumplimiento de un contrato de diversa naturaleza. De esta manera, una póliza de cumplimiento cubre los perjuicios ocasionados al contratante en caso de que este contrato no se cumpla.",
        "benefits": [
          { 
            "title":"Acompañamiento",
            "description":"Garantizamos que tus contratos sean ejecutados a cabalidad según su objeto y sus condiciones."},
          { 
            "title":"Apoyo",
            "description":"Es la mejor opción para blindar su patrimonio frente a un contrato, sea como comprador o vendedor y/o cualquiera que sea su figura."},
          { 
            "title":"Respaldo",
            "description":"Agilidad en la emisión de pólizas ya que contamos con el respaldo de más de 20 aseguradoras "}
        ]
    },
];

const InsuranseResume = ({id}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(null);
    const [redirectTo, setRedirect] = useState();

    if (redirectTo) {
      return <Redirect to={redirectTo} push />;
    }

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    
    const result = insurances.filter( insurance => insurance.id === id)[0];

    return(
        <>
            <Grid container spacing={0}>
                <Grid className='home-carousel-card-container'>
                    <img src={SlideOptionOne} alt='slide-1'/>
                </Grid>
                <Grid container spacing={2} className={classes.content}>
                    <Grid item xs={12} sm={3}>
                        <ButtonBase className={classes.image}>
                            <img className={classes.img} alt="complex" src={result.image} />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm={6}container  direction="column">
                        {
                            result.benefits.map((benefit,key) => (
                                <Grid key={key} className={classes.panel}>
                                    <Accordion expanded={expanded === `panel${key+1}`} onChange={handleChange(`panel${key+1}`)} key={key}>
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1bh-content"
                                          id="panel1bh-header"
                                        >
                                        <Typography className={classes.heading}>{benefit.title}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                        {
                                          benefit.descriptions !== undefined ? 
                                            <ul className={classes.description} >
                                              {
                                                benefit.descriptions.map((description,key)=>(
                                                  <li key={key}>{description}</li>
                                                )) 
                                              }
                                            </ul>
                                          :
                                          <p className={classes.description} >
                                              {benefit.description}
                                          </p>
                                        }
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            ))
                        }
                        <Button 
                          disabled={!result.active}
                          onClick={()=>{setRedirect("new-insurance?typeId=5")}}
                          variant="contained" color="primary" disableElevation>
                            { result.active ? 'Cotizar poliza' : 'PROXIMAMENTE'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        

        </>
    );
}


export default InsuranseResume;