const Strings = {
    PAYMENT_MACHINE_SUCCESS_TITLE: 'TRANSACCIÓN EXITOSA',
    PAYMENT_MACHINE_SUCCESS_HTML: (machineId, status, transactionId, referenceCode, pseBank, pseReference1) => `<hr><p style="text-align:justify; line-height: 25px;"><strong>¡¡¡ MUY BIEN !!! Estas a un paso de concretar tu siguiente negocio.</strong> Tu publicación ha sido creada con éxito, revisaremos los datos de tu publicación para asegurarnos de que todo marche correctamente, para que tu próximo negocio sea un éxito. Tu maquinaria sera revisada y posteriormente aceptada, esto te lo haremos saber vía correo electrónico. Nuestro equipo de profesionales de TMP revisará la consistencia de los datos de tu maquinaria y la calidad de las imágenes, en caso de tener algún ajuste necesario para que tu publicación tenga la mejor calidad y consiga el mayor impacto posible, te lo haremos saber vía email o nos comunicaremos contigo telefónicamente, esto con el fin de lograr los mejores resultados con tu publicación.</p><hr>
        <h3> ::: INFORMACIÓN DE PAGO ::: </h3>
        <p><strong>Publicación #:</strong> ${machineId} </p>
        <p><strong>Estado de la transacción:</strong> ${status} </p>
        <p><strong>Transacción #:</strong> ${transactionId} </p>
        ${pseBank ? `<p><strong>Banco #:</strong> ${pseBank} </p>` : ``}
        ${pseReference1 ? `<p><strong>Referencia PSE #:</strong> ${pseReference1} </p>` : ``}
        <p><strong>Codigo de referencia:</strong> ${referenceCode} </p>`,

    PAYMENT_MACHINE_DECLINED_TITLE: 'TRANSACCIÓN DECLINADA',
    PAYMENT_MACHINE_DECLINED_HTML: (machineId, status, transactionId, referenceCode, pseBank, pseReference1) => `<h3> ::: INFORMACIÓN DE PAGO ::: </h3>
        <p><strong>Publicación #:</strong> ${machineId} </p>
        <p><strong>Estado de la transacción:</strong> ${status} </p>
        <p><strong>Transacción #:</strong> ${transactionId} </p>
        ${pseBank ? `<p><strong>Banco #:</strong> ${pseBank} </p>` : ``}
        ${pseReference1 ? `<p><strong>Referencia PSE #:</strong> ${pseReference1} </p>` : ``}
        <p><strong>Codigo de referencia:</strong> ${referenceCode} </p>`,

    PAYMENT_MACHINE_PENDING_TITLE: 'PAGO PENDIENTE',
    PAYMENT_MACHINE_PENDING_HTML: (machineId, status, transactionId, referenceCode, pseBank, pseReference1) => `<hr><p style="text-align:justify; line-height: 25px;"><strong>¡¡¡ MUY BIEN !!! Estas a un paso de concretar tu siguiente negocio.</strong> Tu publicación ha sido creada con éxito, revisaremos los datos de tu publicación para asegurarnos de que todo marche correctamente, para que tu próximo negocio sea un éxito. <strong>Una vez realices el pago</strong> esta maquinaria sera revisada y posteriormente aceptada, esto te lo haremos saber vía correo electrónico. Nuestro equipo de profesionales de TMP revisará la consistencia de los datos de tu maquinaria y la calidad de las imágenes, en caso de tener algún ajuste necesario para que tu publicación tenga la mejor calidad y consiga el mayor impacto posible, te lo haremos saber vía email o nos comunicaremos contigo telefónicamente, esto con el fin de lograr los mejores resultados con tu publicación.</p><hr>
        <h3> ::: INFORMACIÓN DE PAGO ::: </h3>
        <p><strong>Publicación #:</strong> ${machineId} </p>
        <p><strong>Estado de la transacción:</strong> ${status} </p>
        <p><strong>Transacción #:</strong> ${transactionId} </p>
        ${pseBank ? `<p><strong>Banco #:</strong> ${pseBank} </p>` : ``}
        ${pseReference1 ? `<p><strong>Referencia PSE #:</strong> ${pseReference1} </p>` : ``}
        <p><strong>Codigo de referencia:</strong> ${referenceCode} </p>`,

    PAYMENT_MACHINE_ERROR_TITLE: 'ERROR EN TRANSACCIÓN',
    PAYMENT_MACHINE_ERROR_HTML: (machineId, status, transactionId, referenceCode, pseBank, pseReference1) => `<h3> ::: INFORMACIÓN DE PAGO ::: </h3>
        <p><strong>Publicación #:</strong> ${machineId} </p>
        <p><strong>Estado de la transacción:</strong> ${status} </p>
        <p><strong>Transacción #:</strong> ${transactionId} </p>
        ${pseBank ? `<p><strong>Banco #:</strong> ${pseBank} </p>` : ``}
        ${pseReference1 ? `<p><strong>Referencia PSE #:</strong> ${pseReference1} </p>` : ``}
        <p><strong>Codigo de referencia:</strong> ${referenceCode} </p>`,

    CREATING_MACHINE_SUCCESS_TITLE: 'PUBLICACIÓN CREADA EXITOSAMENTE',
    CREATING_MACHINE_SUCCESS_HTML: (machineId) => `<div style="text-align:justify; line-height: 25px;"><hr><p><strong>¡¡¡ MUY BIEN !!! Estas a un paso de concretar tu siguiente negocio.</strong> Tu publicación ha sido creada con éxito, revisaremos los datos de tu publicación para asegurarnos de que todo marche correctamente, para que tu próximo negocio sea un éxito. Una vez realices el pago esta maquinaria sera revisada y posteriormente aceptada, esto te lo haremos saber vía correo electrónico.</p>
    <p>Nuestro equipo de profesionales de TMP revisará la consistencia de los datos de tu maquinaria y la calidad de las imágenes, en caso de tener algún ajuste necesario para que tu publicación tenga la mejor calidad y consiga el mayor impacto posible, te lo haremos saber vía email o nos comunicaremos contigo telefónicamente, esto con el fin de lograr los mejores resultados con tu publicación.</p>
    <p><strong><h2 style="text-align: center;">El número de referencia de tu publicación es el : # <span>${machineId}</span></h3></strong></p>
    <p><strong>¡¡¡ IMPORTANTE !!! Para que tu publicación sea visible</strong>, al darle OK a esta ventana, deberas seleccionar la oferta que mas se ajuste a tu modelo de negocio y a la urgencia para concretar tu siguiente negocio, en caso de no poder adquirir alguno de nuestros planes en este momento, recuerda que podrás hacerlo mas tarde a través de tu panel o solicitando ayuda de nuestro equipo de profesionales, a través de nuestros diferentes canales de comunicación que tenemos para ti ya sea por chat, whatsapp o llamada telefónica.</p><hr></div>`,
    CREATING_MACHINE_ERROR: (message) => `${message}`,

    CREATING_INSURANCE_SUCCESS_TITLE: 'COTIZACIÓN DE PÓLIZA CREADA EXITOSAMENTE',
    CREATING_INSURANCE_SUCCESS_HTML: (insuranceId) => `Tu cotización ha sido creada exitosamente con el número de referencia # 0000${insuranceId}, te hemos enviado un correo con los datos de esta cotización, muy pronto uno de nuestros expertos se pondrá en contacto contigo para continuar con el proceso de adquisición de póliza.`,
    ERROR_MESSAGE: (error) => error,
    GENERAL_ERROR: 'Error procesando solicitud.',
};

const Colors = {
    SUCCESS_MAIN: '#ff9b30',
}

module.exports = {
    Strings,
    Colors,
}