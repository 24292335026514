import React, { Component } from 'react';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Logo from "../../images/logo.png";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link, Redirect} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import queryString from "query-string";
import axios from "axios";
import { API_URL, RESET_PASSWORD } from "../../API";
import {message_responses} from "../../MESSAGE_RESPONSES";

const styles = {
    paper: {
        margin: '10% 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    logoContainer: {
        textAlign: 'center',
    },
    logo: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
    error: {
        color: '#ef2020',
    },
    success:{
        color: '#28a745'
    },
    progress: {
        color: '#ef2020',
    },
    submit: {
        minHeight: '56px',
        color: '#ef2020',
        borderRadius: '4px !important',
    },
    link: {
        textDecoration: 'none',
        color: '#000000 !important',
        '&:hover': {
            textDecoration: 'underline',
            color: '#000000 !important',
        }
    }
};

class PasswordResetComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            email: '',
            key: '',
            password: '',
            passwordConfirm: '',

            showPassword: false,
            showPasswordConfirm: false,

            passwordError: '',
            passwordConfirmError: '',

            redirectToHome: false,
            doingReset: false,
            resetError: '',
            resetSuccess: '',
            emailError: '',
        }
    }

    componentDidMount() {
        this._getUrlParams();
    }

    _getUrlParams() {
        //console.log(this.props.url)
        let url_params = queryString.parse(this.props.url);

        if(url_params.email === undefined || url_params.key === undefined){
            this.setState({redirectToHome: true})
        }

        this.setState({
            email: url_params.email,
            key: url_params.key,
        })
    }

    redirectToHome = () => {
        if (this.state.redirectToHome) {
            return <Redirect push to='/' />
        }
    }

    handleChange(event){
        this.setState({[event.target.name] : event.target.value})
    }

    handleClickShowPassword(){
        this.setState({showPassword:!this.state.showPassword})
    }

    handleMouseDownPassword(event){
        event.preventDefault();
    }

    handleClickShowPasswordConfirm(){
        this.setState({showPasswordConfirm:!this.state.showPasswordConfirm})
    }

    handleMouseDownPasswordConfirm(event){
        event.preventDefault();
    }

    validateField(){
        return this.validatePassword()
            && this.validatePasswordMatch();
    }

    validatePassword(){
        let field = this.state.password;
        if(field === ''){
            this.setState({passwordError: "La contraseña no puede estar vací­a"})
            return false;
        }else{
            this.setState({passwordError: ""})
            return true;
        }
    }

    validatePasswordMatch(){
        let field = this.state.password;
        let compare_field = this.state.passwordConfirm;
        if(field !== compare_field){
            this.setState({passwordConfirmError: "Las contraseñas no coinciden"})
            return false;
        }else{
            this.setState({passwordConfirmError: ""})
            return true;
        }
    }

    doReset(){
        if(this.validateField()){
            this.setState({
                doingReset:true,
                resetSuccess:'',
                resetError:'',
            })

            let formData = {
                email: this.state.email,
                key: this.state.key,
                password: this.state.password,
                c_password: this.state.passwordConfirm,
            }

            axios.post(API_URL + RESET_PASSWORD, formData).then((response) => {
                this.setState({doingReset:false})
                if(response.data.success){
                    this.setState({ resetSuccess:message_responses[response.data.message] })
                }else{
                    this.setState({ resetError:message_responses[response.data.message] })
                }
            }).catch((error) => {
                if(error.response && error.response.data){
                    this.setState({
                        resetError:message_responses[error.response.data.message],
                        doingReset:false
                    })
                }else{
                    this.setState({
                        resetError: "ERROR DE SERVIDOR",
                        doingReset:false
                    })
                }
            })
        }
    }

    render(){
        const { classes } = this.props;
        return(
            <React.Fragment>
                {this.redirectToHome()}
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} className={classes.logoContainer}>
                                <img src={Logo} alt="logo" className={classes.logo}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo"
                                    name="email"
                                    autoComplete="email"
                                    value={this.state.email}
                                    disabled={true}
                                />
                                { this.state.emailError !== '' && <span className={classes.error}>{this.state.emailError}</span> }
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    value={this.state.password}
                                    onChange={this.handleChange.bind(this)}
                                    InputProps={{
                                        'aria-label': 'bare',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword.bind(this)}
                                                    onMouseDown={this.handleMouseDownPassword.bind(this)}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                { this.state.passwordError !== '' && <span className={classes.error}>{this.state.passwordError}</span> }
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="passwordConfirm"
                                    label="Confirmar Contraseña"
                                    type={this.state.showPasswordConfirm ? 'text' : 'password'}
                                    id="passwordConfirm"
                                    autoComplete="current-password"
                                    value={this.state.passwordConfirm}
                                    onChange={this.handleChange.bind(this)}
                                    InputProps={{
                                        'aria-label': 'bare',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPasswordConfirm.bind(this)}
                                                    onMouseDown={this.handleMouseDownPasswordConfirm.bind(this)}
                                                >
                                                    {this.state.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                { this.state.passwordConfirmError !== '' && <span className={classes.error}>{this.state.passwordConfirmError}</span> }
                            </Grid>

                            <Grid container justify="center">
                                <Grid item>
                                    <br/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                { this.state.resetError !== '' && <span className={classes.error}>{this.state.resetError}</span> }
                                { this.state.resetSuccess !== '' && <span className={classes.success}>{this.state.resetSuccess}</span> }
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <br/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => this.doReset()}
                                    disabled={this.state.doingReset}
                                >
                                    {this.state.doingReset ? <CircularProgress className={classes.progress} color="secondary" /> : 'REESTABLECER CONTRASEÑA'}
                                </Button>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <br/>
                                </Grid>
                            </Grid>

                            <Grid container justify="center">
                                <Grid item>
                                    <Link to="/login" className={classes.link}>
                                        Ya tienes una cuenta ? Inicia sesión.
                                    </Link>
                                </Grid>
                            </Grid>

                            <Grid container justify="center">
                                <Grid item>
                                    <Link to="/sign-up" className={classes.link}>
                                        AÃºn no tienes cuenta en <strong>SushiNogal</strong> ? RegÃ­strate.
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PasswordResetComponent);