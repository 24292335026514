import React from "react";
import { Card as MaterialCard } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";  
import './Card.css';

const Card = ({data}) => {

    const {
        link, 
        image, 
        title, 
        description
    } = data;

  return (
    <MaterialCard className='card-container'>
        <Link to={link} className='card-link'>
            <CardActionArea className='card-action-area'>
                <CardMedia
                    className='card-media'
                    image={image}
                />
                
                <div className='card-title'>
                    {title}
                </div>

                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p" className='card-services-text'>
                        {description}
                    </Typography>
                    <div className='card-show-more'>
                        <Link to={link} className='card-link'>
                            <Button size="small" color="primary" className='card-show-more-button'>
                                Ver Mas
                            </Button>
                        </Link>
                    </div>
                </CardContent>
            </CardActionArea>
        </Link>
    </MaterialCard>
  );
};


export default Card;