import React, { Component } from 'react';
import VerifyEmailComponent from "../../components/VerifyEmailComponent/VerifyEmailComponent";
import Header from "../../components/Header/Header";

class VerifyEmail extends Component{
    render(){
        return(
            <React.Fragment>
                <Header/>
                <VerifyEmailComponent url={this.props.location.search}/>
            </React.Fragment>
        );
    }
}

export default VerifyEmail;