import React from 'react';
import './HomeFooter.css';
import InspectedSeal from "../../images/inspected.png";
import HomeFooterMachineIcon from "../../images/home_footer_machine_icon.svg";
import { Grid } from "@material-ui/core";
import TitleLabel from '../primitive/TitleLabel/TitleLabel';
//import { Link } from "react-router-dom";
import HomeComponentController from './HomeFooterController';

const HomeFooter = () => {

    const controllerProps = HomeComponentController({});

    const {
        goToPublishNewMachine,
    } = controllerProps;

    return(
        <Grid container spacing={0}>

            <Grid item xs={12} md={3} className='homeFooterMachineImageContainer'>
                <img src={HomeFooterMachineIcon} atl='home-footer-machine' className='homeFooterMachineImage'/>
            </Grid>

            <Grid item xs={12} md={6} className='homeFooterCenterContainer'>
                <div className='homeFooterCenter'>
                    <h2>¿Tienes una máquina para vender?</h2>
                    <TitleLabel text='Publica Ahora' variant='dark' onClick={goToPublishNewMachine} />
                    {/*<div>Si eres concesionario, <Link to="/sign-up">regístrate</Link> para conocer nuestros paquetes especiales para tu negocio.</div>*/}
                </div>
                
            </Grid>

            <Grid item xs={12} md={3} className='homeFooterImageContainer'>
                <img src={InspectedSeal} atl='home-footer-machine' className='homeFooterImage'/>
                <h2>GARANTÍA TMP</h2>
            </Grid>

        </Grid>
    )
}

export default HomeFooter;