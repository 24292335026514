import React from 'react';
import SellRentNewMachineComponent from '../../components/SellRentNewMachineComponent/SellRentNewMachineComponent'
import Header from "../../components/Header/Header";
import FooterComponent from "../../components/FooterComponent/FooterComponent";

class SellRentNewMachine extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Header/>
                <SellRentNewMachineComponent/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default SellRentNewMachine;