import React, { Component, Fragment } from 'react';
import './CotizacionForm.css';
import {Grid, Paper, MenuItem, TextField, Typography, Button} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { API_URL } from '../../API';
import axios from 'axios';
import ContactDialog from "../ContactDialog/ContactDialog";



const categories = [
    {
        value: 'Compactadora',
        label: 'Compactadora',
    },
    {
        value: 'Grúa',
        label: 'Grúa',
    },
    {
        value: 'Perforadora',
        label: 'Perforadora',
    },
    {
        value: 'Excavadora',
        label: 'Excavadora',
    },
];

const countries = [
    {
      value: 'Colombia',
      label: 'Colombia',
    },
];

const cities = [
    {
        value: 'Bogotá',
        label: 'Bogotá',
    },
    {
        value: 'Medellín',
        label: 'Medellín',
    }
];

const brands = [
    {
        value: 'Caterpillar',
        label: 'Caterpillar',
    },
    {
        value: 'Komatsu',
        label: 'Komatsu',
    },
    {
        value: 'Volvo',
        label: 'Volvo',
    },
    {
        value: 'Hitachi',
        label: 'Hitachi',
    },
];

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing.unit,
    },
    cssLabel: {
        '&$cssFocused': {
            color: "#000000",
        },
        fontFamily: "\"machineFont\" !important",
    },
    cssFocused: {
        fontFamily: "\"machineFont\" !important",
    },
    cssUnderline: {
        '&:after': {
            borderBottomColor: "#000000",
            fontFamily: "\"machineFont\" !important",
        },
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "#000000",
            borderWidth: "3px",
        },
    },
    notchedOutline: {
        borderRadius: 0,
        borderColor: "#000000 !important",
        borderWidth: "2px",

    },
    bootstrapRoot: {
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    cssFilled: {
        fontFamily: "\"machineFont\" !important",
    },
});

class CotizacionForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            country: '',
            city: '',
            category: '',
            brand: '',
            QuotationRequest : {
                customerName : "",
                customerLastName : "",
                customerEmail : "",
                customerPhone : "",
                customerCountry : "",
                customerCity : "",
                machineCategory : "",
                machineBrand : "",
                machineModel : "",
                machineYear : "",
                machineSN : "",
                machineImgPlate : "NA",
            },
            errorCustomerNameText : "",
            errorCustomerLastNameText : "",
            errorEmailText : "",
            errorCustomerPhoneText: "",
            errorCustomerCountryText: "",
            errorCustomerCityText: "",
            errorMachineCategoryText: "",
            errorMachineBrandText: "",
            errorMachineYearText: "",
            errorMachineModelText: "",
            errorMachineSNText: "",
            open: false,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    _addQuotationRequest(){

        if(this.validateData()){
            console.log(this.state.QuotationRequest);
            let newQuotationRequest = {
                customer_name : this.state.QuotationRequest.customerName.toLowerCase(),
                customer_lastname : this.state.QuotationRequest.customerLastName.toLowerCase(),
                customer_email : this.state.QuotationRequest.customerEmail.toLowerCase(),
                customer_phone : this.state.QuotationRequest.customerPhone,
                customer_country : this.state.QuotationRequest.customerCountry,
                customer_city : this.state.QuotationRequest.customerCity,
                machine_category : this.state.QuotationRequest.machineCategory,
                machine_brand : this.state.QuotationRequest.machineBrand,
                machine_model : this.state.QuotationRequest.machineModel.toUpperCase(),
                machine_year : this.state.QuotationRequest.machineYear,
                machine_SN : this.state.QuotationRequest.machineSN.toUpperCase(),
                machine_plate_img : this.state.QuotationRequest.machineImgPlate,
            }

            axios.post(API_URL+'/quotationRequests', newQuotationRequest).then((response) => {

                let clearQuotationRequest = {
                    customerName : "",
                    customerLastName : "",
                    customerEmail : "",
                    customerPhone : "",
                    customerCountry : "",
                    customerCity : "",
                    machineCategory : "",
                    machineBrand : "",
                    machineModel : "",
                    machineYear : "",
                    machineSN : "",
                    machineImgPlate : "NA",
                };

                this.setState({
                    QuotationRequest: clearQuotationRequest,
                });
            });
        }else{

        };


    }

    validateCustomerName(customerName){
        let regExp = /^[a-z ,.'-]+$/i;
        let validation = regExp.test(customerName) && (customerName.length < 254 && customerName.length > 0);
        if (!validation){
            if(customerName.length > 0){
                this.setState({errorCustomerNameText : "Tienes un error escribiendo tu Nombre"})
            }else{
                this.setState({errorCustomerNameText : "Campo obligatorio"})
            }
            return false;
        }else{
            this.setState({errorCustomerNameText : ""})
            return true;
        }
    }

    validateCustomerLastName(customerLastName){
        let regExp = /^[a-z ,.'-]+$/i;
        let validation = regExp.test(customerLastName) && (customerLastName.length < 254 && customerLastName.length > 0);
        if (!validation){
            if(customerLastName.length > 0){
                this.setState({errorCustomerLastNameText : "Tienes un error escribiendo tu Apellido"})
            }else{
                this.setState({errorCustomerLastNameText : "Campo obligatorio"})
            }
            return false;
        }else{
            this.setState({errorCustomerLastNameText : ""})
            return true;
        }
    }

    validateEmail(email) {
        // eslint-disable-next-line no-useless-escape
        let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let validation = regExp.test(email) && (email.length < 254 && email.length > 5);
        if (!validation){
            if(email.length > 0){
                this.setState({errorEmailText : "Tienes un error escribiendo tu Correo"})
            }else{
                this.setState({errorEmailText : "Campo obligatorio"})
            }
            return false;
        }else{
            this.setState({errorEmailText : ""})
            return true;
        }
    }

    validateCustomerPhone(customerPhone){
        // eslint-disable-next-line no-useless-escape
        let regExp = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
        let validation = regExp.test(customerPhone) && (customerPhone.length < 254 && customerPhone.length > 6);
        if (!validation){
            if(customerPhone.length > 0){
                this.setState({errorCustomerPhoneText : "Tienes un error escribiendo tu Telefono"})
            }else{
                this.setState({errorCustomerPhoneText : "Campo obligatorio"})
            }
            return false;
        }else{
            this.setState({errorCustomerPhoneText : ""})
            return true;
        }
    }

    validateCustomerCountry(customerCountry) {
        if (customerCountry.length > 0 && customerCountry.length < 255){
            this.setState({errorCustomerCountryText : ""})
            return true;
        }else{
            this.setState({errorCustomerCountryText : "Campo obligatorio"})
            return false;
        }
    }

    validateCustomerCity(customerCity){
        if (customerCity.length > 0 && customerCity.length < 255){
            this.setState({errorCustomerCityText : ""})
            return true;
        }else{
            this.setState({errorCustomerCityText : "Campo obligatorio"})
            return false;
        }
    }

    validateMachineCategory(machineCategory){
        //errorMachineCategoryText
        if (machineCategory.length > 0 && machineCategory.length < 255){
            this.setState({errorMachineCategoryText : ""})
            return true;
        }else{
            this.setState({errorMachineCategoryText : "Campo obligatorio"})
            return false;
        }
    }

    validateMachineYear(machineYear){
        if(machineYear.length === 0){
            this.setState({errorMachineYearText : ""})
            return true;
        }
        let regExp = /^\d{4}$/;
        let validation = regExp.test(machineYear);
        if (!validation){
            if(machineYear.length > 0){
                this.setState({errorMachineYearText : "Tienes un error escribiendo el Año del modelo"})
            }else{
                this.setState({errorMachineYearText : "Campo obligatorio"})
            }
            return false;
        }else{
            this.setState({errorMachineYearText : ""})
            return true;
        }
    }

    validateMachineBrand(machineBrand){
        if (machineBrand.length > 0 && machineBrand.length < 255){
            this.setState({errorMachineBrandText : ""})
            return true;
        }else{
            this.setState({errorMachineBrandText : "Campo obligatorio"})
            return false;
        }
    }

    validateMachineModel(machineModel){
        if (machineModel.length < 255){
            this.setState({errorMachineModelText : ""})
            return true;
        }else{
            this.setState({errorMachineModelText : "Campo obligatorio"})
            return false;
        }
    }

    validateMachineSN(machineSN){
        if (machineSN.length < 255){
            this.setState({errorMachineSNText : ""})
            return true;
        }else{
            this.setState({errorMachineSNText : "Campo obligatorio"})
            return false;
        }
    }

    validateData(){
        if(
            this.validateCustomerName(this.state.QuotationRequest.customerName) &&
            this.validateCustomerLastName(this.state.QuotationRequest.customerLastName) &&
            this.validateEmail(this.state.QuotationRequest.customerEmail) &&
            this.validateCustomerPhone(this.state.QuotationRequest.customerPhone) &&
            this.validateCustomerCountry(this.state.QuotationRequest.customerCountry) &&
            this.validateCustomerCity(this.state.QuotationRequest.customerCity) &&
            this.validateMachineCategory(this.state.QuotationRequest.machineCategory) &&
            this.validateMachineBrand(this.state.QuotationRequest.machineBrand) &&
            this.validateMachineModel(this.state.QuotationRequest.machineModel) &&
            this.validateMachineYear(this.state.QuotationRequest.machineYear) &&
            this.validateMachineSN(this.state.QuotationRequest.machineSN)
        ){
            return true;
        }else{
            return false;
        }
    }

    handleChange(event) {

        switch (event.target.name) {
            case "customerName":
                this.validateCustomerName(event.target.value);
                break;
            case "customerLastName":
                this.validateCustomerLastName(event.target.value);
                break;
            case "customerEmail":
                this.validateEmail(event.target.value);
                break;
            case "customerPhone":
                this.validateCustomerPhone(event.target.value);
                break;
            case "customerCountry":
                this.validateCustomerCountry(event.target.value);
                break;
            case "customerCity":
                this.validateCustomerCity(event.target.value);
                break;
            case "machineCategory":
                this.validateMachineCategory(event.target.value);
                break;
            case "machineBrand":
                this.validateMachineBrand(event.target.value);
                break;
            case "machineModel":
                this.validateMachineModel(event.target.value);
                break;
            case "machineYear":
                this.validateMachineYear(event.target.value);
                break;
            case "machineSN":
                this.validateMachineSN(event.target.value);
                break;
            default:
                //console.log("Another Target Not Validated");
                break;
        }


        let newState = Object.assign({}, this.state);
        let temp_QuotationRequest = newState.QuotationRequest;
        temp_QuotationRequest[event.target.name] = event.target.value;
        this.setState({QuotationRequest: temp_QuotationRequest});
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleContactOpen = () => {
        this.setState({ open: true });
    };

    render(){
        const { classes } = this.props;
        return(
            <Fragment>
                <Grid container className={["mainContainer","yellowBackground"].join(" ")}>
                    <Grid item xs={12} className="mainRow">
                        <Paper className="mainPaperCotizar">

                            <Typography variant="h3" className="cotizarTitle">Solicita tu cotización</Typography>

                            <form autoComplete="off">
                                <Grid container spacing={24} className="cotizarFormContainer">

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            error ={this.state.errorCustomerNameText.length === 0 ? false : true }
                                            helperText={this.state.errorCustomerNameText}
                                            id="outlined-name"
                                            name="customerName"
                                            label="Nombre"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                            }}
                                            value={this.state.QuotationRequest.customerName}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            error ={this.state.errorCustomerLastNameText.length === 0 ? false : true }
                                            helperText={this.state.errorCustomerLastNameText}
                                            id="outlined-lastname"
                                            name="customerLastName"
                                            label="Apellido"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                            }}
                                            value={this.state.QuotationRequest.customerLastName}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            error ={this.state.errorEmailText.length === 0 ? false : true }
                                            helperText={this.state.errorEmailText}
                                            id="outlined-email"
                                            name="customerEmail"
                                            label="Correo"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                            }}
                                            value={this.state.QuotationRequest.customerEmail}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            error ={this.state.errorCustomerPhoneText.length === 0 ? false : true }
                                            helperText={this.state.errorCustomerPhoneText}
                                            id="outlined-phone-number"
                                            name="customerPhone"
                                            label="Teléfono de contacto"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                            }}
                                            value={this.state.QuotationRequest.customerPhone}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField

                                            id="select-country"
                                            select
                                            error ={this.state.errorCustomerCountryText.length === 0 ? false : true }
                                            helperText={this.state.errorCustomerCountryText}
                                            label="Pais"
                                            margin="normal"
                                            variant="outlined"
                                            className={classes.root}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                                name: 'customerCountry',
                                            }}
                                            fullWidth
                                            value={this.state.QuotationRequest.customerCountry}
                                            onChange={this.handleChange}
                                        >
                                            {countries.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="select-city"
                                            select
                                            error ={this.state.errorCustomerCityText.length === 0 ? false : true }
                                            helperText={this.state.errorCustomerCityText}
                                            label="Ciudad"
                                            margin="normal"
                                            variant="outlined"
                                            className={classes.root}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                                name: 'customerCity',
                                            }}
                                            fullWidth
                                            value={this.state.QuotationRequest.customerCity}
                                            onChange={this.handleChange}
                                        >
                                            {cities.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Typography variant="h6" className="cotizarTitle">- Detalles de tu maquinaria -</Typography>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="select-category"
                                            select
                                            error ={this.state.errorMachineCategoryText.length === 0 ? false : true }
                                            helperText={this.state.errorMachineCategoryText}
                                            label="Categoría"
                                            margin="normal"
                                            variant="outlined"
                                            className={classes.root}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                                name: 'machineCategory',
                                            }}
                                            fullWidth
                                            value={this.state.QuotationRequest.machineCategory}
                                            onChange={this.handleChange}
                                        >
                                            {categories.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            id="select-brand"
                                            select
                                            error ={this.state.errorMachineBrandText.length === 0 ? false : true }
                                            helperText={this.state.errorMachineBrandText}
                                            label="Marca"
                                            margin="normal"
                                            variant="outlined"
                                            className={classes.root}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                                name: 'machineBrand',
                                            }}
                                            fullWidth
                                            value={this.state.QuotationRequest.machineBrand}
                                            onChange={this.handleChange}
                                        >
                                            {brands.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="outlined-model"
                                            error ={this.state.errorMachineModelText.length === 0 ? false : true }
                                            helperText={this.state.errorMachineModelText}
                                            name="machineModel"
                                            label="Modelo (Opcional)"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                            }}
                                            value={this.state.QuotationRequest.machineModel}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            error ={this.state.errorMachineYearText.length === 0 ? false : true }
                                            helperText={this.state.errorMachineYearText}
                                            id="outlined-year"
                                            name="machineYear"
                                            label="Año (Opcional)"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                            }}
                                            value={this.state.QuotationRequest.machineYear}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="outlined-serial-number"
                                            error ={this.state.errorMachineSNText.length === 0 ? false : true }
                                            helperText={this.state.errorMachineSNText}
                                            name="machineSN"
                                            label="Número de Serie (Opcional)"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.cssLabel,
                                                    focused: classes.cssFocused,
                                                },
                                            }}
                                            InputProps={{
                                                classes: {
                                                    root: classes.cssOutlinedInput,
                                                    focused: classes.cssFocused,
                                                    notchedOutline: classes.notchedOutline,
                                                },
                                                className: classes.cssFilled,
                                            }}
                                            value={this.state.QuotationRequest.machineSN}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>

                                    <Grid container className="cotizacionButtonContainer">
                                        <Grid item xs={12} md={6} className="cotizacionButtonContainer">
                                            <Button onClick={this._addQuotationRequest.bind(this)} className="cotizacionPanelButton"> Enviar Cotizacion</Button>
                                        </Grid>
                                        <Grid item xs={12} md={6} className="cotizacionButtonContainer">
                                            <Button className="cotizacionPanelButton"  onClick={this.handleContactOpen}> Contactanos</Button>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>

                <ContactDialog
                    open={this.state.open}
                    onClose={this.handleClose}
                />

            </Fragment>
        );
    }
}

export default withStyles(styles)(CotizacionForm);