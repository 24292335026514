import React, { Component } from 'react'
import Images from './UploadingImgBox'
import { API_URL } from '../../API'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBowlingBall, faImage} from "@fortawesome/free-solid-svg-icons";

class UploadImage extends Component {

    state = {
        uploading: false,
        images: []
    }

    onChange = e => {
        const files = Array.from(e.target.files)
        this.setState({ uploading: true })

        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append(i, file)
        })

        fetch(`${API_URL}/image-upload`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(images => {
                this.setState({
                    uploading: false,
                    images
                })
            })
    }

    removeImage = id => {
        this.setState({
            images: this.state.images.filter(image => image.public_id !== id)
        })
    }

    render() {
        const { uploading, images } = this.state

        const content = () => {
            switch(true) {
                case uploading:
                    return <div className='spinner fadein'>
                                <FontAwesomeIcon icon={faBowlingBall} size='5x' color='#3B5998' />
                            </div>
                case images.length > 0:
                    return <Images images={images} removeImage={this.removeImage} />
                default:
                    return <div className='buttons fadein'>
                        <div className='button'>
                            <label htmlFor='single'>
                                <FontAwesomeIcon icon={faImage} color='#3B5998' size='10x' />
                            </label>
                            <input type='file' id='single' onChange={this.onChange} />
                        </div>
                    </div>
            }
        }

        return (
            <div>
                <div className='buttons'>
                    {content()}
                </div>
            </div>
        )
    }
}

export default UploadImage;