import React, { Component, Fragment } from 'react';
import Header from '../../components/Header/Header';
import LoginComponent from "../../components/LoginComponent/LoginComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";
import queryString from 'query-string';

class Login extends Component{
    render(){
        return(
            <Fragment>
                <Header/>
                <LoginComponent urlParams={queryString.parse(this.props.location.search)}/>
                <FooterComponent/>
            </Fragment>
        );
    }
}

export default Login;