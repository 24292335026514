import React, { Component, Fragment } from 'react';
import './NotFound.css';


class NotFound extends Component{
    render(){
        return(
            <Fragment>
                ERROR 404: Pagina no encontrada
            </Fragment>
        );
    }
}

export default NotFound;