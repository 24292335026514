import React from 'react';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Paper from "@material-ui/core/Paper";
import DoneIcon from '@material-ui/icons/Done';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CachedIcon from '@material-ui/icons/Cached';
import HelpIcon from '@material-ui/icons/Help';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ContactDialog from "../ContactDialog/ContactDialog";
import {Divider} from "@material-ui/core";

class SellRentNewMachineComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            activeStep: 0,
            open: false,
        }
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return <div>
                    <p>Publicar tu equipo pesado en <strong>www.todoenmaquinariapesada.com</strong> es muy sencillo, solo debes tener las fotografías de tu maquinaria pesada (para esto te recomiendo descargar el instructivo de cómo se deben tomar las fotos  de tu equipo pesado, en el siguiente paso) seguido de esto debes descargar y llenar el formulario con los datos de tu equipo.</p>
                    <p>Luego que tengas la fotografías y el formulario debes enviarlo al correo <strong>info@todoenmaquinariapesada.com</strong>  y listo tu publicación será un éxito!!!</p>
                </div>
            case 1:
                return <div>
                        <p>Tomar fotografías de buena calidad y de la forma correcta (Formato Horizontal con tu cámara o Celular de buena resolución), ayudará a comercializar más rápidamente tu equipo pesado.</p>
                        <p>A continuación, podrás descargar el instructivo de como debes tomar las fotografías de tu maquinaria pesada.</p>
                    <Button
                        className="svgButtonSellRentHeaderPostButton"
                    >
                        <CloudDownloadIcon className='svgButtonSellRentHeaderPostButtonIcon'/>
                        Descargar instructivo de fotografía
                    </Button>
                    <p></p>
                </div>;
            case 2:
                return <div>
                    <p>Las características de tu equipo pesado son de suma importancia a la hora de que alguien se interese por tu equipo, por lo cual proporcionar la mayor cantidad de datos y características agiliza el comercio de tu maquinaria pesada.</p>
                    <p>A continuación, podrás descargar el formulario para que ingrese los datos y las características que nos ayudan a comercializar tu maquinaria pesada más rápidamente.</p>
                    <Button
                        className="svgButtonSellRentHeaderPostButton"
                    >
                        <CloudDownloadIcon className='svgButtonSellRentHeaderPostButtonIcon'/>
                        Descargar formulario de maquinaria
                    </Button>
                    <p></p>
                </div>;
            case 3:
                return <div>
                    <p>Una vez que tengas las fotografías y el formulario de tu equipo pesado completo, deberás enviar un correo con las fotografías y el formulario adjuntos a nuestro correo <strong>info@todoenmaquinariapesada.com</strong> donde uno de nuestros expertos lo revisará detenidamente, verificando la calidad de las fotografías y los datos de la máquina, una vez aprobada esta información será visible en nuestro catálogo en un lapso  de 4 horas.</p>
                    <p>En caso de que se requieran correcciones nuestros expertos se pondrán en contacto para ayudarte a completar la publicación de tu maquinaria pesada.Z</p>
                </div>;
            default:
                return ' ... ';
        }
    }

    handleNext = () => {
        this.setState({activeStep : this.state.activeStep + 1});
    };

    handleBack = () => {
        this.setState({activeStep : this.state.activeStep - 1});
    };

    handleReset = () => {
        this.setState({activeStep : 0});
    };

    handleContactOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render(){

        const steps = ['CÓMO PUBLICAR MI MAQUINARIA PESADA', 'INSTRUCTIVO DE FOTOGRAFÍA', 'DESCARGAR FORMULARIO', 'ENVIAR DATOS'];

        return(
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={4}>
                        <Card className='sellRentPricingCard'>
                            <CardHeader
                                title='PUBLICACIÓN'
                                subheader='ESTANDAR'
                                titleTypographyProps={{ align: 'center' }}
                                subheaderTypographyProps={{ align: 'center' }}
                                className='sellRentPricingCardHeader'
                            />
                            <CardContent>
                                <div className='sellRentPricingCardPricing'>
                                    <Typography component="h2" variant="h3" color="textPrimary">
                                        $0
                                    </Typography>
                                    <Typography variant="h6" color="textSecondary">
                                        /mes
                                    </Typography>
                                </div>
                                <ul>
                                    <Divider/>
                                    <Typography component="li" variant="subtitle1" align="center" className='sellRentPricingItems'>
                                        <CheckCircleIcon className='sellRentPricingItemsIcon'/> VENTA GARANTIZADA
                                    </Typography>
                                    <Divider/>
                                    <Typography component="li" variant="subtitle1" align="center" className='sellRentPricingItems'>
                                        <CheckCircleIcon className='sellRentPricingItemsIcon'/> COMISIÓN POR VENTA DEL 3% AL 5%
                                    </Typography>
                                    <Divider/>
                                    <Typography component="li" variant="subtitle1" align="center" className='sellRentPricingItems'>
                                        <CheckCircleIcon className='sellRentPricingItemsIcon'/> ASESORIA JURÍDICA
                                    </Typography>
                                    <Divider/>
                                    <Typography component="li" variant="subtitle1" align="center" className='sellRentPricingItems'>
                                        <CheckCircleIcon className='sellRentPricingItemsIcon'/> ASESORIA EN CONTRATACIÓN
                                    </Typography>
                                    <Divider/>
                                    <Typography component="li" variant="subtitle1" align="center" className='sellRentPricingItems'>
                                        <CheckCircleIcon className='sellRentPricingItemsIcon'/> PUBLICIDAD EN MEDIOS DIGITALES
                                    </Typography>
                                    <Divider/>
                                </ul>
                            </CardContent>
                            <CardActions>
                                buttonasdfsdf sdf
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid item xs={12} className='expertise-panel-options'>
                            <Stepper activeStep={this.state.activeStep} orientation="vertical">
                                {steps.map((label, index) => (
                                    <Step key={label} className='sellRentStepPost'>
                                        <StepLabel>{label}</StepLabel>
                                        <StepContent>
                                            <Typography>{this.getStepContent(index)}</Typography>
                                            <div>
                                                <Grid container spacing={8}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Button
                                                            className="svgButtonSellRentHeaderPostButton"
                                                            onClick={this.handleNext}
                                                        >
                                                            <DoneIcon className='svgButtonSellRentHeaderPostButtonIcon'/>
                                                            {this.state.activeStep === steps.length - 1 ? 'Entendido' : 'Siguiente Paso'}
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        {
                                                            this.state.activeStep ?
                                                                <Button
                                                                    disabled={this.state.activeStep === 0}
                                                                    className="svgButtonSellRentHeaderPostButton"
                                                                    onClick={this.handleBack}
                                                                >
                                                                    <KeyboardBackspaceIcon className='svgButtonSellRentHeaderPostButtonIcon'/>
                                                                    Atrás
                                                                </Button>
                                                                :
                                                                ''
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            {this.state.activeStep === steps.length && (
                                <Paper square elevation={0}>
                                    <Typography>Has terminado los pasos para publicar tu maquinaria pesada, si tienes alguna duda o deseas que te ayudemos a publicar tu equipo pesado no dudes en darle click al botón de contáctanos.</Typography>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                className="svgButtonSellRentHeaderPostButton"
                                                onClick={this.handleReset}
                                            >
                                                <CachedIcon className='svgButtonSellRentHeaderPostButtonIcon'/>
                                                Reiniciar guía
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button
                                                className="svgButtonSellRentHeaderPostButton"
                                                onClick={this.handleContactOpen}
                                            >
                                                <HelpIcon className='svgButtonSellRentHeaderPostButtonIcon'/>
                                                Contáctanos
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <ContactDialog
                    open={this.state.open}
                    onClose={this.handleClose}
                />
            </React.Fragment>
        )
    }
}

export default SellRentNewMachineComponent;