import React, { Fragment } from 'react';
import AdminHeader from '../../components/AdminHeader/AdminHeader';
import NewMahineComponent from "../../components/NewMachineComponent/NewMahineComponent";

const AdminNewMachine = () => {
    return <Fragment>
                <AdminHeader/>
                <NewMahineComponent variant='admin'/>
            </Fragment>
}

export default AdminNewMachine;