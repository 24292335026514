import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL, GET_STATISTICS_BY_USER } from '../../../API';
import { message_responses } from '../../../MESSAGE_RESPONSES';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
    progress: {
        color: '#ff9b01',
    },
    cardContainer: {
        margin: '20px 0px',

    },
      root: {
    minWidth: 275,
    backgroundColor: "black",
    color: "white"
    
  },
  title: {
    fontSize: 14,
    color: "white"
  },
  pos: {
    marginBottom: 12,
  },
});


const MyMetricsComponent = () => {
    const classes = useStyles();

    const [ loadingItems, setLoadingItems ] = useState(false);
    const [ machine, setItems ] = useState([]);

    useEffect(() => {
        getStatistics();
    }, [])

    const getStatistics = () => {

        let user = JSON.parse(localStorage.getItem('TMPData'));
        if(user){
            let userId = user.user.id;    

            setLoadingItems(true);
    
            axios.get(API_URL + GET_STATISTICS_BY_USER + userId).then((response) => {
                setLoadingItems(false);
                if(response.data.success){
                    setItems(response.data.data);
                }else{
                    alert(message_responses[response.data.message]);
                }
            }).catch((error) => {
                setLoadingItems(false);
                if(error.response && error.response.data){
                    alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
                }else{
                    alert("Por favor discúlpanos :( , hemos tenido un error cargando tus maquinas.")
                }
            })
        }
        
    }

    return(
        <>
            {
                machine.length === 0 ? 
                <>
                    Aun no has publicado ninguna maquinaria.
                </>
                :
                <>
                    <Grid container>
                        <CustomCard title={"Total de Publicaciones"} content={machine.totalPublication}/>
                        <CustomCard title={"Total de Publicaciones Activas "} content={machine.totalActive}/>
                        <CustomCard title={"Total de Publicaciones Pausadas"} content={machine.totalPaused}/>
                        <CustomCard title={"Total de Publicaciones Vendidas"} content={machine.totalSold}/>
                        <CustomCard title={"Total de Visitas"} content={machine.totalVisits}/>
                    </Grid>
                </>
            }
        </>
    );
}

const CustomCard = ({title,content}) => {
    const classes = useStyles();
    return (
        <Grid item xs={12} md={6}>
            <Grid container className={classes.cardContainer}>
                    <CardActionArea component="a" href="#">
                    <Card className={classes.card}></Card>
                    <CardContent>
                        <Typography component="h2" variant="h5">
                        </Typography>
                        <Typography variant="subtitle1" color="inherit">
                        </Typography>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.title} color="inherit" gutterBottom>
                                    {title}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    {content}
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardContent>
                    </CardActionArea>
                </Grid>
        </Grid>
                      
    )
}

export default MyMetricsComponent;