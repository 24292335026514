import React, { Component, Fragment } from 'react';
import Banner from '../../images/contactus_parts.jpg';
import {Button, Grid} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ReactComponent as SVGButton} from "../../images/logo.svg";
import ContactDialog from "../ContactDialog/ContactDialog";

class PartsComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            open: false,
        }
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleContactOpen = () => {
        this.setState({ open: true });
    };

    render(){
        return(
            <Fragment>
                <img src={Banner} className='contact-us-banner-img' alt='parts-banner' />

                <Grid container spacing={0} justify='center'>
                    <Grid item xs={6} className='expertise-panel'>
                        <Link to='/contactanos' className='button-link'>
                            <Button className="panelButton"><SVGButton className='svgButton'/>  Cotizar</Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} className='expertise-panel'>
                        <Button className="panelButton" onClick={this.handleContactOpen}><SVGButton className='svgButton'/>  Contáctanos</Button>
                    </Grid>
                </Grid>

                <ContactDialog
                    open={this.state.open}
                    onClose={this.handleClose}
                />

            </Fragment>
        );
    }
}

export default PartsComponent;