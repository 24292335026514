import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import axios from "axios";
import { GET_CATEGORIES_ACTIVE, API_URL } from "../../API";
import { message_responses } from "../../MESSAGE_RESPONSES";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
    position: "center",
  },
  image: {
    position: "relative",
    height: 250,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.75,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.25,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px`,
    background: "#808080d9",
    margin: "0px 10px",
    color: "#000000",
    fontWeight: "bold",
    "&:hover": {
      background: "#f89a2f8f",
    },
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  cardContainer: {
    width: "100%",
    borderRadius: "0px",
    minHeight: "250px",
    padding: "5px",
    boxShadow: "unset",
  },
  skeletonContainer: {
    transform: "unset",
    minHeight: "100%",
  },
  categoryContainer: {
    padding: "30px",
  },
}));

const SellingCategoriesComponent = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    setLoadingCategories(true);
    axios
      .get(GET_CATEGORIES_ACTIVE)
      .then((response) => {
        setLoadingCategories(false);
        if (response.data.success) {
          setCategories(response.data.data);
        } else {
          alert(message_responses[response.data.message]);
        }
      })
      .catch((error) => {
        setLoadingCategories(false);
        if (error.response && error.response.data) {
          alert(
            error.response.data.message === "VALIDATOR_ERROR"
              ? error.response.data.data.error_message
              : message_responses[error.response.data.message]
          );
        } else {
          alert(
            "Por favor discúlpanos :( , hemos tenido un error de servidor."
          );
        }
      });
  };

  return (
    <div className={classes.root}>
      {loadingCategories && (
        <React.Fragment>
          <Grid container spacing={0}>
            {[...Array(9).keys()].map(() => (
              <Grid item xs={12} sm={6} md={4}>
                <Grid
                  container
                  spacing={0}
                  className={classes.categoryContainer}
                >
                  <Card className={classes.cardContainer}>
                    <Skeleton
                      className={classes.skeletonContainer}
                      width="100%"
                    />
                  </Card>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      )}
      <Grid container spacing={0}>
        {categories.map((category) => (
          <Grid item xs={12} sm={6} md={4}  className={classes.categoryContainer}>
            <Link
              to={
                "/ventas?category_id=" +
                category.id +
                "&category_name=" +
                category.name
              }
            >
              <ButtonBase
                focusRipple
                key={category.name}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={{
                  width: "100%",
                }}
              >
                <span
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: `url(${category.image_path})`,
                  }}
                />
                <span className={classes.imageBackdrop} />
                <span className={classes.imageButton}>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                  >
                    {category.name.toUpperCase()}
                    <span className={classes.imageMarked} />
                  </Typography>
                </span>
              </ButtonBase>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SellingCategoriesComponent;
