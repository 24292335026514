import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {ADMIN_LOGIN} from "../../API";
import axios from 'axios';
import {message_responses} from "../../MESSAGE_RESPONSES";


export const AdminLoginController = () => {

    /*const { urlParams } = props
    console.log(urlParams)*/

    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ showPassword, setShowPassword ] = useState(false)
    const [ doingLogin, setDoingLogin ] = useState(false)
    const [ errors, setErrors ] = useState({
        email: '',
        password: '',
    });

    const history = useHistory();

    const validateEmail = () => {
        if(!email){
            setErrors({...errors, ...{ email: 'El correo no puede estar vacío' } })
            return false;
        }else{
            setErrors({...errors, ...{ email: '' } })
            return true;
        }
    }

    const validatePassword = () => {
        if(!password){
            setErrors({...errors, ...{ password: 'El correo no puede estar vacío' } })
            return false;
        }else{
            setErrors({...errors, ...{ password: '' } })
            return true;
        }
    }

    const validateField = () => {
        return validateEmail() && validatePassword();
    }

    const doLogin = () => {
        if(validateField()){
            setDoingLogin(true)

            let formData = {
                email,
                password,
            }

            axios.post(ADMIN_LOGIN, formData).then((response) => {
                setDoingLogin(false)
                if(response.data.success){
                    localStorage.setItem('TMPData', JSON.stringify(response.data.data))
                    history.push('/admin/dashboard')
                }else{
                    setErrors({...errors, ...{ login: message_responses[response.data.message] } })
                    alert(message_responses[response.data.message])
                }
            }).catch((error) => {
                setDoingLogin(false)
                if(error.response && error.response.data){
                    setErrors({...errors, ...{ login: message_responses[error.response.data.message] } })
                    alert(message_responses[error.response.data.message])
                }else{
                    alert("ERROR DE SERVIDOR")
                }
            })
        }
    }

    return {
        email,
        setEmail,
        password,
        setPassword,
        errors,
        showPassword,
        setShowPassword,
        doingLogin,
        doLogin,
    }

}

