import { useHistory } from 'react-router-dom';

const HomeComponentController = () => {

    const history = useHistory();

    const goToPublishNewMachine = () => {
        history.push('/publicar')
    }

    return {
        goToPublishNewMachine,
    }
}

export default HomeComponentController;