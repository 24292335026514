export const API_URL = process.env.REACT_APP_API_ENDPOINT;

/** API_V1 **/
export const SEND_CONTACT_US = '';
export const CREATE_QUOTATION_REQUEST = 'quotation-requests';

//export const GET_SELL_RENT_PRODUCTS = 'sell-rent-products';
export const GET_SELL_RENT_PRODUCT = 'sell-rent-products/';

/** API_v2 **/

export const GET_CATEGORIES_ACTIVE = `${API_URL}machine-categories-active`;
export const GET_SUBCATEGORIES_BY_CATEGORY = 'machine-subcategories-by-category/';
export const GET_SUBCATEGORIES_BY_CATEGORY_WITH_MACHINES = 'machine-subcategories-with-at-leats-one-machine/';
export const GET_BRANDS_ACTIVE = 'machine-brands-active';
export const GET_SUBCATEGORY_FIELDS = 'subcategory-fields-by-name';

export const CREATE_MACHINE = 'machine';
export const EDIT_MACHINE = 'machine/edit';
export const DELETE_MACHINE = 'delete-machine/';

export const GET_USER_MACHINES = 'user-machines/';
export const GET_MACHINES_FOR_SELL = 'machines-for-sell';
export const GET_MACHINE_FOR_SELL_BY_ID = 'machine-for-sell/';
export const DELETE_MACHINE_IMAGE = 'machine-image-delete/';
export const GET_MACHINES_BY_SUBCATEGORY = 'machines-by-subcategory/';

export const GET_MACHINES_FOR_RENT = 'machines-for-rent';
export const GET_MACHINE_FOR_RENT_BY_ID = 'machine-for-rent/';
export const CREATE_INSURANCE = 'insurance'
export const USER_SIGN_UP = 'user-sign-up';
export const USER_LOGIN = 'login';
export const ADMIN_LOGIN = `${API_URL}admin-login`;

export const VALIDATE_ACCOUNT_EMAIL = 'validate-email';
export const SEND_RESET_PASSWORD_EMAIL = 'send-reset-password-email';
export const RESET_PASSWORD = 'reset-password';

export const GET_MACHINES_COMMENTS = 'comments-by-machine/';
export const GET_REPONSE_BY_MACHINE = 'responses-by-machine/';

export const CREATE_COMMENT_REPONSE = 'machine-comment-response';
export const CREATE_MACHINE_COMMENT = 'machine-comment';

export const GET_STATISTICS_BY_USER = 'statistics-by-user/';
export const MARK_MACHINE_AS_SOLD = 'machines-mark-as-sold';

export const GET_PACKAGES = `${API_URL}packages-enabled-coming-soon`;

export const GET_ADMIN_MACHINE_LIST = `${API_URL}machines-admin-list`;
export const GET_MACHINE_CATEGORIES = `${API_URL}machine-categories`;
export const GET_MACHINE_SUBCATEGORIES = `${API_URL}machine-subcategories`;
export const GET_MACHINE_BRANDS = `${API_URL}machine-brands`;

export const GET_ADMIN_MACHINE_SEARCH = `${API_URL}machines-admin-search`;
export const UPDATE_MACHINE_STATUS = `${API_URL}machines-update-status`;

export const GET_ACTIVE_USERS = `${API_URL}active-users`;
export const SET_MACHINE_VISIBILITY = `${API_URL}set-machine-visibility`;
export const SEARCH_MACHINE = `${API_URL}search-machine`;
export const GET_OUTSTANDING_MACHINES = `${API_URL}outstanding-machines`;