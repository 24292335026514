import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Logo from "../../images/logo.png";
import {Link, Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import axios from "axios";
import {API_URL, SEND_RESET_PASSWORD_EMAIL} from "../../API";
import {message_responses} from "../../MESSAGE_RESPONSES";

const styles = {
    paper: {
        margin: '10% 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    logoContainer: {
        textAlign: 'center',
    },
    logo: {
        maxHeight: '100%',
        maxWidth: '50%',
    },
    error: {
        color: '#ef2020',
    },
    success:{
        color: '#28a745'
    },
    progress: {
        color: '#ff9b01',
    },
    submit: {
        minHeight: '56px',
        color: '#000000',
        borderRadius: '4px !important',
    },
    link: {
        textDecoration: 'none',
        color: '#000000 !important',
        '&:hover': {
            textDecoration: 'underline',
            color: '#000000 !important',
        }
    }
};

class PasswordRecoveryComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            redirectToHome: false,
            doingRecovery: false,
            resetError: '',
            resetSuccess: '',
            emailError: '',
        }
    }

    componentDidMount() {
        this.checkAuth();
    }

    checkAuth(){
        if(localStorage.getItem('TMPData')){
            this.setState({redirectToHome: true})
        }
    }

    redirectToHome = () => {
        if (this.state.redirectToHome) {
            return <Redirect push to='/' />
        }
    }

    doRecovery(){
        if(this.validateField()){
            this.setState({doingRecovery:true})

            let formData = {
                email: this.state.email,
            }
            
            axios.post(API_URL + SEND_RESET_PASSWORD_EMAIL, formData).then((response) => {
                this.setState({doingRecovery:false})
                if(response.data.success){
                    this.setState({ resetSuccess:message_responses[response.data.message] })
                }else{
                    this.setState({ resetError:message_responses[response.data.message] })
                }
            }).catch((error) => {
                if(error.response && error.response.data){
                    this.setState({
                        resetError:message_responses[error.response.data.message],
                        doingRecovery:false
                    })
                }else{
                    this.setState({
                        resetError: "ERROR DE SERVIDOR",
                        doingRecovery:false
                    })
                }
            })

        }
    }

    validateField(){
        return this.validateEmail();
    }

    validateEmail(){
        let field = this.state.email;
        if(field === ''){
            this.setState({emailError: "El correo no puede estar vacío"})
            return false;
        }else{
            this.setState({emailError: ""})
            return true;
        }
    }

    handleChange(event){
        this.setState({[event.target.name] : event.target.value})
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.redirectToHome()}
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} className={classes.logoContainer}>
                                <img src={Logo} alt="logo" className={classes.logo}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo"
                                    name="email"
                                    autoComplete="email"
                                    value={this.state.email}
                                    onChange={this.handleChange.bind(this)}
                                />
                                { this.state.emailError !== '' && <span className={classes.error}>{this.state.emailError}</span> }
                            </Grid>

                            <Grid container justify="center">
                                <Grid item>
                                    <br/>
                                </Grid>
                            </Grid>
                        </Grid>
                        { this.state.resetError !== '' && <span className={classes.error}>{this.state.resetError}</span> }
                        { this.state.resetSuccess !== '' && <span className={classes.success}>{this.state.resetSuccess}</span> }
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => this.doRecovery()}
                            disabled={this.state.doingRecovery}
                        >
                            {this.state.doingRecovery ? <CircularProgress className={classes.progress} color="secondary" /> : 'ENVIAR CORREO DE RECUPERACIÓN'}
                        </Button>

                        <Grid container justify="center">
                            <Grid item>
                                <br/>
                            </Grid>
                        </Grid>

                        <Grid container justify="center">
                            <Grid item>
                                <Link to="/login" className={classes.link}>
                                    Ya tienes una cuenta ? Inicia sesión.
                                </Link>
                            </Grid>
                        </Grid>

                        <Grid container justify="center">
                            <Grid item>
                                <Link to="/sign-up" className={classes.link}>
                                    Aún no tienes cuenta en <strong>TMP</strong> ? Regístrate.
                                </Link>
                            </Grid>
                        </Grid>

                    </div>
                </Container>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(PasswordRecoveryComponent);