import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from 'axios';
import {
    API_URL, 
    GET_BRANDS_ACTIVE,
    GET_CATEGORIES_ACTIVE,
    GET_SUBCATEGORIES_BY_CATEGORY,
    GET_SUBCATEGORY_FIELDS,
    GET_MACHINE_FOR_SELL_BY_ID,
    DELETE_MACHINE_IMAGE,
    EDIT_MACHINE
} from "../../API";
import {message_responses} from "../../MESSAGE_RESPONSES";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import NumberFormat from "react-number-format";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from '@material-ui/icons/Help';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PublicIcon from '@material-ui/icons/Public';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Link from "react-router-dom/Link";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ErrorIcon from '@material-ui/icons/Error';
import {
    MAX_NUMBER_IMAGES_UPLOAD_FREE, 
    MAX_UPLOAD_IMAGE_SIZE_MB, 
    MIN_NUMBER_IMAGES_UPLOAD_FREE,
    MAX_UPLOAD_IMAGE_SIZE_BYTES
} from "../../SETTINGS";
import {all_countries} from '../../countries';
import { Redirect } from "react-router-dom";
import CropImages from '../CropImagesComponent/CropImages';
import { Pricing } from '../PricingComponent';
import Logo from "../../images/logo.png";
import Loading from '../LoadingComponent/Loading';

const styles = {
    container: {
        padding: '10px',
    },
    paper: {
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: '0px',
        padding: '10px',
    },
    stepper: {

    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        minHeight: '50px',
        margin: '10px',
    },
    buttonOverImage:{
        position: 'absolute',
        display: 'flex',
        top: 0,
        right: 0,
    },
    newFieldButton:{
        minHeight: '54px',
        marginTop: '16px',
        marginBottom: '8px',
    },
    buttonContainer: {
        textAlign: 'center',
    },
    title: {
        fontFamily: 'BebasFont, serif !important',
        paddingTop: '20px',
        width: '100%',
        textAlign: 'center',
    },
    subtitle: {
        fontFamily: 'BebasFont, serif !important',
        paddingTop: '20px',
        width: '100%',
        textAlign: 'left',
        fontSize: '2rem',
    },
    select: {
        width: '100%',
        marginTop: '16px',
        marginBottom: '8px',
    },
    spanErrorMessage:{
        color: '#dc3545',
        fontWeight: 'bold',
        textAlign: 'justify',
    },
    spanSuccessMessage:{
        color: '#28a745',
        fontWeight: 'bold',
        textAlign: 'justify',
    },
    centered:{
        textAlign: 'center'
    },
    noDisplay:{
        display: 'none',
    },
    imgPreview:{
        width: '100%',
        height: '100%',
        maxHeight: '250px',
        borderStyle: 'dashed',
        borderWidth: '1px',
        borderColor: '#ff9b01',
        padding: '3px',
    },
    imgPreviewContainer:{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        minHeight: '150px',
    },
    labelUploadImg:{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    addAPhotoIcon:{
        height: '35px',
        width: '35px',
    },
    removeImgIcon:{
        color: '#ff9b01',
        backgroundColor: '#000000',
        borderRadius: '50%',
    },
    errorIcon:{
        color: '#dc3545',
        height: '50px',
        width: '50px',
    },
    successIcon:{
        color: '#28a745',
        height: '50px',
        width: '50px',
    },
    paragraph:{
        fontSize: '15px',
    },
    finalMessageContainer:{
        border: 'dashed 2px #000000',
        borderRadius: '10px',
    },
    machineNumber:{
        fontSize: '25px',
    }
};

function NumberFormatCustom(props) {
    const { inputRef, onChange, defaultValue, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            isNumericString
            prefix={defaultValue + " "}
        />
    );
}

function NumberFormatHorometer(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            isNumericString
            suffix=" Horas"
        />
    );
}

function NumberFormatWeight(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            isNumericString
            suffix=" Kg"
        />
    );
}

function NumberFormatHP(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={0}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            isNumericString
            suffix=" HP a 2100 RPM"
        />
    );
}

function NumberFormatLitres(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={1}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            isNumericString
            suffix=" Litros"
        />
    );
}

function NumberFormatGalH(props) {
    const { inputRef, onChange, defaultValue, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            isNumericString
            suffix={" " + defaultValue}
        />
    );
}

function NumberFormatSubcategory(props) {
    const { inputRef, onChange, defaultValue, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            thousandSeparator={","}
            decimalSeparator={"."}
            fixedDecimalScale={true}
            isNumericString
            suffix={" " + defaultValue}
        />
    );
}

const steps = ['Paquetes', 'Información Pricipal', 'Detalles', 'Fotografías'];

class NewMachineComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeStep: 0,

            contact_name: '',
            contact_name_error: '',
            contact_last_name: '',
            contact_last_name_error: '',
            contact_country: '',
            contact_country_error: '',
            contact_country_dial_code: '',
            contact_phone_number_1: '',
            contact_phone_number_1_error: '',
            contact_phone_number_2: '',
            contact_phone_number_2_error: '',
            contact_email: '',
            contact_email_error: '',

            for_rent: false,
            for_sell: false,
            for_sell_for_rent_error: '',

            categories: [],
            category: null,
            gettingCategories: false,
            errorCategories: '',
            category_error: '',
            new_category: '',

            subcategories: [],
            subcategory: null,
            gettingSubcategories: false,
            errorSubcategories: '',
            subcategory_error: '',
            new_subcategory: '',

            brands: [],
            brand: null,
            gettingBrands: false,
            errorBrands: '',
            brand_error: '',
            new_brand: '',

            subcategoryFields: [],
            gettingSubcategoryFields: false,
            errorSubcategoryFields: '',

            model: '',
            model_error: '',
            year: '',
            year_error: '',
            location_country: null,
            location_country_error: '',
            location_city: '',
            location_city_error: '',
            condition: '',
            condition_error: '',
            horometer: '',
            horometer_error: '',
            currency: '',
            currency_error: '',

            sale_price: '',
            sale_price_error: '',
            rental_price_hour: '',
            rental_price_hour_error: '',
            rental_price_day: '',
            rental_price_day_error: '',
            rental_price_week: '',
            rental_price_week_error: '',
            rental_price_month: '',
            rental_price_month_error: '',
            is_negotiable: '',
            is_negotiable_error: '',
            delivery_terms: '',
            delivery_terms_error: '',
            delivery_country: null,
            delivery_country_error: '',
            delivery_place: '',
            delivery_place_error: '',

            currencies: [
                {
                    value : "USD",
                    name : "Dólar Estadounidense",
                    symbol: "$",
                },
                {
                    value : "COP",
                    name : "Peso Colombiano",
                    symbol: "$",
                }
            ],

            countries: all_countries,

            all_countries,

            serial_number: '',
            serial_number_error: '',
            useful_weight: '',
            useful_weight_error: '',
            fuel_type: null,
            fuel_type_unit: '',
            fuel_type_error: null,
            motor_brand: '',
            new_motor_brand: '',
            motor_brand_error: '',
            motor_model: '',
            motor_model_error: '',
            engine_potency: '',
            engine_potency_error: '',
            cylinders_liters: '',
            cylinders_liters_error: '',
            fuel_consumption: '',
            fuel_consumption_error: '',
            has_air_conditioner: null,
            has_air_conditioner_error: '',
            has_gps: null,
            has_gps_error: '',
            cabin_type: '',
            cabin_type_error: '',
            comments: '',
            comments_error: '',

            new_field_name: '',
            new_field_name_error: '',
            new_field_unit: '',
            new_field_unit_error: '',
            new_field_value: '',
            new_field_value_error: '',

            newSubcategoryFields: [],

            validateZeroErrorMessage: '',
            validateOneErrorMessage: '',
            validateTwoErrorMessage: '',
            validateThreeErrorMessage: '',

            uploadImageErrorMessage: '',

            creatingMachine: false,
            creatingMachineError: '',
            creatingMachineSuccess: false,

            machine_number: '',

            redirectToHome: false,
            isAuth: false,
            authUser: null,

            product: {},
            gettingProduct: false,
            errorProduct: '',
            product_images: [],

            deletingImage: false,
            deletingImageError: '',

            machine_to_edit: null,

            images: [],
        }
    }
    componentDidMount() {
        this.checkAuth();
        this._updateCategories();
        this._updateBrands();
        this.setState({machine_to_edit: this.props.url_id.maquinaId}, () => {
            this._getProduct();
        })
    }

    _getProduct(){
        let end_point = GET_MACHINE_FOR_SELL_BY_ID + this.state.machine_to_edit;
        let requestStatus = "gettingProduct";
        let errorVar = "errorProduct";
        let array = "product";
        this.setState({[requestStatus]:true})
        axios.get(API_URL + end_point).then((response) => {
            this.setState({[requestStatus]:false})
            if(response.data.success){
                this.setState({[array] : response.data.data}, () => {
                    this.setCurrentMachineValues() 
                })
            }else{
                this.setState({
                    [errorVar]: message_responses[response.data.message],
                })
            }
        }).catch((error) => {
            this.setState({[requestStatus]:false})
            if(error.response && error.response.data){
                this.setState({
                    [errorVar]: message_responses[error.response.data.message],
                })
                alert(message_responses[error.response.data.message]);
                this.setState({redirectToHome : true});
            }else{
                this.setState({
                    [errorVar]: "ERROR DE SERVIDOR",
                })
            }
        })
    }

    setCurrentMachineValues(){

        let subcategoryFields = this.processSubcategoryFields();

        let country = all_countries.filter((country) => country.label === this.state.product.contact_country)[0];
        let location_country = all_countries.filter((country) => country.label === this.state.product.location_country)[0];
        let delivery_country = all_countries.filter((country) => country.label === this.state.product.delivery_country)[0];

        let currency = this.state.currencies.filter((currency) => currency.value === this.state.product.currency)[0];

        let for_rent = this.state.product.for_rent === 0 ? false : true;
        let for_sell = this.state.product.for_sell === 0 ? false : true;

        this.setState({
            contact_name : this.state.product.contact_name,
            contact_last_name: this.state.product.contact_last_name,
            contact_country:  country,
            contact_country_dial_code: country.phone,
            contact_phone_number_1: this.state.product.contact_phone_number_1,
            contact_phone_number_2: this.state.product.contact_phone_number_2,
            contact_email: this.state.product.contact_email,
            for_rent,
            for_sell,

            category: this.state.product.new_category !== '' && this.state.product.new_category !== null && this.state.product.new_category !== undefined ? false : this.state.product.category,
            new_category: this.state.product.new_category,

            subcategory: this.state.product.new_subcategory !== '' && this.state.product.new_subcategory !== null && this.state.product.new_subcategory !== undefined ? false : this.state.product.subcategory,
            new_subcategory: this.state.product.new_subcategory,
            
            brand: this.state.product.new_brand !== '' && this.state.product.new_brand !== null && this.state.product.new_brand !== undefined ? false : this.state.product.brand,
            new_brand: this.state.product.new_brand,
            
            model: this.state.product.model,
            year: this.state.product.year,
            location_country,
            location_city: this.state.product.location_city,
            condition: this.state.product.condition,
            horometer: this.state.product.horometer,
            currency,
            currency_name: this.state.product.currency_name,
            currency_symbol: this.state.product.currency_symbol,
            sale_price: for_sell ? this.state.product.sale_price : null,
            rental_price_hour: for_rent ? this.state.product.rental_price_hour : null,
            rental_price_day: for_rent ? this.state.product.rental_price_day : null,
            rental_price_week: for_rent ? this.state.product.rental_price_week : null,
            rental_price_month: for_rent ? this.state.product.rental_price_month : null,
            is_negotiable: this.state.product.is_negotiable,
            delivery_terms: for_sell ? this.state.product.delivery_terms : null,
            delivery_country,
            delivery_place: for_sell ? this.state.product.delivery_place : null,
            serial_number: this.state.product.serial_number,
            useful_weight: this.state.product.useful_weight,
            fuel_type: this.state.product.fuel_type,
            fuel_type_unit: this.state.product.fuel_type_unit,
            motor_brand: this.state.product.new_motor_brand !== '' && this.state.product.new_motor_brand !== null && this.state.product.new_motor_brand !== undefined ? false : this.state.product.motor_brand,
            new_motor_brand: this.state.product.new_motor_brand,
            motor_model: this.state.product.motor_model,
            engine_potency: this.state.product.engine_potency,
            cylinders_liters: this.state.product.fuel_type !== 'Electrico' ? this.state.product.cylinders_liters : null,
            fuel_consumption: this.state.product.fuel_consumption,
            has_air_conditioner: this.state.product.has_air_conditioner,
            has_gps: this.state.product.has_gps,
            cabin_type: this.state.product.cabin_type,
            comments: this.state.product.comments,
            subcategoryFields: this.state.product.details,
            product_images: this.state.product.images,
            updated_by: this.state.authUser.id,
        }, () => {
            this._updateSubcategories();
        })
    }

    checkAuth(){
        let user = JSON.parse(localStorage.getItem('TMPData'));
        if(user){
            this.setState({isAuth: true, authUser: user.user})
        }else{
            this.setState({isAuth: false, authUser: null, redirectToHome: true})
        }
    }

    redirectToHome = () => {
        if (this.state.redirectToHome) {
            return <Redirect push to='/' />
        }
    }

    setImages = (value) => {
        this.setState({ images : value });
    }

    setUploadImageErrorMessage = (value) => {
        this.setState({ uploadImageErrorMessage: value })
    }

    _updateCategories(){
        let end_point = GET_CATEGORIES_ACTIVE;
        let requestStatus = "gettingCategories";
        let errorVar = "errorCategories";
        this.setState({[requestStatus]:true})
        axios.get(end_point).then((response) => {
            this.setState({[requestStatus]:false})
            if(response.data.success){
                //* WHAT HAPPENED IF THE REQUEST WAS SUCCESS *//
                this.setState({categories : response.data.data})
            }else{
                this.setState({
                    [errorVar]: message_responses[response.data.message],
                })
            }
        }).catch((error) => {
            this.setState({[requestStatus]:false})
            if(error.response && error.response.data){
                this.setState({
                    [errorVar]: message_responses[error.response.data.message],
                })
            }else{
                this.setState({
                    [errorVar]: "ERROR DE SERVIDOR",
                })
            }
        })
    }

    _updateSubcategories(){
        let category = this.state.categories.find((category) => category.name === this.state.category);
        if(category !== undefined){
            let end_point = GET_SUBCATEGORIES_BY_CATEGORY + category.id;
            let requestStatus = "gettingSubcategories";
            let errorVar = "errorSubCategories";
            this.setState({[requestStatus]:true})
            axios.get(API_URL + end_point).then((response) => {
                this.setState({[requestStatus]:false})
                if(response.data.success){
                    this.setState({subcategories : response.data.data})
                }else{
                    this.setState({
                        [errorVar]: message_responses[response.data.message],
                    })
                }
            }).catch((error) => {
                this.setState({[requestStatus]:false})
                if(error.response && error.response.data){
                    this.setState({
                        [errorVar]: message_responses[error.response.data.message],
                    })
                }else{
                    this.setState({
                        [errorVar]: "ERROR DE SERVIDOR",
                    })
                }
            })
        }else{
            this.setState({ subcategories : []})
        }
    }

    _updateBrands(){
        let end_point = GET_BRANDS_ACTIVE;
        let requestStatus = "gettingBrands";
        let errorVar = "errorBrands";
        let array = "brands";
        this.setState({[requestStatus]:true})
        axios.get(API_URL + end_point).then((response) => {
            this.setState({[requestStatus]:false})
            if(response.data.success){
                //* WHAT HAPPENED IF THE REQUEST WAS SUCCESS *//
                this.setState({[array] : response.data.data})
            }else{
                this.setState({
                    [errorVar]: message_responses[response.data.message],
                })
            }
        }).catch((error) => {
            this.setState({[requestStatus]:false})
            if(error.response && error.response.data){
                this.setState({
                    [errorVar]: message_responses[error.response.data.message],
                })
            }else{
                this.setState({
                    [errorVar]: "ERROR DE SERVIDOR",
                })
            }
        })
    }

    _updateSubcategoryFields(){
        let end_point = GET_SUBCATEGORY_FIELDS;
        let requestStatus = "gettingSubcategoryFields";
        let errorVar = "errorSubcategoryFields";
        let array = "subcategoryFields";
        let formData = {
            name: this.state.subcategory
        }

        this.setState({[requestStatus]:true})
        axios.post(API_URL + end_point, formData).then((response) => {
            this.setState({[requestStatus]:false})
            if(response.data.success){
                //* WHAT HAPPENED IF THE REQUEST WAS SUCCESS *//
                this.setState({[array] : response.data.data})
            }else{
                this.setState({
                    [errorVar]: message_responses[response.data.message],
                })
            }
        }).catch((error) => {
            this.setState({[requestStatus]:false})
            if(error.response && error.response.data){
                this.setState({
                    [errorVar]: message_responses[error.response.data.message],
                })
            }else{
                this.setState({
                    [errorVar]: "ERROR DE SERVIDOR",
                })
            }
        })
    }

    handleBack(){
        window.scrollTo(0, 0);
        this.setState({activeStep: this.state.activeStep - 1});
    };

    doEditMachine(){

        let formData = {
            machine_id: this.state.machine_to_edit,
            contact_name: this.state.contact_name,
            contact_last_name: this.state.contact_last_name,
            contact_country: this.state.contact_country.label,
            contact_country_dial_code: this.state.contact_country.phone,
            contact_phone_number_1: this.state.contact_phone_number_1,
            contact_phone_number_2: this.state.contact_phone_number_2,
            contact_email: this.state.contact_email,
            for_rent: this.state.for_rent,
            for_sell: this.state.for_sell,
            category: this.state.new_category !== '' && this.state.new_category !== null && this.state.new_category !== undefined ? false : this.state.category,
            new_category: this.state.new_category,
            subcategory: this.state.new_subcategory !== '' && this.state.new_subcategory !== null && this.state.new_subcategory !== undefined ? false : this.state.subcategory,
            new_subcategory: this.state.new_subcategory,
            brand: this.state.new_brand !== '' && this.state.new_brand !== null && this.state.new_brand !== undefined ? false : this.state.brand,
            new_brand: this.state.new_brand,
            model: this.state.model,
            year: this.state.year,
            location_country: this.state.location_country.label,
            location_city: this.state.location_city,
            condition: this.state.condition,
            horometer: this.state.horometer,
            currency: this.state.currency.value,
            currency_name: this.state.currency.name,
            currency_symbol: this.state.currency.symbol,
            sale_price: this.state.for_sell ? this.state.sale_price : null,
            rental_price_hour: this.state.for_rent ? this.state.rental_price_hour : null,
            rental_price_day: this.state.for_rent ? this.state.rental_price_day : null,
            rental_price_week: this.state.for_rent ? this.state.rental_price_week : null,
            rental_price_month: this.state.for_rent ? this.state.rental_price_month : null,
            is_negotiable: this.state.is_negotiable,
            delivery_terms: this.state.for_sell ? this.state.delivery_terms : null,
            delivery_country: this.state.for_sell ? this.state.delivery_country.label : null,
            delivery_place: this.state.for_sell ? this.state.delivery_place : null,
            serial_number: this.state.serial_number,
            useful_weight: this.state.useful_weight,
            fuel_type: this.state.fuel_type,
            fuel_type_unit: this.state.fuel_type_unit,
            motor_brand: this.state.new_motor_brand !== '' && this.state.new_motor_brand !== null && this.state.new_motor_brand !== undefined ? false : this.state.motor_brand,
            new_motor_brand: this.state.new_motor_brand,
            motor_model: this.state.motor_model,
            engine_potency: this.state.engine_potency,
            cylinders_liters: this.state.fuel_type !== 'Electrico' ? this.state.cylinders_liters : null,
            fuel_consumption: this.state.fuel_consumption,
            has_air_conditioner: this.state.has_air_conditioner,
            has_gps: this.state.has_gps,
            cabin_type: this.state.cabin_type,
            comments: this.state.comments,
            oldSubcategoryFields: this.state.subcategoryFields,
            newSubcategoryFields: this.state.newSubcategoryFields,
            images: this.state.images,
            created_by: this.state.authUser.id,
        }

        let end_point = EDIT_MACHINE;
        let requestStatus = "creatingMachine";
        let errorVar = "creatingMachineError";
        let successVar = "creatingMachineSuccess";
        this.setState({[requestStatus]:true});


        axios.post(API_URL + end_point, formData).then((response) => {
            if(response.data.success){
                this.setState({
                        [requestStatus]:false,
                        [successVar] : true,
                        machine_number : response.data.data.id,
                })
            }
        }).catch((error) => {
            this.setState({[requestStatus]:false})
            if(error.response && error.response.data){
                this.setState({
                    [errorVar]: message_responses[error.response.data.message],
                })
            }else{
                this.setState({
                    [errorVar]: "ERROR DE SERVIDOR",
                })
            }
        })
    }

    handleNext(){
      let currentStep = this.state.activeStep;
      let flag = false;
      switch (currentStep) {
          case 0:
            this.setState({validateZeroErrorMessage: ''})
            window.scrollTo(0, 0);
            flag = true;
            break;
          case 1:
            if(this.validateStepOne()){
                this.setState({validateOneErrorMessage: ''})
                window.scrollTo(0, 0);
                flag = true;
            }else{
                this.setState({validateOneErrorMessage: 'Oops, por favor verifica el formulario, al parecer falta algo.' })
            }
            break;
          case 2:
            if(this.validateStepTwo()){
                this.setState({validateTwoErrorMessage: ''})
                window.scrollTo(0, 0);
                flag = true;
            }else{
                this.setState({validateTwoErrorMessage: 'Oops, por favor verifica el formulario, al parecer falta algo.' })
            }
            break;
          case 3:
            if(this.validateStepThree()){
                this.setState({validateThreeErrorMessage: ''})
                this.doEditMachine();
                flag = true;
                window.scrollTo(0, 0);
            }else{
                this.setState({validateThreeErrorMessage: 'Oops, por favor verifica el formulario, al parecer falta algo.' })
            }
            break;
          default:
              break;
      }

      if(flag){
          this.setState({activeStep: this.state.activeStep + 1});
      }
  }

    processSubcategoryFields(){
        let newSubcategoryFields = this.state.newSubcategoryFields;
        let subcategoryFields = this.state.subcategoryFields;
        let arr = newSubcategoryFields.concat(subcategoryFields);
        return arr;
    }

    validateStepOne(){
        return this.validateNormalTextField('contact_name', 'contact_name_error') &&
            this.validateNormalTextField('contact_last_name', 'contact_last_name_error') &&
            this.validateNormalTextField('contact_country', 'contact_country_error') &&
            this.validateNormalTextField('contact_phone_number_1', 'contact_phone_number_1_error') &&
            this.validateNormalTextFieldLength('contact_phone_number_2', 'contact_phone_number_2_error', 64) &&
            this.validateNormalTextField('contact_email', 'contact_email_error') &&
            this.validateForSellAndForRent() &&
            this.validateAutocomplete('category', 'new_category','category_error') &&
            this.validateAutocomplete('subcategory', 'new_subcategory','subcategory_error') &&
            this.validateAutocomplete('brand', 'new_brand','brand_error') &&
            this.validateNormalTextField('model', 'model_error') &&
            this.validateNormalTextField('year', 'year_error') &&
            this.validateNormalTextField('location_country', 'location_country_error') &&
            this.validateNormalTextField('location_city', 'location_city_error') &&
            this.validateNormalTextField('condition', 'condition_error') &&
            this.validateNormalTextField('horometer', 'horometer_error') &&
            this.validateNormalTextField('currency', 'currency_error') &&
            this.validateNormalTextFieldIfSell('sale_price', 'sale_price_error') &&

            this.validateNormalTextFieldIfRent('rental_price_hour', 'rental_price_hour_error') &&
            this.validateNormalTextFieldIfRent('rental_price_day', 'rental_price_day_error') &&
            this.validateNormalTextFieldIfRent('rental_price_week', 'rental_price_week_error') &&
            this.validateNormalTextFieldIfRent('rental_price_month', 'rental_price_month_error') &&

            this.validateNormalTextField('is_negotiable', 'is_negotiable_error') &&

            this.validateNormalTextFieldIfSell('delivery_terms', 'delivery_terms_error') &&
            this.validateNormalTextFieldIfSell('delivery_country', 'delivery_country_error') &&
            this.validateNormalTextFieldIfSell('delivery_place', 'delivery_place_error');
    }

    validateStepTwo(){
        return this.validateNormalTextField('serial_number', 'serial_number_error') &&
            this.validateNormalTextField('useful_weight', 'useful_weight_error') &&
            this.validateNormalTextField('fuel_type', 'fuel_type_error') &&

            this.validateAutocomplete('motor_brand', 'new_motor_brand','motor_brand_error') &&

            this.validateNormalTextField('motor_model', 'motor_model_error') &&
            this.validateNormalTextField('engine_potency', 'engine_potency_error') &&
            this.validateNormalTextFieldIfElectric('cylinders_liters', 'cylinders_liters_error') &&
            this.validateNormalTextField('fuel_consumption', 'fuel_consumption_error') &&
            this.validateNormalTextField('has_air_conditioner', 'has_air_conditioner_error') &&
            this.validateNormalTextField('has_gps', 'has_gps_error') &&
            this.validateNormalTextField('cabin_type', 'cabin_type_error') &&
            this.validateNormalTextFieldLength('comments', 'comments_error', 512);
    }

    validateStepThree(){
        return this.validateUploadPhotoFiles();
    }

    validateUploadPhotoFiles(){
        let images_qty = this.state.images.length + this.state.product_images.length;
        if(images_qty < MIN_NUMBER_IMAGES_UPLOAD_FREE){
            this.setState({uploadImageErrorMessage:"La cantidad mínima de fotografías que puedes incluir es de " + MIN_NUMBER_IMAGES_UPLOAD_FREE + " fotografías."})
            return false;
        }else if(images_qty > MAX_NUMBER_IMAGES_UPLOAD_FREE){
            this.setState({uploadImageErrorMessage:"La cantidad máxima de fotografías que puedes incluir es de " + MAX_NUMBER_IMAGES_UPLOAD_FREE + " fotografías."})
            return false;
        }else{
            this.setState({uploadImageErrorMessage:""})
            return true;
        }
    }

    validateAddSubcategoryField(){
        return this.validateNormalTextField('new_field_name', 'new_field_name_error') &&
            this.validateNormalTextFieldLength('new_field_unit', 'new_field_unit_error', 64) &&
            this.validateNormalTextField('new_field_value', 'new_field_value_error');
    }

    validateNormalTextField(field, errorMessage){
        let value = this.state[field];
        if(value === '' || value === null || value === undefined){
            this.setState({[errorMessage] : "Este campo no puede estar vacío."})
            return false;
        }else if(value.length > 64 ){
            this.setState({[errorMessage] : "Este campo excede la longitud máxima de 64 caracteres."})
            return false;
        }else{
            this.setState({[errorMessage] : ""})
            return true;
        }
    }

    validateNormalTextFieldIfElectric(field, errorMessage){
        if(this.state.fuel_type !== 'Electrico'){
            let value = this.state[field];
            if(value === '' || value === null || value === undefined){
                this.setState({[errorMessage] : "Este campo no puede estar vacío."})
                return false;
            }else if(value.length > 64 ){
                this.setState({[errorMessage] : "Este campo excede la longitud máxima de 64 caracteres."})
                return false;
            }else{
                this.setState({[errorMessage] : ""})
                return true;
            }
        }else{
            this.setState({[errorMessage] : ""})
            return true;
        }
    }

    validateNormalTextFieldIfSell(field, errorMessage){
        if(this.state.for_sell){
            let value = this.state[field];
            if(value === '' || value === null || value === undefined){
                this.setState({[errorMessage] : "Este campo no puede estar vacío."})
                return false;
            }else if(value.length > 64 ){
                this.setState({[errorMessage] : "Este campo excede la longitud máxima de 64 caracteres."})
                return false;
            }else{
                this.setState({[errorMessage] : ""})
                return true;
            }
        }else{
            this.setState({[errorMessage] : ""})
            return true;
        }
    }

    validateNormalTextFieldIfRent(field, errorMessage){
        if(this.state.for_rent){
            let value = this.state[field];
            if(value === '' || value === null || value === undefined){
                this.setState({[errorMessage] : "Este campo no puede estar vacío."})
                return false;
            }else if(value.length > 64 ){
                this.setState({[errorMessage] : "Este campo excede la longitud máxima de 64 caracteres."})
                return false;
            }else{
                this.setState({[errorMessage] : ""})
                return true;
            }
        }else{
            this.setState({[errorMessage] : ""})
            return true;
        }
    }

    validateNormalTextFieldLength(field, errorMessage, length ){
        let value = this.state[field];
        if(value && value.length > length ){
            this.setState({[errorMessage] : "Este campo excede la longitud máxima de " + length + " caracteres."})
            return false;
        }else{
            this.setState({[errorMessage] : ""})
            return true;
        }
    }

    validateAutocomplete(field, new_field, errorMessage){
        let value = this.state[field];
        if(value === '' || value === null || value === undefined){
            let new_value = this.state[new_field];
            if(new_value !== '' && new_value !== null && new_value !== undefined){
                this.setState({[errorMessage] : ""})
                return true;
            }else{
                this.setState({[errorMessage] : "Este campo no puede estar vacío."})
                return false;
            }
        }else if(value.length > 64 ){
            this.setState({[errorMessage] : "Este campo excede la longitud máxima de 64 caracteres."})
            return false;
        }else{
            this.setState({[errorMessage] : ""})
            return true;
        }
    }

    validateForSellAndForRent(){
        if(this.state.for_rent === false && this.state.for_sell === false){
            this.setState({for_sell_for_rent_error : "Debes seleccionar al menos una de las dos opciones."})
            return false;
        }else{
            this.setState({for_sell_for_rent_error : ""})
            return true;
        }
    }

    handleCheckBoxChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    categoryClosed(){
        this.setState({ subcategory : null });
    }

    autocompleteChange(event, newValue, field){
        this.setState({ [field] : newValue });
    }

    categoryChanged(event, newValue){
        this.setState({
            category : newValue
        }, () => this._updateSubcategories());
    }

    categoryChangedOnInput(event, newValue){
        this.setState({ new_category : newValue ? newValue : '' })
    }

    subcategoryChanged(event, newValue){
        this.setState({ subcategory : newValue }, () => this._updateSubcategoryFields());
    }

    subcategoryChangedOnInput(event, newValue){
        this.setState({ new_subcategory : newValue ? newValue : '' })
    }

    brandChangedOnInput(event, newValue){
        this.setState({ new_brand : newValue ? newValue : '' })
    }

    motorBrandChangedOnInput(event, newValue){
        this.setState({ new_motor_brand : newValue ? newValue : '' })
    }

    textFieldChange(event){
        this.setState({ [event.target.name] : event.target.value });
    }

    textFieldFormattedChange = name => event => {
        if (event.target.value === '') {
            this.setState({
                [name]: event.target.value,
            });
        } else {
            this.setState({
                [name]: parseFloat(event.target.value),
            });
        }
    }

    selectChange(event, field){
        this.setState({ [field] : event.target.value });
    }

    selectChangeFuelType(event, field){
        let fuel_type_unit = '';
        if(event.target.value === 'Gasolina'){
            fuel_type_unit = 'gal/hora'
        }
        if(event.target.value === 'Diesel'){
            fuel_type_unit = 'gal/hora'
        }
        if(event.target.value === 'Gas'){
            fuel_type_unit = 'm3/hora'
        }
        if(event.target.value === 'Electrico'){
            fuel_type_unit = 'kW/hora'
        }

        this.setState({
            [field] : event.target.value,
            fuel_type_unit : fuel_type_unit
        });
    }

    subcategoryFieldChange = index => event => {
        let value;
        if (event.target.value === '') {
            value = event.target.value
        } else {
            value = parseFloat(event.target.value)
        }
        let subcategoryFields = this.state.subcategoryFields;
        subcategoryFields[parseInt(index)].value = value;
        this.setState({ subcategoryFields : subcategoryFields });
    }

    subcategoryFieldEventChange(event){
        let subcategoryFields = this.state.subcategoryFields;
        subcategoryFields[parseInt(event.target.name)].value = event.target.value;
        this.setState({ subcategoryFields : subcategoryFields });
    }

    countryToFlag(isoCode) {
        return typeof String.fromCodePoint !== 'undefined'
            ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
            : isoCode;
    }

    addSubcategoryField(){
        if(this.validateAddSubcategoryField()){
            let newSubcategoryField = {
                name: this.state.new_field_name,
                unit: this.state.new_field_unit,
                value: this.state.new_field_value,
            }

            let newSubcategoryFields = this.state.newSubcategoryFields;
            newSubcategoryFields.push(newSubcategoryField);
            this.setState({
                newSubcategoryFields : newSubcategoryFields,
                new_field_name: '',
                new_field_unit: '',
                new_field_value: '',
            })
        }
    }

    removeSubcategoryField(index){
        let newSubcategoryFields = this.state.newSubcategoryFields;
        newSubcategoryFields.splice(index, 1);
        this.setState({newSubcategoryFields : newSubcategoryFields})
    }

    removeProductImage = (id) => {
      const images_qty = this.state.product_images.length;

      if(images_qty - 1 < MIN_NUMBER_IMAGES_UPLOAD_FREE){
        alert("La cantidad mínima de fotografías que puedes incluir es de " + MIN_NUMBER_IMAGES_UPLOAD_FREE + " fotografías.")
        return false;
      }

      if(window.confirm("Esta es una operación destructiva, la imagen no podrá ser recuperada de ninguna manera, esta seguro de continuar?")){
          let end_point = DELETE_MACHINE_IMAGE + id;
          let requestStatus = "deletingImage";
          let errorVar = "deletingImageError";
          this.setState({[requestStatus]:true})
          axios.delete(API_URL + end_point).then((response) => {
              this.setState({[requestStatus]:false})
              if(response.data.success){
                  let product_images = this.state.product_images.filter((img) => img.id !== id );
                  this.setState({ product_images });
              }else{
                  this.setState({
                      [errorVar]: message_responses[response.data.message],
                  })
              }
          }).catch((error) => {
              this.setState({[requestStatus]:false})
              if(error.response && error.response.data){
                  this.setState({
                      [errorVar]: message_responses[error.response.data.message],
                  })
              }else{
                  this.setState({
                      [errorVar]: "ERROR DE SERVIDOR",
                  })
              }
          })
      }
    }

    render(){
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.redirectToHome()}
                <Loading loadingImage={Logo} loadingText={'Eliminando Fotografía.'} loadingState={this.state.deletingImage} />
                <Loading loadingImage={Logo} loadingText={'Guardando publicación.'} loadingState={this.state.creatingMachine} />
                <CssBaseline />
                <Container maxWidth="xl" className={classes.container}>
                    <Paper className={classes.paper}>
                        <Typography variant="h3" className={classes.title}>EDITAR PUBLICACIÓN</Typography>
                        <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {this.state.activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography variant="h5" gutterBottom>
                                        {
                                            this.state.creatingMachineError !== '' &&
                                                <React.Fragment>
                                                    <Grid item xs={12} className={classes.centered}>
                                                        <span className={classes.spanErrorMessage}>Error</span>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.centered}>
                                                        <ErrorIcon className={classes.errorIcon}/>
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.centered}>
                                                        <span>{this.state.creatingMachineError}</span>
                                                    </Grid>
                                                </React.Fragment>
                                        }
                                        {
                                            this.state.creatingMachineSuccess &&
                                                <React.Fragment>
                                                    <Grid item xs={12} className={classes.finalMessageContainer}>
                                                        <Grid item xs={12} className={classes.centered}>
                                                            <span className={classes.spanSuccessMessage}>¡Genial!</span>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.centered}>
                                                            <ThumbUpIcon className={classes.successIcon}/>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.centered}>
                                                            <p className={classes.paragraph}>Tu publicación ha sido actualizada con éxito, revisaremos los datos de tu publicación para asegurarnos de que todo marche correctamente. Una vez revisada y aceptada te lo haremos saber vía correo electrónico.</p>

                                                            <p className={classes.paragraph}>Se revisará la consistencia de los datos de tu maquinaria y la calidad de las imágenes, en caso de tener algún ajuste necesario para que tu publicación tenga la mejor calidad y consiga el mayor impacto posible, te lo haremos saber vía email o nos comunicaremos contigo telefónicamente.</p>

                                                            <p className={classes.paragraph}><strong>La número de referencia de tu publicación es el : # <span className={classes.machineNumber}>{this.state.machine_number}</span></strong></p>

                                                        </Grid>
                                                    </Grid>

                                                </React.Fragment>
                                        }

                                    </Typography>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    { this.state.activeStep === 0 && <Pricing variant='only-info' /> }
                                    {
                                        this.state.activeStep === 1 &&
                                        <React.Fragment>
                                            <Container maxWidth="lg">

                                                <Grid container spacing={2}>
                                                    <Typography variant="h3" className={classes.subtitle}>
                                                        <Tooltip title="Los datos suministrados en esta sección serán públicos y visibles una vez tu publicación sea aprobada, esto con el fin de que los compradores interesados en tu maquinaria puedan contactarte para concretar la venta de tu maquinaria.">
                                                            <HelpIcon color="primary"/>
                                                        </Tooltip>
                                                        &nbsp;Datos de contacto :
                                                    </Typography>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-contact_name"
                                                            label="Nombres"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="contact_name"
                                                            fullWidth
                                                            required
                                                            value={this.state.contact_name}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                        />
                                                        {
                                                            this.state.contact_name_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.contact_name_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-contact_last_name"
                                                            label="Apellidos"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="contact_last_name"
                                                            fullWidth
                                                            required
                                                            value={this.state.contact_last_name}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                        />
                                                        {
                                                            this.state.contact_last_name_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.contact_last_name_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <Autocomplete
                                                            id="country-select-demo-contact"
                                                            style={{ width: '100%' }}
                                                            options={this.state.all_countries}
                                                            onChange={(event, newValue) => this.autocompleteChange(event, newValue, 'contact_country')}
                                                            value={this.state.contact_country}
                                                            className={classes.select}
                                                            classes={{
                                                                option: classes.option,
                                                            }}
                                                            autoHighlight
                                                            getOptionLabel={option => option.label}
                                                            renderOption={option => (
                                                                <React.Fragment>
                                                                    <span>{this.countryToFlag(option.code)}</span>
                                                                    {option.label} ({option.code}) +{option.phone}
                                                                </React.Fragment>
                                                            )}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Pais de contacto"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: 'disabled', // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {
                                                            this.state.contact_country_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.contact_country_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-contact_phone_number_1"
                                                            label="Número de Teléfono Móvil 1"
                                                            type="number"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="contact_phone_number_1"
                                                            fullWidth
                                                            required
                                                            value={this.state.contact_phone_number_1}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            InputProps={{
                                                                startAdornment: this.state.contact_country !== '' && this.state.contact_country !== null && this.state.contact_country !== undefined ? <InputAdornment position="start">{"+" + this.state.contact_country.phone}</InputAdornment> : null,
                                                            }}
                                                        />
                                                        {
                                                            this.state.contact_phone_number_1_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.contact_phone_number_1_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-contact_phone_number_2"
                                                            label="Número de Teléfono Móvil 2"
                                                            type="number"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="contact_phone_number_2"
                                                            fullWidth
                                                            value={this.state.contact_phone_number_2}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            InputProps={{
                                                                startAdornment: this.state.contact_country !== '' && this.state.contact_country !== null && this.state.contact_country !== undefined ? <InputAdornment position="start">{"+" + this.state.contact_country.phone}</InputAdornment> : null,
                                                            }}
                                                        />
                                                        {
                                                            this.state.contact_phone_number_2_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.contact_phone_number_2_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-contact_email"
                                                            label="Correo Electronico"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="contact_email"
                                                            fullWidth
                                                            required
                                                            value={this.state.contact_email}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.contact_email_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.contact_email_error}</span>
                                                        }
                                                    </Grid>
                                                    <Typography variant="h3" className={classes.subtitle}>
                                                        <Tooltip title="Cuando publicas tu maquinaria pesada puedes elegir entre únicamente ponerla a la venta o únicamente ponerla en renta, pero también puedes ponerla tanto a la venta como en renta marcando las 2 opciones.">
                                                            <HelpIcon color="primary"/>
                                                        </Tooltip>
                                                        &nbsp;Tipo de publicación :
                                                    </Typography>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.for_sell}
                                                                    onChange={this.handleCheckBoxChange('for_sell')}
                                                                    value={this.state.for_sell}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Quieres vender tu maquinaria?"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.for_rent}
                                                                    onChange={this.handleCheckBoxChange('for_rent')}
                                                                    value={this.state.for_rent}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Quieres rentar tu maquinaria?"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {
                                                            this.state.for_sell_for_rent_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.for_sell_for_rent_error}</span>
                                                        }
                                                    </Grid>
                                                    <Typography variant="h3" className={classes.subtitle}>
                                                        <Tooltip title="La información principal es información necesaria para cualquier tipo de equipo pesado, además de brindar la información acerca del costo de compra de tu maquinaria pesada o del alquiler de cualquier equipo pesado.">
                                                            <HelpIcon color="primary"/>
                                                        </Tooltip>
                                                        &nbsp;Información principal :
                                                    </Typography>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <Autocomplete
                                                            id="free-solo-category"
                                                            freeSolo={false}
                                                            disabled={this.state.gettingCategories}
                                                            options={this.state.categories.map(option => option.name)}
                                                            onChange={(event, newValue) => this.categoryChanged(event, newValue)}
                                                            onInputChange={(event, newValue) => this.categoryChangedOnInput(event, newValue)}
                                                            onClose={() => this.categoryClosed()}
                                                            value={this.state.category}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Categoría"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {this.state.gettingCategories ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {
                                                            this.state.category_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.category_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <Autocomplete
                                                            id="free-solo-subcategory"
                                                            freeSolo={false}
                                                            disabled={(this.state.category === '' || this.state.category === null || this.state.category === undefined || this.state.gettingSubcategories) && (this.state.new_category === '' || this.state.new_category === null || this.state.new_category === undefined) }
                                                            options={this.state.subcategories.map(option => option.name)}
                                                            onChange={(event, newValue) => this.subcategoryChanged(event, newValue)}
                                                            onInputChange={(event, newValue) => this.subcategoryChangedOnInput(event, newValue)}
                                                            value={this.state.subcategory}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Subcategoría"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required

                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {this.state.gettingSubcategories ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {
                                                            this.state.subcategory_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.subcategory_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <Autocomplete
                                                            id="free-solo-brand"
                                                            freeSolo={false}
                                                            disabled={this.state.gettingBrands}
                                                            options={this.state.brands.map(option => option.name)}
                                                            onChange={(event, newValue) => this.autocompleteChange(event, newValue, 'brand')}
                                                            onInputChange={(event, newValue) => this.brandChangedOnInput(event, newValue)}
                                                            value={this.state.brand}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Marca"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {this.state.gettingBrands ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {
                                                            this.state.brand_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.brand_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-model"
                                                            label="Modelo"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="model"
                                                            fullWidth
                                                            required
                                                            value={this.state.model}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.model_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.model_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-year"
                                                            label="Año"
                                                            type="number"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="year"
                                                            fullWidth
                                                            required
                                                            value={this.state.year}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.year_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.year_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <Autocomplete
                                                            id="country-select-demo"
                                                            style={{ width: '100%' }}
                                                            options={this.state.countries}
                                                            onChange={(event, newValue) => this.autocompleteChange(event, newValue, 'location_country')}
                                                            value={this.state.location_country}
                                                            className={classes.select}
                                                            classes={{
                                                                option: classes.option,
                                                            }}
                                                            autoHighlight
                                                            getOptionLabel={option => option.label}
                                                            renderOption={option => (
                                                                <React.Fragment>
                                                                    <span>{this.countryToFlag(option.code)}</span>
                                                                    {option.label} ({option.code}) +{option.phone}
                                                                </React.Fragment>
                                                            )}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Pais"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: 'disabled', // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {
                                                            this.state.location_country_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.location_country_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-location_city"
                                                            label="Ciudad"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="location_city"
                                                            fullWidth
                                                            required
                                                            value={this.state.location_city}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.location_city_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.location_city_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <FormControl variant="outlined" className={classes.select} required>
                                                            <InputLabel id="demo-simple-select-outlined-label">
                                                                Condición
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={this.state.condition}
                                                                onChange={(event) => this.selectChange(event, 'condition')}
                                                                labelWidth={75}

                                                            >
                                                                <MenuItem value={'Nueva'}>Nueva</MenuItem>
                                                                <MenuItem value={'Usada'}>Usada</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            this.state.condition_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.condition_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-horometer"
                                                            label="Horometro (Horas)"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="horometer"
                                                            fullWidth
                                                            required
                                                            value={this.state.horometer}
                                                            onChange={ this.textFieldFormattedChange('horometer') }
                                                            InputProps={{
                                                                inputComponent: NumberFormatHorometer,
                                                            }}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.horometer_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.horometer_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <FormControl variant="outlined" className={classes.select} required>
                                                            <InputLabel id="demo-simple-select-outlined-label-currency">
                                                                Moneda
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label-currency"
                                                                id="demo-simple-select-outlined-currency"
                                                                value={this.state.currency}
                                                                onChange={(event) => this.selectChange(event, 'currency')}
                                                                labelWidth={65}
                                                            >
                                                                {
                                                                    this.state.currencies.map((currency) => (
                                                                        <MenuItem value={currency}>{"(" + currency.value + ") " + currency.symbol + " " + currency.name}</MenuItem>
                                                                    ))
                                                                }

                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            this.state.currency_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.currency_error}</span>
                                                        }
                                                    </Grid>
                                                    {
                                                        this.state.for_sell &&
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <TextField
                                                                    id="outlined-basic-sale-price"
                                                                    label="Precio de venta"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    name="sale_price"
                                                                    fullWidth
                                                                    required
                                                                    value={this.state.sale_price}
                                                                    defaultValue={this.state.currency.symbol}
                                                                    onChange={ this.textFieldFormattedChange('sale_price') }
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatCustom,
                                                                        startAdornment: this.state.currency.value !== '' ? <InputAdornment position="start">{this.state.currency.value}</InputAdornment> : null,
                                                                    }}
                                                                    inputProps={{
                                                                        autoComplete: 'disabled', // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                                {
                                                                    this.state.sale_price_error !== '' &&
                                                                    <span className={classes.spanErrorMessage}>{this.state.sale_price_error}</span>
                                                                }
                                                            </Grid>
                                                    }

                                                    {
                                                        this.state.for_rent &&
                                                            <React.Fragment>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <TextField
                                                                        id="outlined-basic-rental-price-hour"
                                                                        label="Precio de alquiler por hora"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        name="rental_price_hour"
                                                                        fullWidth
                                                                        value={this.state.rental_price_hour}
                                                                        defaultValue={this.state.currency.symbol}
                                                                        onChange={ this.textFieldFormattedChange('rental_price_hour') }
                                                                        InputProps={{
                                                                            inputComponent: NumberFormatCustom,
                                                                            startAdornment: this.state.currency.value !== '' ? <InputAdornment position="start">{this.state.currency.value}</InputAdornment> : null,
                                                                        }}
                                                                        inputProps={{
                                                                            autoComplete: 'disabled', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                    {
                                                                        this.state.rental_price_hour_error !== '' &&
                                                                        <span className={classes.spanErrorMessage}>{this.state.rental_price_hour_error}</span>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <TextField
                                                                        id="outlined-basic-rental-price-day"
                                                                        label="Precio de alquiler por dia"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        name="rental_price_day"
                                                                        fullWidth
                                                                        value={this.state.rental_price_day}
                                                                        defaultValue={this.state.currency.symbol}
                                                                        onChange={ this.textFieldFormattedChange('rental_price_day') }
                                                                        InputProps={{
                                                                            inputComponent: NumberFormatCustom,
                                                                            startAdornment: this.state.currency.value !== '' ? <InputAdornment position="start">{this.state.currency.value}</InputAdornment> : null,
                                                                        }}
                                                                        inputProps={{
                                                                            autoComplete: 'disabled', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                    {
                                                                        this.state.rental_price_day_error !== '' &&
                                                                        <span className={classes.spanErrorMessage}>{this.state.rental_price_day_error}</span>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <TextField
                                                                        id="outlined-basic-rental-price-week"
                                                                        label="Precio de alquiler por semana"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        name="rental_price_week"
                                                                        fullWidth
                                                                        value={this.state.rental_price_week}
                                                                        defaultValue={this.state.currency.symbol}
                                                                        onChange={ this.textFieldFormattedChange('rental_price_week') }
                                                                        InputProps={{
                                                                            inputComponent: NumberFormatCustom,
                                                                            startAdornment: this.state.currency.value !== '' ? <InputAdornment position="start">{this.state.currency.value}</InputAdornment> : null,
                                                                        }}
                                                                        inputProps={{
                                                                            autoComplete: 'disabled', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                    {
                                                                        this.state.rental_price_week_error !== '' &&
                                                                        <span className={classes.spanErrorMessage}>{this.state.rental_price_week_error}</span>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <TextField
                                                                        id="outlined-basic-rental-price-month"
                                                                        label="Precio de alquiler por mes"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        name="rental_price_month"
                                                                        fullWidth
                                                                        value={this.state.rental_price_month}
                                                                        defaultValue={this.state.currency.symbol}
                                                                        onChange={ this.textFieldFormattedChange('rental_price_month') }
                                                                        InputProps={{
                                                                            inputComponent: NumberFormatCustom,
                                                                            startAdornment: this.state.currency.value !== '' ? <InputAdornment position="start">{this.state.currency.value}</InputAdornment> : null,
                                                                        }}
                                                                        inputProps={{
                                                                            autoComplete: 'disabled', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                    {
                                                                        this.state.rental_price_month_error !== '' &&
                                                                        <span className={classes.spanErrorMessage}>{this.state.rental_price_month_error}</span>
                                                                    }
                                                                </Grid>
                                                            </React.Fragment>
                                                    }

                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <FormControl variant="outlined" className={classes.select} required>
                                                            <InputLabel id="demo-simple-select-outlined-label-is-negotiable">
                                                                Precios negociables?
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label-is-negotiable"
                                                                id="demo-simple-select-outlined-is-negotiable"
                                                                value={this.state.is_negotiable}
                                                                onChange={(event) => this.selectChange(event, 'is_negotiable')}
                                                                labelWidth={150}
                                                            >
                                                                <MenuItem value={'Si'}>Si</MenuItem>
                                                                <MenuItem value={'No'}>No</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            this.state.is_negotiable_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.is_negotiable_error}</span>
                                                        }
                                                    </Grid>

                                                    {
                                                        this.state.for_sell &&
                                                            <React.Fragment>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <FormControl variant="outlined" className={classes.select} required>
                                                                        <InputLabel id="demo-simple-select-outlined-label-delivery_terms">
                                                                            Términos de comercio internacional
                                                                        </InputLabel>

                                                                        <Select
                                                                            labelId="demo-simple-select-outlined-label-delivery_terms"
                                                                            id="demo-simple-select-outlined-delivery_terms"
                                                                            value={this.state.delivery_terms}
                                                                            onChange={(event) => this.selectChange(event, 'delivery_terms')}
                                                                            labelWidth={280}
                                                                        >
                                                                            <MenuItem value={'EXW'}>EXW (Ex Works)</MenuItem>
                                                                            <MenuItem value={'FCA'}>FCA (Free Carrier)</MenuItem>
                                                                            <MenuItem value={'FAS'}>FAS (Free Alongside Ship)</MenuItem>
                                                                            <MenuItem value={'FOB'}>FOB (Free On Board)</MenuItem>
                                                                            <MenuItem value={'CFR'}>CFR (Cost and Freight)</MenuItem>
                                                                            <MenuItem value={'CPT'}>CPT (Carriage Paid To)</MenuItem>
                                                                            <MenuItem value={'CIF'}>CIF (Cost Insurance and Freight)</MenuItem>
                                                                            <MenuItem value={'CIP'}>CIP (Carriage and Insurance Paid)</MenuItem>
                                                                            <MenuItem value={'DAT'}>DAT (Delivered At Terminal)</MenuItem>
                                                                            <MenuItem value={'DDP'}>DDP (Delivery Duty Paid)</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                    <Tooltip title="¿Que son los términos de comercio internacional?">
                                                                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                                        <a href="https://vicentferrer.com/incoterms/" target="_blank">
                                                                            <HelpIcon color="primary"/>
                                                                        </a>
                                                                    </Tooltip>
                                                                    {
                                                                        this.state.delivery_terms_error !== '' &&
                                                                        <span className={classes.spanErrorMessage}>{this.state.delivery_terms_error}</span>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <Autocomplete
                                                                        id="country-select-demo-delivery-country"
                                                                        style={{ width: '100%' }}
                                                                        options={this.state.all_countries}
                                                                        onChange={(event, newValue) => this.autocompleteChange(event, newValue, 'delivery_country')}
                                                                        value={this.state.delivery_country}
                                                                        className={classes.select}
                                                                        classes={{
                                                                            option: classes.option,
                                                                        }}
                                                                        autoHighlight
                                                                        getOptionLabel={option => option.label}
                                                                        renderOption={option => (
                                                                            <React.Fragment>
                                                                                <span>{this.countryToFlag(option.code)}</span>
                                                                                {option.label} ({option.code}) +{option.phone}
                                                                            </React.Fragment>
                                                                        )}
                                                                        renderInput={params => (
                                                                            <TextField
                                                                                {...params}
                                                                                label="Pais de entrega"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    autoComplete: 'disabled', // disable autocomplete and autofill
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {
                                                                        this.state.delivery_country_error !== '' &&
                                                                        <span className={classes.spanErrorMessage}>{this.state.delivery_country_error}</span>
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <TextField
                                                                        id="outlined-basic-delivery_place"
                                                                        label="Lugar de entrega"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        name="delivery_place"
                                                                        fullWidth
                                                                        value={this.state.delivery_place}
                                                                        onChange={(event) => this.textFieldChange(event)}
                                                                        inputProps={{
                                                                            autoComplete: 'disabled', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                    <Tooltip title="Por ejemplo el puerto de entrega (Puerto de Buenaventura)">
                                                                        <HelpIcon color="primary"/>
                                                                    </Tooltip>
                                                                    {
                                                                        this.state.delivery_place_error !== '' &&
                                                                        <span className={classes.spanErrorMessage}>{this.state.delivery_place_error}</span>
                                                                    }
                                                                </Grid>
                                                            </React.Fragment>
                                                    }

                                                </Grid>
                                            </Container>

                                        </React.Fragment>
                                    }
                                    {
                                        this.state.activeStep === 2 &&
                                        <React.Fragment>
                                            <Container maxWidth="lg">
                                                <Grid container spacing={2}>
                                                    <Typography variant="h3" className={classes.subtitle}>
                                                        <Tooltip title="La información general es información disponible para cualquier tipo de equipo pesado, además de brindar la información acerca de las características más generales de la maquinaria pesada.">
                                                            <HelpIcon color="primary"/>
                                                        </Tooltip>
                                                        &nbsp;Información general :
                                                    </Typography>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-serial_number"
                                                            label="Serial"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="serial_number"
                                                            fullWidth
                                                            required
                                                            value={this.state.serial_number}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.serial_number_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.serial_number_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-useful_weight"
                                                            label="Peso útil del equipo (Kg)"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="useful_weight"
                                                            fullWidth
                                                            required
                                                            value={this.state.useful_weight}
                                                            onChange={ this.textFieldFormattedChange('useful_weight') }
                                                            InputProps={{
                                                                inputComponent: NumberFormatWeight,
                                                            }}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.useful_weight_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.useful_weight_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <FormControl variant="outlined" className={classes.select} required>
                                                            <InputLabel id="demo-simple-select-outlined-label-fuel_type">
                                                                Tipo de Combustible
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label-fuel_type"
                                                                id="demo-simple-select-outlined-fuel_type"
                                                                value={this.state.fuel_type}
                                                                onChange={(event) => this.selectChangeFuelType(event, 'fuel_type')}
                                                                labelWidth={150}
                                                            >
                                                                <MenuItem value={'Diesel'}>Diesel</MenuItem>
                                                                <MenuItem value={'Gasolina'}>Gasolina</MenuItem>
                                                                <MenuItem value={'Gas'}>Gas</MenuItem>
                                                                <MenuItem value={'Electrico'}>Electrico</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            this.state.fuel_type_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.fuel_type_error}</span>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <Autocomplete
                                                            id="free-solo-motor-brand"
                                                            freeSolo={false}
                                                            disabled={this.state.gettingBrands}
                                                            options={this.state.brands.map(option => option.name)}
                                                            onChange={(event, newValue) => this.autocompleteChange(event, newValue, 'motor_brand')}
                                                            onInputChange={(event, newValue) => this.motorBrandChangedOnInput(event, newValue)}
                                                            value={this.state.motor_brand}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Marca del Motor"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        autoComplete: 'disabled', // disable autocomplete and autofill
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {this.state.gettingBrands ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {
                                                            this.state.motor_brand_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.motor_brand_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-useful_weight"
                                                            label="Modelo del motor"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="motor_model"
                                                            fullWidth
                                                            required
                                                            value={this.state.motor_model}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.motor_model_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.motor_model_error}</span>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-engine_potency"
                                                            label="Potencia del motor (HP) A 2100 RPM"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="engine_potency"
                                                            fullWidth
                                                            required
                                                            value={this.state.engine_potency}
                                                            onChange={ this.textFieldFormattedChange('engine_potency') }
                                                            InputProps={{
                                                                inputComponent: NumberFormatHP,
                                                            }}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.engine_potency_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.engine_potency_error}</span>
                                                        }
                                                    </Grid>

                                                    {
                                                        this.state.fuel_type !== 'Electrico' &&
                                                        <Grid item xs={12} sm={6} md={3}>
                                                            <TextField
                                                                id="outlined-basic-cylinders_liters"
                                                                label="Cilindrada del motor (Litros)"
                                                                margin="normal"
                                                                variant="outlined"
                                                                name="cylinders_liters"
                                                                fullWidth
                                                                required
                                                                value={this.state.cylinders_liters}
                                                                onChange={ this.textFieldFormattedChange('cylinders_liters') }
                                                                InputProps={{
                                                                    inputComponent: NumberFormatLitres,
                                                                }}
                                                                inputProps={{
                                                                    autoComplete: 'disabled', // disable autocomplete and autofill
                                                                }}
                                                            />
                                                            {
                                                                this.state.cylinders_liters_error !== '' &&
                                                                <span className={classes.spanErrorMessage}>{this.state.cylinders_liters_error}</span>
                                                            }
                                                        </Grid>
                                                    }


                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-fuel_consumption"
                                                            label={"Consumo de Combustible (" + this.state.fuel_type_unit +")"}
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="fuel_consumption"
                                                            fullWidth
                                                            required
                                                            defaultValue={this.state.fuel_type_unit}
                                                            value={this.state.fuel_consumption}
                                                            onChange={ this.textFieldFormattedChange('fuel_consumption') }
                                                            InputProps={{
                                                                inputComponent: NumberFormatGalH,
                                                            }}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.fuel_consumption_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.fuel_consumption_error}</span>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <FormControl variant="outlined" className={classes.select} required>
                                                            <InputLabel id="demo-simple-select-outlined-label-has_air_conditioner">
                                                                Posee aire acondicionado?
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label-has_air_conditioner"
                                                                id="demo-simple-select-outlined-has_air_conditioner"
                                                                value={this.state.has_air_conditioner}
                                                                onChange={(event) => this.selectChange(event, 'has_air_conditioner')}
                                                                labelWidth={200}
                                                            >
                                                                <MenuItem value={'Si'}>Si</MenuItem>
                                                                <MenuItem value={'No'}>No</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            this.state.has_air_conditioner_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.has_air_conditioner_error}</span>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <FormControl variant="outlined" className={classes.select} required>
                                                            <InputLabel id="demo-simple-select-outlined-label-has_gps">
                                                                Posee GPS?
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label-has_gps"
                                                                id="demo-simple-select-outlined-has_gps"
                                                                value={this.state.has_gps}
                                                                onChange={(event) => this.selectChange(event, 'has_gps')}
                                                                labelWidth={100}
                                                            >
                                                                <MenuItem value={'Si'}>Si</MenuItem>
                                                                <MenuItem value={'No'}>No</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            this.state.has_gps_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.has_gps_error}</span>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <FormControl variant="outlined" className={classes.select} required>
                                                            <InputLabel id="demo-simple-select-outlined-label-cabin_type">
                                                                Tipo de cabina (ROPS)?
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label-cabin_type"
                                                                id="demo-simple-select-outlined-cabin_type"
                                                                value={this.state.cabin_type}
                                                                onChange={(event) => this.selectChange(event, 'cabin_type')}
                                                                labelWidth={190}
                                                            >
                                                                <MenuItem value={'Cerrada'}>Cerrada</MenuItem>
                                                                <MenuItem value={'Abierta'}>Abierta</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {
                                                            this.state.cabin_type_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.cabin_type_error}</span>
                                                        }
                                                    </Grid>
                                                    {
                                                        this.state.subcategoryFields.length > 0 &&
                                                            <Typography variant="h3" className={classes.subtitle}>
                                                                <Tooltip title="Esta información es opcional, proporcionar esta información te ayudará a concretar una venta o la renta de tu maquinaria más fácilmente. La información específica es información disponible para equipos pesados específicos con ciertas características con las que no cuenta otro tipo de maquinaria pesada.">
                                                                    <HelpIcon color="primary"/>
                                                                </Tooltip>
                                                                &nbsp;Información especifica :
                                                            </Typography>
                                                    }

                                                    {this.state.subcategoryFields.map((field, index) => (
                                                        field.unit !== null ?
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <TextField
                                                                    id="outlined-basic-subcategory-field"
                                                                    label={field.field}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    name={index}
                                                                    fullWidth
                                                                    required
                                                                    defaultValue={field.unit !== null ? field.unit : ''}
                                                                    value={this.state.subcategoryFields[index].value}
                                                                    onChange={ this.subcategoryFieldChange(index) }
                                                                    InputProps={{
                                                                        inputComponent: NumberFormatSubcategory,
                                                                    }}
                                                                    inputProps={{
                                                                        autoComplete: 'disabled', // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            </Grid>
                                                            :
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <TextField
                                                                    id="outlined-basic-subcategory-field"
                                                                    label={field.name}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    name={index}
                                                                    fullWidth
                                                                    required
                                                                    defaultValue={field.unit !== null ? field.unit : ''}
                                                                    value={this.state.subcategoryFields[index].value}
                                                                    onChange={ (event) => this.subcategoryFieldEventChange(event) }
                                                                    inputProps={{
                                                                        autoComplete: 'disabled', // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            </Grid>
                                                    ))}
                                                    <Typography variant="h3" className={classes.subtitle}>
                                                        <Tooltip title={
                                                            <React.Fragment>
                                                                La información adicional son características que no has mencionado anteriormente, que aportan valor a tu maquinaria y que son útiles para las personas interesadas en comprar tu equipo pesado.
                                                                <br/><br/>Puedes utilizar esta sección para agregar algún tipo de herramienta adicional con la que cuenta tu equipo pesado, por ejemplo si tu maquinaria cuenta con un kit de herramientas, puedes agregarlos en esta sección de la siguiente manera:
                                                                <br/><br/><strong>Nombre de la característica:</strong> Kit de herramientas
                                                                <br/><strong>Unidad de medida:</strong> La dejas en blanco ya que para este caso no aplica
                                                                <br/><strong>Valor:</strong> Si
                                                            </React.Fragment>
                                                            }
                                                        >
                                                            <HelpIcon color="primary"/>
                                                        </Tooltip>
                                                        &nbsp;Información adicional :
                                                    </Typography>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-new_field_name"
                                                            label="Nombre de la característica"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="new_field_name"
                                                            fullWidth
                                                            required
                                                            value={this.state.new_field_name}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.new_field_name_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.new_field_name_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-new_field_unit"
                                                            label="Unidad de medida (Opcional)"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="new_field_unit"
                                                            fullWidth
                                                            value={this.state.new_field_unit}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.new_field_unit_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.new_field_unit_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <TextField
                                                            id="outlined-basic-new_field_value"
                                                            label="Valor"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="new_field_value"
                                                            fullWidth
                                                            required
                                                            value={this.state.new_field_value}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.new_field_value_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.new_field_value_error}</span>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={3}>
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.addSubcategoryField.bind(this)}
                                                            className={classes.newFieldButton}>
                                                            <AddCircleIcon/> &nbsp; Agregar
                                                        </Button>
                                                    </Grid>

                                                    {
                                                        this.state.newSubcategoryFields.map((subcategoryField, index) =>
                                                            <React.Fragment>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <TextField
                                                                        id="outlined-basic-new_field_name"
                                                                        label="Nombre de la característica"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        name={index}
                                                                        fullWidth
                                                                        disabled
                                                                        value={subcategoryField.name}
                                                                        inputProps={{
                                                                            autoComplete: 'disabled', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <TextField
                                                                        id="outlined-basic-new_field_unit"
                                                                        label="Unidad de medida (Opcional)"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        name="new_field_unit"
                                                                        fullWidth
                                                                        disabled
                                                                        value={subcategoryField.unit}
                                                                        inputProps={{
                                                                            autoComplete: 'disabled', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <TextField
                                                                        id="outlined-basic-new_field_value"
                                                                        label="Valor"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        name="new_field_value"
                                                                        fullWidth
                                                                        disabled
                                                                        value={subcategoryField.value}
                                                                        inputProps={{
                                                                            autoComplete: 'disabled', // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={3}>
                                                                    <Button
                                                                        fullWidth
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        onClick={() => this.removeSubcategoryField(index)}
                                                                        className={classes.newFieldButton}>
                                                                        <DeleteForeverIcon/> &nbsp; Eliminar
                                                                    </Button>
                                                                </Grid>
                                                            </React.Fragment>
                                                        )
                                                    }


                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="outlined-multiline-static-comments"
                                                            label="Comentarios adicionales"
                                                            multiline
                                                            rows="4"
                                                            margin="normal"
                                                            variant="outlined"
                                                            name="comments"
                                                            fullWidth
                                                            value={this.state.comments}
                                                            onChange={(event) => this.textFieldChange(event)}
                                                            inputProps={{
                                                                autoComplete: 'disabled', // disable autocomplete and autofill
                                                            }}
                                                        />
                                                        {
                                                            this.state.comments_error !== '' &&
                                                            <span className={classes.spanErrorMessage}>{this.state.comments_error}</span>
                                                        }
                                                    </Grid>


                                                </Grid>
                                            </Container>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.activeStep === 3 &&
                                        <React.Fragment>
                                            <Container maxWidth="lg">
                                                <Grid container spacing={2}>
                                                    <Typography variant="h3" className={classes.subtitle}>
                                                        <Tooltip title="Los datos suministrados en esta sección serán públicos y visibles una vez tu publicación sea aprobada, esto con el fin de que los compradores interesados en tu maquinaria puedan contactarte para concretar la venta de tu maquinaria.">
                                                            <HelpIcon color="primary"/>
                                                        </Tooltip>
                                                        &nbsp;Fotografías :
                                                    </Typography>

                                                    {
                                                        this.state.product_images.map((image, index) => (
                                                            <Grid item xs={12} sm={6} md={4} className={classes.imgPreviewContainer} key={index} >
                                                                <img src={image.path} alt="image_preview" className={classes.imgPreview} />
                                                                <div className={classes.buttonOverImage}>
                                                                    <HighlightOffIcon
                                                                        className={classes.removeImgIcon}
                                                                        onClick={() => this.removeProductImage(image.id, image.path)}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        ))
                                                    }

                                                    <CropImages
                                                        setImages={this.setImages} 
                                                        images={this.state.images}
                                                        maxImageSize={MAX_UPLOAD_IMAGE_SIZE_BYTES}
                                                        maxImages={MAX_NUMBER_IMAGES_UPLOAD_FREE}
                                                        setUploadImageErrorMessage={this.setUploadImageErrorMessage}
                                                    />
                                                    
                                                    <Grid item xs={12} justify='center'>
                                                        <Grid item xs={12} md={6} lg={3}>
                                                            <Link to={{ pathname: "https://tmp-production-bucket.s3.amazonaws.com/assets/es/manual_fotografia.pdf" }} target="_blank" download>
                                                                <Button
                                                                    fullWidth
                                                                    variant="contained"
                                                                    color="primary"
                                                                    className={classes.button}
                                                                >
                                                                    <CloudDownloadIcon/> &nbsp; Descargar manual de fotografía
                                                                </Button>
                                                            </Link>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} className={classes.centered}>
                                                        {
                                                            this.state.uploadImageErrorMessage !== '' &&
                                                            <div className={classes.spanErrorMessage}>{this.state.uploadImageErrorMessage}</div>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </React.Fragment>
                                    }

                                    <Grid item xs={12} justify='center' alignContent='center' alignItems='center' className={classes.buttonContainer}>
                                        {this.state.activeStep !== 0 && (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={this.handleBack.bind(this)}
                                                className={classes.button}>
                                                <ArrowBackIosIcon/> Anterior
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleNext.bind(this)}
                                            className={classes.button}
                                        >
                                            {this.state.activeStep === steps.length - 1 ?
                                                <React.Fragment>
                                                    Publicar <PublicIcon/>
                                                </React.Fragment>:
                                                <React.Fragment>
                                                    Siguiente <ArrowForwardIosIcon/>
                                                </React.Fragment>
                                            }
                                        </Button>

                                    </Grid>
                                    <Grid item xs={12} className={classes.centered}>
                                        {
                                            this.state.validateZeroErrorMessage !== '' &&
                                            <span className={classes.spanErrorMessage}>{this.state.validateZeroErrorMessage}</span>
                                        }
                                        {
                                            this.state.validateOneErrorMessage !== '' &&
                                            <span className={classes.spanErrorMessage}>{this.state.validateOneErrorMessage}</span>
                                        }
                                        {
                                            this.state.validateTwoErrorMessage !== '' &&
                                            <span className={classes.spanErrorMessage}>{this.state.validateTwoErrorMessage}</span>
                                        }
                                        {
                                            this.state.validateThreeErrorMessage !== '' &&
                                            <span className={classes.spanErrorMessage}>{this.state.validateThreeErrorMessage}</span>
                                        }
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </Container>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(NewMachineComponent);