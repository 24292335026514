import React, { Component, Fragment } from 'react';
import Header from '../../components/Header/Header';
import PasswordRecoveryComponent from "../../components/PasswordRecoveryComponent/PasswordRecoveryComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";


class PasswordRecovery extends Component{
    render(){
        return(
            <Fragment>
                <Header/>
                <PasswordRecoveryComponent/>
                <FooterComponent/>
            </Fragment>
        );
    }
}

export default PasswordRecovery;