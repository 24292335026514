import React, { useState, useEffect, createRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './CropImages.css';
import { getBase64 } from '../Utils/utils';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { MAX_UPLOAD_IMAGE_SIZE_BYTES } from "../../SETTINGS";

const CropImages = props => {

  const { 
    images, 
    setImages,
    setUploadImageErrorMessage,
  } = props;

  const [upImgArray, setUpImgArray] = useState([]);
  const [imgRefArray, setImgRefArray] = useState([]);
  const [previewCanvasRefArray, setPreviewCanvasRefArray] = useState([]);
  const [cropArray, setCropArray] = useState([]);
  const [completedCropArray, setCompletedCropArray] = useState([]);

  const MIN_WIDTH = 80;
  const MIN_HEIGHT = 45;

  const removeImage = i => {

    const removeFromArray = (array, index) => {
      const arrayAux = [...array];
      arrayAux.splice(index, 1);
      return arrayAux
    }

    setImgRefArray(removeFromArray(imgRefArray, i))
    setPreviewCanvasRefArray(removeFromArray(previewCanvasRefArray, i))
    setCropArray(removeFromArray(cropArray, i))
    setCompletedCropArray(removeFromArray(completedCropArray, i))
    setImages(removeFromArray(images, i))
    setUpImgArray(removeFromArray(upImgArray, i))

  }

  const generateDownload = (canvas, crop, i) => {

    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob( async blob => {
      const previewUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = `imageGeneratedTMP_${i}.png`;
      anchor.href = URL.createObjectURL(blob);
      anchor.click();
      window.URL.revokeObjectURL(previewUrl);
    }, 'image/png', 1);
  }

  const generateFile = async (canvas, crop, i) => {
    if (!crop || !canvas) {
      return;
    }

    try {
      const blob = await new Promise(resolve => canvas.toBlob(resolve));

      return {
        file: URL.createObjectURL(blob),
        name: `TMP_image_generated_${i}.png`,
        base64: await getBase64(blob),
      }
    }catch{
      return;
    }
  }


  const onSelectFileArray = async (e) => {
    
    if (e.target.files && e.target.files.length > 0) {

      const filesArray = Array.from(e.target.files)

      const types = ['image/png', 'image/jpeg', 'image/gif']

      let upImgArray_ = await Promise.all(
        filesArray.map(async (file) => {

          if (types.every(type => file.type !== type)) {
            // TODO : ALERT
            alert(`${file.type} no es un formato soportado.`)
            setUploadImageErrorMessage(<>{file.type} no es un formato soportado.</>);
            throw `Error: Unsupported file format: '${file.type}`;
          }

          if (file.size > MAX_UPLOAD_IMAGE_SIZE_BYTES) {
            alert(`${file.name} es demasiado grande, el máximo tamaño permitido es ${MAX_UPLOAD_IMAGE_SIZE_BYTES/1000000}MB.`)
            setUploadImageErrorMessage(<>{file.name} es demasiado grande, el máximo tamaño permitido es {MAX_UPLOAD_IMAGE_SIZE_BYTES/1000000}MB.</>)
            throw `Error: File size excceded: '${file.name}`;
          }

          return URL.createObjectURL(file);
        })
      )

      if(upImgArray.length > 0){

        let auxArray = []

        auxArray = Array(upImgArray_.length).fill(createRef())
        setImgRefArray([...imgRefArray, ...auxArray])
        auxArray = Array(upImgArray_.length).fill(createRef())
        setPreviewCanvasRefArray([...previewCanvasRefArray, ...auxArray])

        setCropArray([...cropArray, ...Array(upImgArray_.length).fill({x: 0, y: 0, width: MIN_WIDTH, height: MIN_HEIGHT, unit: "px", aspect: 16 / 9 })])
        setCompletedCropArray([...completedCropArray, ...Array(upImgArray_.length).fill(null)])
        setImages([...images, ...Array(upImgArray_.length).fill(null)])
        setUpImgArray([...upImgArray, ...upImgArray_])
      }else{
        setImgRefArray(imgRef => (Array(upImgArray_.length).fill().map((_, i) => imgRef[i] || createRef())))
        setPreviewCanvasRefArray(imgRef => (Array(upImgArray_.length).fill().map((_, i) => imgRef[i] || createRef())))
        setCropArray(Array(upImgArray_.length).fill({x: 0, y: 0, width: MIN_WIDTH, height: MIN_HEIGHT, unit: "px", aspect: 16 / 9 }))
        setCompletedCropArray(Array(upImgArray_.length).fill(null))
        setImages(Array(upImgArray_.length).fill(null))
        setUpImgArray(upImgArray_)
      }

      setUploadImageErrorMessage('')
      

      

    }
  };

  const onLoadArray = (img, i) => {
    imgRefArray[i].current = img;
  };

  useEffect(() => {

    completedCropArray.forEach((completedCrop_, i) => {

      if (!completedCrop_ || !previewCanvasRefArray[i] || !previewCanvasRefArray[i].current || !imgRefArray[i] || !imgRefArray[i].current) return;

      const image = imgRefArray[i].current;
      const canvas = previewCanvasRefArray[i].current;
      const crop = completedCrop_;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;

      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );

    })
   
  }, [completedCropArray]);

  useEffect( async () => {

    if(previewCanvasRefArray.length === 0 || completedCropArray.length === 0 || upImgArray.length === 0) return

    const upImgArray_ = await Promise.all(
      upImgArray.map((x, i) => {
        if(previewCanvasRefArray[i] && completedCropArray[i]) return generateFile(previewCanvasRefArray[i].current, completedCropArray[i], i)
      })
    )

    //Remove null values from images Array
    upImgArray_.filter(n => n)

    setImages(upImgArray_)
    
  }, [upImgArray, previewCanvasRefArray, completedCropArray])

  return (
    <div>
      { upImgArray.map((upImage, i) => (
          <div>
            <div className="imageCropCounter">{i+1}</div>
            <div className="imageCropContainer">
              <ReactCrop
                src={upImage}
                onImageLoaded={(e) => onLoadArray(e, i)}
                crop={cropArray[i]}
                onChange={(c) => {
                  setCropArray([...cropArray.slice(0,i), c, ...cropArray.slice(i+1)]);
                }}
                onComplete={(c) => {
                  //{x: 0, y: 0, width: MIN_WIDTH, height: MIN_HEIGHT, unit: "px", aspect: 16 / 9 }
                  setCompletedCropArray([...completedCropArray.slice(0,i), c, ...completedCropArray.slice(i+1)])  
                }}
                className="reactCrop"
              />
              <div className="reactCanvasCropContainer">
                <canvas
                  className="reactCanvasCrop"
                  ref={previewCanvasRefArray[i]}
                  // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                  style={{
                    width: Math.round(completedCropArray[i]?.width ?? 0),
                    height: Math.round(completedCropArray[i]?.height ?? 0)
                  }}
                />
              </div>

              <button
                type="button"
                disabled={!completedCropArray[i]?.width || !completedCropArray[i]?.height}
                onClick={() => generateDownload(previewCanvasRefArray[i].current, completedCropArray[i], i)}
              >
                Descargar imagen recortada
              </button>

              <button
                type="button"
                onClick={() => removeImage(i)}
              >
                Eliminar imagen
              </button>
        
            </div>
          </div>
        ))
      }

      <div className='cropImagesRoot'>

        <label htmlFor='multi'>
            <div className='buttonContainer'>
                <div><AddAPhotoIcon color='primary' className='addAPhotoIcon' /></div>
                <div>Clic aquí para cargar tus fotografías</div>
            </div>
        </label>

        <input 
            className='cropImagesInput'
            type='file' 
            id='multi' 
            accept="image/*" 
            onChange={onSelectFileArray} 
            multiple 
        />

      </div>

    </div>
  );
}


export default CropImages;