import React, { Component } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import IconButton from "@material-ui/core/IconButton";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import {API_URL, GET_MACHINES_BY_SUBCATEGORY } from "../../API";
import axios from 'axios';
import SellRentGalleryItem from "../SellRentGalleryItem/SellRentGalleryItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {message_responses} from "../../MESSAGE_RESPONSES";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const drawerWidth = 300;

const styles = theme => ({
    root: {
        maxWidth: '1920px',
        margin: 'auto',
        display: 'flex',
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        top: 'auto',
        right: 'auto',
        position: 'absolute',
        maxWidth: '1920px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#ff9b01',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        top: 'auto',
        position: 'relative',
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        overflowX: 'hidden',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    toolbar: {
        overflowX: 'hidden',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        margin: 'auto !important',
        width: '60% !important',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000000',
        zIndex: 1,
    },
    inputRoot: {
        backgroundColor: '#ffffff',
        color: '#000000',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    linearProgress: {
        width: '100%',
        margin: '0px 8px',
    },
    card: {
        width: '100%',
        maxWidth: 400,
        borderRadius: '0px',
    },
    cardFilters:{
        width: '100%',
        borderRadius: '0px',
        minHeight: '48px',
        padding: '5px 20px',
    },
    skeletonDescription:{
        padding: '10px',
    },
    filterHeading:{
        fontWeight: 'bold',
    },
    expansionPanelExpanded:{
        margin: '0px !important',
    },
    expansionPanelDetails:{
        padding: 0,
    }
});

const TMPFilterCheckbox = withStyles({
    root: {
        color: 'black',
        '&$checked': {
            color: '#ff9b01',
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

class SellingComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            open: this.props.windowsWitdh > 960,

            products: [],
            products_filtered: [],

            categories: [],
            categoriesFilter: {},

            subcategories: [],
            subcategoriesFilter: {},

            brands: [],
            brandsFilter: {},

            models: [],
            modelsFilter: {},

            years: [],
            yearsFilter: {},

            cities: [],
            citiesFilter: {},

            fuelTypes: [],
            fuelTypesFilter: {},

            conditions: [],
            conditionsFilter: {},

            onlyOwners: [],
            onlyOwnersFilter: {},

            searchText: '',

            loadingItems: false,

            skeletons: [0,1,2,3,4,5,6,7,8,9,10,11],

            isAuth: false,
            authUser: null
        }
    }

    componentDidMount() {
        this._getSellRentItems();
        this.checkAuth();
    }

    checkAuth(){
        let user = JSON.parse(localStorage.getItem('TMPData'));
        if(user){
            this.setState({isAuth: true, authUser: user.user})
        }else{
            this.setState({isAuth: false, authUser: null})
        }
    }

    _getSellRentItems(){
        this.setState({loadingItems: true})
        axios.get(API_URL + GET_MACHINES_BY_SUBCATEGORY + this.props.subcategory_id ).then((response) => {
            if(response.data.success){
                this.setState({
                    products: response.data.data,
                    products_filtered: response.data.data,
                })
                this._getFilter(response.data.data);
            }else{
                alert(message_responses[response.data.message]);
            }
            this.setState({loadingItems: false})
        }).catch((error) => {
            if(error.response && error.response.data){
                alert(error.response.data.message === 'VALIDATOR_ERROR' ? error.response.data.data.error_message : message_responses[error.response.data.message])
            }else{
                alert("Por favor discúlpanos :( , hemos tenido un error cargando nuestra maquinaria.")
            }
        })
    }

    searchText(event){
        this.setState({searchText:event.target.value}, () => {
            this.applyFilters();
        });
    }

    _filterSearchText(){
        let products_filtered = [];
        this.state.products.forEach((product) => {
            let entries = Object.entries(product);
            for (let i = 0; i < entries.length; i++) {
                let value = entries[i][1] ? entries[i][1].toString() : '';
                if(value.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase())){
                    products_filtered.push(product)
                    break;
                }
            }
        })
        return products_filtered;
    }

    applyFilters(){
        //let products_filtered = this.state.products;
        let products_filtered = this._filterSearchText();
        products_filtered = this._filter('brandsFilter', 'brand', products_filtered);
        products_filtered = this._filter('modelsFilter', 'model', products_filtered);
        products_filtered = this._filter('yearsFilter', 'year', products_filtered);
        products_filtered = this._filter('citiesFilter', 'location_city', products_filtered);
        products_filtered = this._filter('fuelTypesFilter', 'fuel_type', products_filtered);
        products_filtered = this._filter('conditionsFilter', 'condition', products_filtered);
        this._getFilter(products_filtered);
        this.setState({products_filtered})
    }

    _filter(filter, field, products_filtered){
        let array = this.state[filter];
        if(this.hasFilter(this.state[filter])){
            Object.entries(array).forEach(entry => {
                let key = entry[0];
                let value = entry[1];
                if(!value){
                    products_filtered = products_filtered.filter((product) => product[field] !== key )
                }
            });
        }
        return products_filtered;
    }

    _getFilter(products_filtered){
        this._getFilterValues('brands', 'brandsFilter', 'brand', products_filtered, false);
        this._getFilterValues('models', 'modelsFilter', 'model', products_filtered, false);
        this._getFilterValues('years', 'yearsFilter', 'year', products_filtered, true);
        this._getFilterValues('cities', 'citiesFilter', 'location_city', products_filtered, false);
        this._getFilterValues('fuelTypes', 'fuelTypesFilter', 'fuel_type', products_filtered, false);
        this._getFilterValues('conditions', 'conditionsFilter', 'condition', products_filtered, false);
    }

    hasFilter(array){
        let flag = false;
        Object.entries(array).forEach(entry => {
            //let key = entry[0];
            let value = entry[1];
            if(value) flag = true;
        });
        return flag;
    }

    _getFilterValues(filterValues, filterName, filterField, products_filtered, reverse){
        let currentFilterValues = [...new Set(products_filtered.map(data => data[filterField]))]
        reverse ? currentFilterValues.sort().reverse() : currentFilterValues.sort();
        let current_filters = this.state[filterName];
        if(Object.keys(current_filters).length === 0 && current_filters.constructor === Object){
            let newFilterValues = [];
            currentFilterValues.forEach((value) => { newFilterValues[value] = false })
            this.setState({ [filterValues] : currentFilterValues, [filterName] : newFilterValues})
        }
    }

    handleChangeBrand = name => event => {
        let brandsFilter = this.state.brandsFilter;
        brandsFilter[name] = event.target.checked;
        this.setState({ brandsFilter }, () => {
            this.applyFilters();
        });
    };

    handleChangeModel = name => event => {
        let modelsFilter = this.state.modelsFilter;
        modelsFilter[name] = event.target.checked;
        this.setState({ modelsFilter }, () => {
            this.applyFilters();
        });
    };

    handleChangeYear = name => event => {
        let yearsFilter = this.state.yearsFilter;
        yearsFilter[name] = event.target.checked;
        this.setState({ yearsFilter }, () => {
            this.applyFilters();
        });
    };

    handleChangeCity = name => event => {
        let citiesFilter = this.state.citiesFilter;
        citiesFilter[name] = event.target.checked;
        this.setState({ citiesFilter }, () => {
            this.applyFilters();
        });
    };

    handleChangeFuelType = name => event => {
        let fuelTypesFilter = this.state.fuelTypesFilter;
        fuelTypesFilter[name] = event.target.checked;
        this.setState({ fuelTypesFilter }, () => {
            this.applyFilters();
        });
    };

    handleChangeCondition = name => event => {
        let conditionsFilter = this.state.conditionsFilter;
        conditionsFilter[name] = event.target.checked;
        this.setState({ conditionsFilter }, () => {
            this.applyFilters();
        });
    };

    handleChangeOnlyOwner = name => event => {
        let onlyOwnersFilter = this.state.onlyOwnersFilter;
        onlyOwnersFilter[name] = event.target.checked;
        this.setState({ onlyOwnersFilter }, () => {
            this.applyFilters();
        });
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    closeDawer = () => {
        if(this.state.open && this.props.windowsWitdh < 600){
            this.setState({open:false})
        }
    }

    render() {
        const { classes, theme } = this.props;
        const { open } = this.state;

        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar disableGutters={!open} className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButton, open && classes.hide)}
                        >
                            <FilterListIcon />
                        </IconButton>
                        <div className={classes.search} onClick={() => {this.closeDawer()}}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="BUSCAR . . . "
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={this.state.searchText}
                                onChange={this.searchText.bind(this)}
                            />
                        </div>
                        {
                            this.state.isAuth &&
                                <div className='sellRentHeaderPostButton'>
                                    <Link to={"/publicar"}>
                                        <Button className="svgButtonSellRentHeaderPostButton"><CloudUploadIcon className='svgButtonSellRentHeaderPostButtonIcon'/>  Publica tu maquinaria</Button>
                                    </Link>
                                </div>
                        }

                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={this.handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>

                    {
                        //this.state.loadingItems
                        this.state.loadingItems &&
                        <React.Fragment>
                            <Grid container spacing={0}>
                                {
                                    this.state.skeletons.map(() => (
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                <Card className={classes.cardFilters}>
                                                    <Grid item xs={12}>
                                                        <Skeleton height={15} width="100%" />
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        this.state.brands.length > 0 &&
                        <ExpansionPanel
                            classes={{
                                expanded: classes.expansionPanelExpanded
                            }}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.filterHeading}>MARCA FABRICANTE</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                <List className='filterList'>
                                    {
                                        this.state.brands.map(brand => (
                                            <div>
                                                <FormControlLabel
                                                    control={
                                                        <TMPFilterCheckbox
                                                            checked={this.state.brandsFilter[brand]}
                                                            onChange={this.handleChangeBrand(brand)}
                                                            value={this.state.brandsFilter[brand]}
                                                        />
                                                    }
                                                    label={brand}
                                                />
                                            </div>
                                        ))
                                    }
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                    {
                        this.state.models.length > 0 &&
                        <ExpansionPanel
                            classes={{
                                expanded: classes.expansionPanelExpanded
                            }}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.filterHeading}>MODELO</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                <List className='filterList'>
                                    {
                                        this.state.models.map(model => (
                                            <div>
                                                <FormControlLabel
                                                    control={
                                                        <TMPFilterCheckbox
                                                            checked={this.state.modelsFilter[model]}
                                                            onChange={this.handleChangeModel(model)}
                                                            value={this.state.modelsFilter[model]}
                                                        />
                                                    }
                                                    label={model}
                                                />
                                            </div>
                                        ))
                                    }
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                    {
                        this.state.years.length > 0 &&
                        <ExpansionPanel
                            classes={{
                                expanded: classes.expansionPanelExpanded
                            }}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.filterHeading}>AÑO</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                <List className='filterList'>
                                    {
                                        this.state.years.map(year => (
                                            <FormControlLabel
                                                control={
                                                    <TMPFilterCheckbox
                                                        checked={this.state.yearsFilter[year]}
                                                        onChange={this.handleChangeYear(year)}
                                                        value={this.state.yearsFilter[year]}
                                                    />
                                                }
                                                label={year}
                                            />
                                        ))
                                    }
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                    {
                        this.state.cities.length > 0 &&
                        <ExpansionPanel
                            classes={{
                                expanded: classes.expansionPanelExpanded
                            }}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.filterHeading}>CIUDAD</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                <List className='filterList'>
                                    {
                                        this.state.cities.map(city => (
                                            <div>
                                                <FormControlLabel
                                                    control={
                                                        <TMPFilterCheckbox
                                                            checked={this.state.citiesFilter[city]}
                                                            onChange={this.handleChangeCity(city)}
                                                            value={this.state.citiesFilter[city]}
                                                        />
                                                    }
                                                    label={city}
                                                />
                                            </div>
                                        ))
                                    }
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                    {
                        this.state.fuelTypes.length > 0 &&
                        <ExpansionPanel
                            classes={{
                                expanded: classes.expansionPanelExpanded
                            }}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.filterHeading}>COMBUSTIBLE</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                <List className='filterList'>
                                    {
                                        this.state.fuelTypes.map(fuelType => (
                                            <div>
                                                <FormControlLabel
                                                    control={
                                                        <TMPFilterCheckbox
                                                            checked={this.state.fuelTypesFilter[fuelType]}
                                                            onChange={this.handleChangeFuelType(fuelType)}
                                                            value={this.state.fuelTypesFilter[fuelType]}
                                                        />
                                                    }
                                                    label={fuelType}
                                                />
                                            </div>
                                        ))
                                    }
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                    {
                        this.state.conditions.length > 0 &&
                        <ExpansionPanel
                            classes={{
                                expanded: classes.expansionPanelExpanded
                            }}
                        >
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.filterHeading}>NUEVA / USADA</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                                <List className='filterList'>
                                    {
                                        this.state.conditions.map(condition => (
                                            <div>
                                                <FormControlLabel
                                                    control={
                                                        <TMPFilterCheckbox
                                                            checked={this.state.conditionsFilter[condition]}
                                                            onChange={this.handleChangeCondition(condition)}
                                                            value={this.state.conditionsFilter[condition]}
                                                        />
                                                    }
                                                    label={condition}
                                                />
                                            </div>
                                        ))
                                    }
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    }
                </Drawer>
                <main
                    className={classNames(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >

                    <div className={classes.drawerHeader} />

                    <Grid container spacing={2}>
                        {
                            this.state.loadingItems &&

                                <React.Fragment>
                                    <Grid container spacing={3}>
                                    {
                                        this.state.skeletons.map(() => (
                                            <Grid item xs={12} sm={6} md={4} xl={3}>
                                                <Grid container spacing={1}>
                                                    <Card className={classes.card}>
                                                        <Grid item xs={12}>
                                                            <Skeleton variant='rect' width="100%" height={250} />
                                                        </Grid>
                                                        <div className={classes.skeletonDescription}>
                                                            <Grid item xs={12}>
                                                                <Skeleton height={15} width="80%" />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton height={15} width="60%" />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton height={15} width="40%" />
                                                            </Grid>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                    </Grid>
                                </React.Fragment>
                        }

                        {this.state.products_filtered.map(product => (
                            <Grid item xs={12} sm={6} md={4} xl={3} key={product.id}  >
                                <SellRentGalleryItem data={product}/>
                            </Grid>
                        ))}

                    </Grid>
                </main>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SellingComponent);