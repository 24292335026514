import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Logo from "../../images/logo.png";
import {Link, Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import {API_URL, USER_LOGIN} from "../../API";
import axios from 'axios';
import {message_responses} from "../../MESSAGE_RESPONSES";


const styles = {
    paper: {
        margin: '10% 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    logoContainer: {
        textAlign: 'center',
    },
    logo: {
        maxHeight: '100%',
        maxWidth: '50%',
    },
    error: {
        color: '#ef2020',
    },
    progress: {
        color: '#ff9b01',
    },
    submit: {
        minHeight: '56px',
        color: '#000000',
        borderRadius: '4px !important',
    },
    link: {
        textDecoration: 'none',
        color: '#000000 !important',
        '&:hover': {
            textDecoration: 'underline',
            color: '#000000 !important',
        }
    },
    link2: {
        textDecoration: 'none',
        color: '#ffffff !important',
        '&:hover': {
            textDecoration: 'underline',
            color: '#ffffff !important',
        }
    }
};



class LoginComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            showPassword: false,
            redirectToHome: false,
            doingLogin: false,
            loginError: '',
            passwordError: '',
            emailError: '',
        }
    }

    componentDidMount() {
        this.checkAuth();
    }

    checkAuth(){
        if(localStorage.getItem('TMPData')){
            this.setState({redirectToHome: true})
        }
    }

    redirectToHome = () => {
        if (this.state.redirectToHome) {
            if(this.props.urlParams.redirect){
                return <Redirect push to={this.props.urlParams.redirect} />
            }
            return <Redirect push to='/' />
        }
    }

    handleClickShowPassword(){
        this.setState({showPassword:!this.state.showPassword})
    }

    handleMouseDownPassword(event){
        event.preventDefault();
    }

    doLogin(){
        if(this.validateField()){
            this.setState({doingLogin:true})

            let formData = {
                email: this.state.email,
                password: this.state.password,
            }

            axios.post(API_URL + USER_LOGIN, formData).then((response) => {
                this.setState({doingLogin:false})
                localStorage.clear();
                if(response.data.success){
                    localStorage.clear();
                    localStorage.setItem('TMPData', JSON.stringify(response.data.data))
                    this.setState({
                        redirectToHome: true,
                    })
                }else{
                    this.setState({
                        loginError: message_responses[response.data.message],
                    })
                }
            }).catch((error) => {
                localStorage.clear();
                this.setState({doingLogin:false})
                if(error.response && error.response.data){
                    this.setState({
                        loginError: message_responses[error.response.data.message],
                    })
                }else{
                    this.setState({
                        loginError: "ERROR DE SERVIDOR",
                    })
                }
            })
            
        }
    }

    validateField(){
        return this.validateEmail() && this.validatePassword();
    }

    validateEmail(){
        let field = this.state.email;
        if(field === ''){
            this.setState({emailError: "El correo no puede estar vacío"})
            return false;
        }else{
            this.setState({emailError: ""})
            return true;
        }
    }

    validatePassword(){
        let field = this.state.password;
        if(field === ''){
            this.setState({passwordError: "La contraseña no puede estar vacía"})
            return false;
        }else{
            this.setState({passwordError: ""})
            return true;
        }
    }

    handleChange(event){
        this.setState({[event.target.name] : event.target.value})
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.redirectToHome()}
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} className={classes.logoContainer}>
                                <img src={Logo} alt="logo" className={classes.logo}/>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo"
                                    name="email"
                                    autoComplete="email"
                                    value={this.state.email}
                                    onChange={this.handleChange.bind(this)}
                                />
                                { this.state.emailError !== '' && <span className={classes.error}>{this.state.emailError}</span> }
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    value={this.state.password}
                                    onChange={this.handleChange.bind(this)}
                                    InputProps={{
                                        'aria-label': 'bare',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword.bind(this)}
                                                    onMouseDown={this.handleMouseDownPassword.bind(this)}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                { this.state.passwordError !== '' && <span className={classes.error}>{this.state.passwordError}</span> }
                            </Grid>

                            <Grid container justify="center">
                                <Grid item>
                                    <br/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                { this.state.loginError !== '' && <span className={classes.error}>{this.state.loginError}</span> }
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <br/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => this.doLogin()}
                                    disabled={this.state.doingLogin}
                                >
                                    {this.state.doingLogin ? <CircularProgress className={classes.progress} color="primary" /> : 'INICIAR SESIÓN'}
                                </Button>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <Link to="/password-recovery" className={classes.link}>
                                        Olvidaste tu contraseña ?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>
                                    <br/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    className={classes.submit}
                                    disabled={this.state.doingLogin}
                                >
                                    <Link to="/sign-up" className={classes.link2}>
                                        Aún no tienes cuenta en <strong>TMP</strong> ? Regístrate.
                                    </Link>
                                </Button>
                            </Grid>
                        </Grid>


                    </div>
                </Container>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(LoginComponent);