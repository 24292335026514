import React, { Component, Fragment } from 'react';
import AdminHeader from '../../components/AdminHeader/AdminHeader';
import AdminMachinesComponent from "../../components/AdminMachinesComponent/AdminMachines";

class AdminMachines extends Component{
    render(){
        return(
            <Fragment>
                <AdminHeader/>
                <AdminMachinesComponent/>
            </Fragment>
        );
    }
}

export default AdminMachines;