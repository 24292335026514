import React from 'react';
import Header from "../../components/Header/Header";
import AboutUSComponent from "../../components/AboutUSComponent/AboutUSComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";

class AboutUS extends React.Component{
    render(){
        return(
            <React.Fragment>
                <Header/>
                <AboutUSComponent/>
                <FooterComponent/>
            </React.Fragment>
        )
    }
}

export default AboutUS;