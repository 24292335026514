import { useState, useEffect } from 'react';
import { SEARCH_MACHINE } from '../../API';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const SearchMachineController = () => {

    const history = useHistory();

    const [ searchText, setSearchText ] = useState('');
    const [ paginationData, setPaginationData ] = useState(null);
    const [ machinesData, setMachinesData ] = useState([]);
    const [ loadingMachineSearching, setLoadingMachineSearching ] = useState(false);

    useEffect(() => {
        clearSearching();
    }, [searchText])

    const throwAlert = () => {
        alert(`OPS, lo sentimos no hemos encontrado ningún resultado para la referencia ${searchText}`)
    }

    const clearSearching = () => {
        setPaginationData(null);
        setMachinesData([]);
        setLoadingMachineSearching(false)
    }

    const onClose = () => {
        setSearchText('');
        clearSearching();
    }

    const goToMachine = id => {
        history.push(`/ventas/detalles-maquina?maquinaId=${id}`)
        window.location.reload();
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') handleSearch()
    }

    const handleSearch = () => {
        setLoadingMachineSearching(true)
        let formData = { string: searchText };
        axios.post(SEARCH_MACHINE, formData).then((response) => {
            setLoadingMachineSearching(false)
            if(response.data.success && response.data.data !== null){
                setPaginationData(response.data.data)
                setMachinesData(response.data.data.data)
                if(Array.isArray(response.data.data.data) && response.data.data.data.length === 0){
                    throwAlert()
                }
            }else{
                setPaginationData(null)
                setMachinesData([])
            }   
        }).catch((error) => {
            setLoadingMachineSearching(false)
            setPaginationData(null)
            setMachinesData([])
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error buscando maquinaria.")
            }
        })
    }

    const onLoadMore = () => {
        if(!paginationData.next_page_url) return
        setLoadingMachineSearching(true)
        let formData = { string: searchText };
        axios.post(paginationData.next_page_url, formData).then((response) => {
            setLoadingMachineSearching(false)
            if(response.data.success && response.data.data !== null){
                setPaginationData(response.data.data)
                setMachinesData([...machinesData, ...response.data.data.data])
                if(Array.isArray(response.data.data.data) && response.data.data.data.length === 0){
                    throwAlert()
                }
            }else{
                setPaginationData(null)
                setMachinesData([])
            }   
        }).catch((error) => {
            setLoadingMachineSearching(false)
            setPaginationData(null)
            setMachinesData([])
            if (error.response && error.response.data && error.response.data.message) {
                alert(error.response.data.message)
            } else {
                alert("ERROR: error buscando maquinaria.")
            }
        })
    }

    return {
        searchText,
        setSearchText,
        handleSearch,
        loadingMachineSearching,
        paginationData,
        machinesData,
        onLoadMore,
        onClose,
        goToMachine,
        handleKeyPress,
    }
}

export default SearchMachineController;