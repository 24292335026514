import React, { Component, Fragment } from 'react';
import './Home.css';
import Header from '../../components/Header/Header';

import HomeComponent from "../../components/HomeComponent/HomeComponent";
import FooterComponent from "../../components/FooterComponent/FooterComponent";

class Home extends Component{
    render(){
        return(
            <Fragment>
                <Header/>
                <HomeComponent/>
                <FooterComponent/>
            </Fragment>
        );
    }
}

export default Home;